import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function LoadingState() {
  return (
    <div
      className="d-flex justify-content-center align-items-center border border-1 rounded"
      style={{ minHeight: 550 }}
    >
      <FontAwesomeIcon
        icon={faCircleNotch}
        size={"lg"}
        className="text-secondary fa-spin"
      />
    </div>
  );
}

export default LoadingState;
