import {
  faBox,
  faFileCsv,
  faMotorcycle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { SideBar, ModalMasterSatuan, ModalMaster } from "../../components";
import { getProdukAllChange } from "../../config/redux/action/produkAction";
import moment from "moment";

function MasterPage() {
  const { AllProduk } = useSelector((state) => state.produkReducer);
  const [Tabs, setTabs] = useState(1);
  const [DataCSV, setDataCSV] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getProdukAllChange({
        url: "/allproduk",
        search: "",
        type: "ALL_PRODUK_CHANGE",
        limit: 1000,
      }),
      dispatch
    );
  }, []);

  useEffect(() => {
    if (AllProduk.length > 0) {
      let data = [];

      AllProduk.map((val) => {
        data.push({
          "Name*": val.nama,
          // Description: val.keterangan.replace(/"/g, "'").replace(/,/g, "-").replace(',', '.'),
          ProductCode: val.id,
          "*Unit": val.satuan,
          SellPrice: val.harga,
          DefaultSellAccountCode: val.account_code,
          ProductCategories: "Produk Apps",
        });
      });

      setDataCSV(data);
    }
  }, [AllProduk]);

  return (
    <div>
      <SideBar />
      <div className="content px-1">
        <div className="card p-3 mb-3 shadow d-flex flex-row ">
          <div
            className="nav d-flex flex-column"
            style={{
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            <button
              className={`nav-link border-0 text-decoration-none fw-bold text-dark mb-2 d-flex justify-content-center align-items-center `}
              onClick={() => setTabs(1)}
              style={Tabs === 1 ? { backgroundColor: "#33D49D" } : null}
            >
              <div className={`px-2 ${Tabs === 1 ? "text-white" : ""}`}>
                Paket Berlangganan
              </div>
            </button>
            <button
              className={`nav-link border-0 text-decoration-none fw-bold text-dark mb-2 d-flex justify-content-center align-items-center `}
              onClick={() => setTabs(2)}
              style={Tabs === 2 ? { backgroundColor: "#33D49D" } : null}
            >
              <div className={`px-2 ${Tabs === 2 ? "text-white" : ""}`}>
                Paket Order Satuan
              </div>
            </button>
            <button
              className={`nav-link border-0 text-decoration-none fw-bold text-dark mb-2 d-flex justify-content-center align-items-center `}
              onClick={() => setTabs(3)}
              style={Tabs === 3 ? { backgroundColor: "#33D49D" } : null}
            >
              <div className={`px-2 ${Tabs === 3 ? "text-white" : ""}`}>
                Paket Setrika
              </div>
            </button>
            <button
              className={`nav-link border-0 text-decoration-none fw-bold text-dark mb-2 d-flex justify-content-center align-items-center `}
              onClick={() => setTabs(4)}
              style={Tabs === 4 ? { backgroundColor: "#33D49D" } : null}
            >
              <div className={`px-2 ${Tabs === 4 ? "text-white" : ""}`}>
                Satuan Order
              </div>
            </button>
            <button
              className={`nav-link border-0 text-decoration-none fw-bold text-dark mb-2 d-flex justify-content-center align-items-center `}
              onClick={() => setTabs(5)}
              style={Tabs === 5 ? { backgroundColor: "#33D49D" } : null}
            >
              <div className={`px-2 ${Tabs === 5 ? "text-white" : ""}`}>
                Wash Option
              </div>
            </button>
            <button
              className={`nav-link border-0 text-decoration-none fw-bold text-dark mb-2 d-flex justify-content-center align-items-center `}
              onClick={() => setTabs(6)}
              style={Tabs === 6 ? { backgroundColor: "#33D49D" } : null}
            >
              <div className={`px-2 ${Tabs === 6 ? "text-white" : ""}`}>
                Kiloan
              </div>
            </button>
            <button
              className={`nav-link border-0 text-decoration-none fw-bold text-dark mb-2 d-flex justify-content-center align-items-center `}
              onClick={() => setTabs(7)}
              style={Tabs === 7 ? { backgroundColor: "#33D49D" } : null}
            >
              <div className={`px-2 ${Tabs === 7 ? "text-white" : ""}`}>
                Karpet
              </div>
            </button>
            <button
              className={`nav-link border-0 text-decoration-none fw-bold text-dark mb-2 d-flex justify-content-center align-items-center `}
              onClick={() => setTabs(8)}
              style={Tabs === 8 ? { backgroundColor: "#33D49D" } : null}
            >
              <div className={`px-2 ${Tabs === 8 ? "text-white" : ""}`}>
                Setrika Kiloan
              </div>
            </button>
            <button
              className={`nav-link border-0 text-decoration-none fw-bold text-dark mb-2 d-flex justify-content-center align-items-center `}
              onClick={() => setTabs(9)}
              style={Tabs === 9 ? { backgroundColor: "#33D49D" } : null}
            >
              <div className={`px-2 ${Tabs === 9 ? "text-white" : ""}`}>
                Paket Duluxe
              </div>
            </button>
            <button
              className={`nav-link border-0 text-decoration-none fw-bold text-dark mb-2 d-flex justify-content-center align-items-center `}
              onClick={() => setTabs(10)}
              style={Tabs === 10 ? { backgroundColor: "#33D49D" } : null}
            >
              <div className={`px-2 ${Tabs === 10 ? "text-white" : ""}`}>
                Cuci Lipat
              </div>
            </button>
            <button
              className={`nav-link border-0 text-decoration-none fw-bold text-dark mb-2 d-flex justify-content-center align-items-center `}
              onClick={() => setTabs(11)}
              style={Tabs === 11 ? { backgroundColor: "#33D49D" } : null}
            >
              <div className={`px-2 ${Tabs === 11 ? "text-white" : ""}`}>
                Ongkir
              </div>
            </button>

            <CSVLink
              data={DataCSV}
              filename={`Data Produk - ${moment().format("LLLL")}.csv`}
              target="_blank"
              className={`nav-link border-0 text-decoration-none fw-bold text-white mb-2 mt-5 d-flex justify-content-center bg-success align-items-center `}
              enclosingCharacter={``}
            >
              <div className={`px-2`}>Export CSV</div>
              <FontAwesomeIcon icon={faFileCsv} size="lg" />
            </CSVLink>
          </div>
          <div className="ms-2 w-100">
            {Tabs === 1 ? (
              <ModalMaster kategori_id={1} />
            ) : Tabs === 2 ? (
              <ModalMaster kategori_id={2} />
            ) : Tabs === 3 ? (
              <ModalMaster kategori_id={3} />
            ) : Tabs === 4 ? (
              <ModalMasterSatuan kategori_id={10} />
            ) : Tabs === 5 ? (
              <ModalMaster kategori_id={"null"} kategori_detail_id={"null"} />
            ) : Tabs === 6 ? (
              <ModalMaster kategori_id={4} />
            ) : Tabs === 7 ? (
              <ModalMaster kategori_id={5} />
            ) : Tabs === 8 ? (
              <ModalMaster kategori_id={7} />
            ) : Tabs === 9 ? (
              <ModalMasterSatuan kategori_id={6} />
            ) : Tabs === 10 ? (
              <ModalMaster kategori_id={8} />
            ) : (
              <ModalMaster kategori_id={9} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterPage;
