import React from "react";
import LoadingState from "./LoadingState";

function TableComponent({ header, data }) {
  return (
    <div className="table-responsive">
      <table className="table opacity-8">
        <thead style={{ fontSize: 14 }}>
          <tr>
            {header.length > 0 &&
              header.map((val, key) => {
                return (
                  <th style={{ paddingLeft: 5 }} key={key}>
                    {val}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody style={{ fontSize: 14, fontWeight: "normal" }}>
          {data === null ? (
            <tr>
              <td colSpan={header.length} align={"center"}>
                <LoadingState height={400} />
              </td>
            </tr>
          ) : data.length > 0 ? (
            data
          ) : (
            <tr>
              <td colSpan={header.length} align={"center"}>
                Data tidak ditemukan
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TableComponent;
