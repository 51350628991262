import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getDataAPI } from "../../../config/redux/action/newRequestAction";

ChartJS.register(ArcElement, Tooltip, Legend);

function ProdukOrderChart({dateRange}) {
  const { ProdukChart } = useSelector((state) => state.chartReducer);
  const [PieData, setPieData] = useState({
    labels: [],
    datasets: [
      {
        label: "Order Periode",
        data: [],
        backgroundColor: "rgba(255, 0, 144, 0.7)",
      },
    ],
  });
  const dispatch = useDispatch()

  useEffect(() => {
    if (dateRange[0] !== null && dateRange[1] !== null) {
      dispatch(
        getDataAPI(
          {
            url: `/chart/produk`,
            type: "PRODUK_CHART_LIST",
            data: {
              dateRange,
            },
          },
          dispatch
        )
      );
    }
    
  }, [dateRange]);

  useEffect(() => {
    if (ProdukChart.length > 0) {
      let labels = [];
      let datas = [];

      ProdukChart.map((val) => {
        labels.push(val.produk.nama);
        datas.push(val.count);
      });

      setPieData({
        labels: labels,
        datasets: [
          {
            data: datas,
            backgroundColor: [
              "rgba(255, 99, 132, 0.8)",
              "rgba(54, 162, 235, 0.8)",
              "rgba(255, 206, 86, 0.8)",
              "rgba(75, 192, 192, 0.8)",
              "rgba(153, 102, 255, 0.8)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
            ],
            borderWidth: 1,
            hoverOffset: 2,
          },
        ],
      });
    }
  }, [ProdukChart]);

  return (
    <div className="row d-flex justify-content-center align-items-center">
      <div className="col-10">
        <Pie
          data={PieData}
          options={{
            plugins: {
              title: {
                display: true,
                text: "Produk Terjual Terbanyak",
              },
              legend: {
                position: "right",
                labels: {
                  boxWidth: 10,
                  font: {
                    size: 10,
                  },
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default ProdukOrderChart;
