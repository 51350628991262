import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import {
  formatRupiah,
  statusBayar,
  statusDriver,
  statusProduksi,
} from "../../../utils";
import CopyOrder from "./CopyOrder";

function TabOrder({ data, onClick }) {
  const [Data, setData] = useState([]);
  const [Total, setTotal] = useState(0);
  let dataOrder = [];

  useEffect(() => {
    if (data.length > 0) {
      let datas = [];
      let nilai = 0;
      let total = 0;
      let total_seleruh = 0;

      data.map((val) => {
        nilai = 0;
        total = 0;

        if (val.status !== 1) {
          val.order_detail.map((result) => {
            nilai += result.nilai;
          });

          total += nilai + val.diskon + val.biaya_aplikasi + val.ongkos;
        }

        total_seleruh += total;

        let diskon = val.diskon;

        datas.push({
          no_order: val.no_order,
          tanggal_pesan: moment(new Date(val.tanggal_pesan)).format("LLL"),
          nama: val.user.nama,
          status_bayar: statusBayar(val.status),
          status_driver: statusDriver(val.status_driver),
          status_produksi: statusProduksi(val.status_produksi),
          status_order: val.tanggal_terima === null ? "Berlangsung" : "Selesai",
          total: formatRupiah(String(total), "Rp. "),
          val,
        });
      });
      setTotal(total_seleruh);
      setData(datas);
    } else {
      setData([]);
    }
  }, [data]);

  const column = [
    {
      name: "No Order",
      selector: (row) => row.no_order,
      wrap: true,
    },
    {
      name: "Tanggal Pesan",
      selector: (row) => row.tanggal_pesan,
      wrap: true,
    },
    {
      name: "Nama",
      selector: (row) => row.nama,
      wrap: true,
    },
    {
      name: "Status Bayar",
      selector: (row) => row.status_bayar,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row) => row.val.status === 1,
          style: {
            backgroundColor: "#D8274E",
            color: "white",
          },
        },
        {
          when: (row) => row.val.status === 2,
          style: {
            backgroundColor: "#173c74",
            color: "white",
          },
        },
        {
          when: (row) => row.val.status === 3 || row.val.status === 5,
          style: {
            backgroundColor: "#FCFF43",
            color: "black",
          },
        },
        {
          when: (row) => row.val.status === 4 || row.val.status === 6,
          style: {
            backgroundColor: "#33D49D",
            color: "",
          },
        },
      ],
    },
    {
      name: "Status Driver",
      selector: (row) => row.status_driver,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row) => row.val.status_driver > 1 && row.val.status_driver < 6,
          style: {
            backgroundColor: "#FCFF43",
            color: "black",
          },
        },
        {
          when: (row) => row.val.status_driver === 1,
          style: {
            backgroundColor: "#173c74",
            color: "white",
          },
        },
        {
          when: (row) => row.val.status_driver === 6,
          style: {
            backgroundColor: "#33D49D",
            color: "",
          },
        },
        {
          when: (row) =>
            row.val.status_driver === null || row.val.status_driver === "",
          style: {
            backgroundColor: "#173c74",
            color: "white",
          },
        },
      ],
    },
    {
      name: "Status Produksi",
      selector: (row) => row.status_produksi,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row) =>
            row.val.status_produksi > 1 && row.val.status_produksi < 10,
          style: {
            backgroundColor: "#FCFF43",
            color: "black",
          },
        },
        {
          when: (row) => row.val.status_produksi === 1,
          style: {
            backgroundColor: "#173c74",
            color: "white",
          },
        },
        {
          when: (row) => row.val.status_produksi === 10,
          style: {
            backgroundColor: "#33D49D",
            color: "",
          },
        },
        {
          when: (row) =>
            row.val.status_produksi === null || row.val.status_produksi === "",
          style: {
            backgroundColor: "#173c74",
            color: "white",
          },
        },
      ],
    },
    {
      name: "Status Order",
      selector: (row) => row.status_order,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row) => row.val.tanggal_terima === null,
          style: {
            backgroundColor: "#FCFF43",
            color: "black",
          },
        },
        {
          when: (row) => row.val.tanggal_terima !== null,
          style: {
            backgroundColor: "#33D49D",
            color: "",
          },
        },
      ],
    },
    {
      name: "Total",
      selector: (row) => row.total,
      wrap: true,
      right: true,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Tampilkan ",
    rangeSeparatorText: " dari ",
  };

  const ExpandedComponent = (data) => {
    return (
      <pre className="container-fluid pt-2 px-3 pb-2 mb-0">
        <div className="row border bg-light" style={{ fontSize: "13px" }}>
          <div className="col-12">
            <div className="fw-bold">Alamat</div>
            <div>{data.data.val.alamat_detail}</div>
          </div>
          <div className="col-12">
            <div className="fw-bold">Keterangan</div>
            <div>
              {data.data.val.keterangan === "" ||
              data.data.val.keterangan === null
                ? "- "
                : data.data.val.keterangan}
            </div>
          </div>
          <div className="col-12">
            <div className="fw-bold">Voucher</div>
            <div>
              {data.data.val.voucers === null
                ? "-"
                : data.data.val.voucers.nama +
                  " (" +
                  formatRupiah(String(data.data.val.diskon), "Rp. ") +
                  ")"}
            </div>
          </div>
        </div>
      </pre>
    );
  };

  return (
    <div className="d-flex flex-column">
      <div className="container-fluid">
        <div className="row align-items-center mb-2">
          <div className="col-4">
            {/* <button className="btn btn-success">Export Rekap</button> */}
            <CopyOrder />
          </div>
          <div className="col-8">
            <div className="fw-bold text-danger text-end">
              Total : {formatRupiah(String(Total), "Rp ")}
            </div>
          </div>
        </div>
      </div>
      <DataTable
        columns={column}
        data={Data}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        customStyles={{
          table: {
            style: {
              border: "solid 0.1px",
              borderColor: "#b5aab3",
            },
          },

          headRow: {
            style: {
              minHeight: "40px",
              fontSize: "13px",
              fontWeight: "bold",
              color: "rgba(51, 51, 51, 0.9)",
              borderRight: "solid 0.1px",
              borderLeft: "solid 0.1px",
              borderColor: "#b5aab3",
              backgroundColor: "lavender",
            },
          },
          rows: {
            style: {
              fontSize: "12px",
              minHeight: "50px",
              ":hover": {
                backgroundColor: "#33d49d50",
              },
              cursor: "pointer",
            },
          },
          cells: {
            style: {
              // border: "solid 0.1px",
              borderRight: "solid 0.1px",
              borderLeft: "solid 0.1px",
              borderColor: "#b5aab3",
            },
          },
          headCells: {
            style: {
              // border: "solid 0.1px",
              borderRight: "solid 0.1px",
              borderColor: "#b5aab3",
            },
          },
        }}
        onRowClicked={(val) =>
          onClick("/order/detail?no_order=" + val.no_order)
        }
      />
    </div>
  );
}

export default TabOrder;
