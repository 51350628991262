const initialState = {
    JamList: [],
    PaymentAcceptList: []
}


const settingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "JAM_LIST":
            return {
                ...state,
                JamList: action.payload
            }
        case "PAYMENT_ACCEPT_LIST":
            return {
                ...state,
                PaymentAcceptList: action.payload
            }
        default:
            return state
    }

}

export default settingReducer