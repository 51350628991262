import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddUserWorkshop,
  ALertNotification,
  CuciTab,
  DriverTab,
  SetrikaTab,
  SideBar,
} from "../../components";
import {
  deleteUserWorkshop,
  getWorkshopChange,
} from "../../config/redux/action/workshopAction";
import { getUserList } from "../../config/redux/action/userAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandsWash,
  faMotorcycle,
  faUserNinja,
} from "@fortawesome/free-solid-svg-icons";
import { getDataAPI } from "../../config/redux/action/newRequestAction";

function WorkshopPage() {
  const { WorkshopList, UserListWorkshop } = useSelector(
    (state) => state.workshopReducer
  );
  // const { UserListWorkshop } = useSelector(state => state.userReducer)
  const [Update, setUpdate] = useState(0);
  const [Data, setData] = useState(null);
  const [Show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const handleCloseAlert = () => setShowAlert(false);
  const [Pesan, setPesan] = useState("");
  const [Cuci, setCuci] = useState([]);
  const [Setrika, setSetrika] = useState([]);
  const [Driver, setDriver] = useState([]);
  const [Tabs, setTabs] = useState(1);
  const [WorkshopId, setWorkshopId] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(
      getDataAPI({
        url: "/user",
        type: "USER_LIST_WORKSHOP",
        data: {
          role_id: 4,
          limit: 50,
          validasi: true,
        },
      })
    );
  }, []);

  useEffect(() => {
    if (UserListWorkshop.length > 0) {
      if (WorkshopId === 0) {
        setWorkshopId(UserListWorkshop[0].user.id);
      }

      dispatch(
        getWorkshopChange({
          url: "/workshop",
          workshop_id:
            WorkshopId === 0 ? UserListWorkshop[0].user.id : WorkshopId,
          type: "WORKSHOP_LIST_CHANGE",
        }),
        dispatch
      );
    }
  }, [UserListWorkshop, Update]);

  useEffect(() => {
    setCuci([]);
    setSetrika([]);
    setDriver([]);

    if (WorkshopList.length > 0) {
      let dataCuci = [];
      let dataSetrika = [];
      let dataDriver = [];

      WorkshopList.map((val) => {
        if (val.user.Roles[0].name === "CUCI") {
          dataCuci.push(val);
        } else if (val.user.Roles[0].name === "SETRIKA") {
          dataSetrika.push(val);
        } else {
          dataDriver.push(val);
        }
      });

      setCuci(dataCuci);
      setSetrika(dataSetrika);
      setDriver(dataDriver);
    }
  }, [WorkshopList]);

  const deleteUser = (val) => {
    dispatch(
      deleteUserWorkshop({
        url: "/workshop",
        id: val.id,
      }),
      dispatch
    ).then(() => {
      setUpdate(Update + 1);
    });
  };

  return (
    <div>
      <SideBar />
      <div className="content">
        <div className="mx-2 bg-white rounded shadow p-2 row">
          <div className="col-6 col-sm-4 col-md-4 col-lg-2 fw-bold pt-1">
            Pilih Workshop
          </div>
          <div className="col-6 col-sm-4 col-md-4 col-lg-3">
            <select
              className="form-select"
              value={WorkshopId}
              onChange={(e) => {
                setWorkshopId(e.target.value);
                dispatch(
                  getWorkshopChange({
                    url: "/workshop",
                    workshop_id: e.target.value,
                    type: "WORKSHOP_LIST_CHANGE",
                  }),
                  dispatch
                );
              }}
            >
              {UserListWorkshop.length > 0 ? (
                UserListWorkshop.map((val) => {
                  return (
                    <option key={val.id} value={val.user.id}>
                      {val.user.nama}
                    </option>
                  );
                })
              ) : (
                <option>Workshop tidak tersedia</option>
              )}
            </select>
          </div>

          <div className="col-12 mt-3">
            <nav>
              <div className="nav nav-tabs w-100 justify-content-around mb-2">
                <button
                  className={`nav-link flex-grow-1 border-0 text-decoration-none text-dark d-flex justify-content-center align-items-center `}
                  onClick={() => setTabs(1)}
                  style={Tabs === 1 ? { backgroundColor: "#33D49D" } : null}
                >
                  <FontAwesomeIcon icon={faHandsWash} />
                  <div className="px-2">Cuci</div>
                </button>
                <button
                  className={`nav-link flex-grow-1 border-0 text-decoration-none text-dark d-flex justify-content-center align-items-center `}
                  onClick={() => setTabs(2)}
                  style={Tabs === 2 ? { backgroundColor: "#33D49D" } : null}
                >
                  <FontAwesomeIcon icon={faUserNinja} />
                  <div className="px-2">Setrika</div>
                </button>
                <button
                  className={`nav-link flex-grow-1 border-0 text-decoration-none text-dark d-flex justify-content-center align-items-center `}
                  onClick={() => setTabs(3)}
                  style={Tabs === 3 ? { backgroundColor: "#33D49D" } : null}
                >
                  <FontAwesomeIcon icon={faMotorcycle} />
                  <div className="px-2">Driver</div>
                </button>
              </div>
            </nav>
          </div>

          <div className="col-12 my-2 row">
            <div className="col-12 col-sm-6 mb-2"></div>
            <div className="col-12 col-sm-6 mb-2 d-flex justify-content-end">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setData(null);
                  setShow(true);
                }}
              >
                {" "}
                Tambah{" "}
              </button>
            </div>

            {Tabs === 1 ? (
              <CuciTab data={Cuci} onClick={(val) => deleteUser(val)} />
            ) : Tabs === 2 ? (
              <SetrikaTab data={Setrika} onClick={(val) => deleteUser(val)} />
            ) : Tabs === 3 ? (
              <DriverTab data={Driver} onClick={(val) => deleteUser(val)} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <AddUserWorkshop
        show={Show}
        handleClose={handleClose}
        data={Data}
        workshop_id={WorkshopId}
        update={(e) => {
          setUpdate(Update + 1);
          handleClose();

          if (e !== undefined) {
            setShowAlert(true);
            setPesan(e);
          }
        }}
      />
      <ALertNotification
        show={ShowAlert}
        pesan={Pesan}
        handleClose={() => handleCloseAlert()}
      />
    </div>
  );
}

export default WorkshopPage;
