const initialState = {
    BerlanggananProduk: [],
    KategoriDetail: [],
    KiloanProduk: [],
    AllProduk: []
}

const produkReducer = (state = initialState, action) => {
    switch (action.type) {
        case "BERLANGGANAN_CHANGE":
            return {
                ...state,
                BerlanggananProduk: action.payload
            }
        case "KATEGORI_DETAIL_CHANGE":
            return {
                ...state,
                KategoriDetail: action.payload
            }
        case "KILOAN_CHANGE":
            return {
                ...state,
                KiloanProduk: action.payload
            }
        case "ALL_PRODUK_CHANGE":
            return {
                ...state,
                AllProduk: action.payload
            }
        default:
            return state
    }

}

export default produkReducer