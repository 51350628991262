const initialState = {
    ListUser: [],
    ListUserWorkshop: [],
    ListRole: [],
    ListKategoriUser: []
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LIST_USER_CHANGE":
            return {
                ...state,
                ListUser: action.payload
            }
        case "LIST_ROLE_CHANGE":
            return {
                ...state,
                ListRole: action.payload
            }
        case "LIST_KATEGORI_USER_CHANGE":
            return {
                ...state,
                ListKategoriUser: action.payload
            }
        case "LIST_USER_WORKSHOP_CHANGE":
            return {
                ...state,
                ListUserWorkshop: action.payload
            }
        default:
            return state
    }

}

export default userReducer