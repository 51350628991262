import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getDataAPI,
  patchDataAPI,
} from "../../../config/redux/action/newRequestAction";
import SettingDayActiveList from "./SettingDayActiveList";

function SettingDayActive() {
  const [update, setUpdate] = useState(true);
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDataAPI({
        url: "/setting/day-active",
      })
    )
      .then((res) => {
        let tempRes = res.data;
        let tempValue = [];

        for (let i = 0; i < tempRes.length; i++) {
          const val = tempRes[i];
          if (val.active) {
            tempValue = tempValue.concat(val.value);
          }
        }

        setData(tempRes);
        setValue(tempValue);
        setUpdate(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onUpdate = (e) => {
    let tempValue = value;
    if (e.active) {
      tempValue = tempValue.concat(e.value);
    } else {
      if (e.value.length > 1) {
        for (let i = 0; i < e.value.length; i++) {
          const value = e.value[i];
          tempValue = tempValue.filter((val) => val !== value);
        }
      } else {
        tempValue = tempValue.filter((val) => val !== e.value[0]);
      }
    }
    setValue(tempValue);

    let formData = new FormData();
    formData.append("value", `[${tempValue.toString()}]`);

    dispatch(
      patchDataAPI({
        url: "/setting/day-active",
        data: formData,
      })
    )
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <div className="me-2 bg-white shadow rounded px-2 pt-1 pb-3">
      <div className="d-flex justify-content-center fw-bold">Hari Tidak Aktif</div>

      {update ? (
        <>loading</>
      ) : (
        <div>
          {data.map((val, key) => {
            return (
              <SettingDayActiveList
                key={key}
                value={val}
                onChangeValue={onUpdate}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SettingDayActive;
