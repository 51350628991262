import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDataAPI } from "../../../config/redux/action/newRequestAction";

function AlamatUser({ user_id, show }) {
  const dispatch = useDispatch();
  const [dataAddress, setDataAddress] = useState([]);

  useEffect(() => {
    if (user_id !== null) {
      dispatch(
        getDataAPI({
          url: "/user/alamat",
          data: {
            user_id,
          },
        })
      )
        .then((res) => {
          setDataAddress(res.data);
        })
        .catch((err) => {});
    }
  }, [user_id]);

  useEffect(() => {
    if (!show) {
      setDataAddress([]);
    }
  }, [show]);

  const RowValue = ({ title, value }) => {
    return (
      <div className="d-flex flex-row">
        <div style={{ minWidth: 100 }}>{title}</div>
        <div style={{ minWidth: 10 }}>:</div>
        <div>{value}</div>
      </div>
    );
  };

  return (
    <div style={{ width: "100%", fontSize: 12 }}>
      {dataAddress.length > 0
        ? dataAddress.map((val, key) => {
            return (
              <div
                key={key}
                className="d-flex flex-column border border-primary p-2 rounded mb-2"
              >
                <RowValue title={"Nama Penrima"} value={val.nama_penerima} />
                <RowValue title={"Telp"} value={val.telp_penerima} />
                <RowValue title={"Alamat"} value={val.jalan} />
                <RowValue title={"Kode pos"} value={val.kode_pos} />
              </div>
            );
          })
        : null}
    </div>
  );
}

export default AlamatUser;
