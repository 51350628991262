import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAktifitasUserList } from '../../../config/redux/action/userAction'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker'
import DataTable from 'react-data-table-component'

function AktivitasUserDashboard() {
    const { AktivitasUserList } = useSelector(state => state.globalReducer)
    const [Data, setData] = useState([])
    const [dateRange, setDateRange] = useState([
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate()
    ]);
    const [startDate, endDate] = dateRange;
    const dispatch = useDispatch()

    useEffect(() => {
        if (dateRange[0] !== null && dateRange[1] !== null) {
            dispatch(getAktifitasUserList({
                url: '/logs',
                dateRange,
                type: 'AKTIVITAS_USER_LIST'
            }), dispatch)
        }
    }, [dateRange])

    useEffect(() => {
        if (AktivitasUserList.length > 0) {
            let datas = []
            AktivitasUserList.map(val => {
                datas.push({
                    judul:val.judul,
                    tanggal: moment(new Date(val.createdAt)).format('LLL'),
                    no_order: val.no_order,
                    keterangan: val.keterangan,
                    user: val.user.nama
                })
            })
            setData(datas)
        }else{
            setData([])
        }

    }, [AktivitasUserList])

    const column = [
        {
            name: 'Judul',
            selector: row => row.judul,
            sortable: true,
        },
        {
            name: 'Tanggal',
            selector: row => row.tanggal,
            sortable: true,
        },
        {
            name: 'No Order',
            selector: row => row.no_order,
        },
        {
            name: 'Ketrangan',
            selector: row => row.keterangan,
            grow: 3
        },
        {
            name: 'User',
            selector: row => row.user,
        }
    ]

    const paginationComponentOptions = {
        rowsPerPageText: 'Tampilkan ',
        rangeSeparatorText: ' dari ',
    };

    return (
        <div className='bg-white px-2 py-1 shadow rounded'>
            <div className='w-100 text-center text-secondary mb-3' style={{ fontSize: '14px', fontWeight: 'bold' }}>
                Aktivitas User
            </div>

            <div className='mb-2 d-flex justify-content-between mx-2'>
                <div className=' d-flex align-items-center'>
                    <div className='align-self-center me-2'>
                        Sortir :
                    </div>
                    <ReactDatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        onChange={(update) => {
                            if (update[1] !== null) {
                                update[1] = moment(update[1]).set({ h: 23, m: 59 }).toDate()
                            }
                            setDateRange(update);
                        }}
                        withPortal
                        showMonthDropdown
                        showYearDropdown
                        className='form-control form-control-sm'
                    />
                </div>
            </div>

            <DataTable
                columns={column}
                data={Data}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                paginationPerPage={50}
                paginationRowsPerPageOptions={[50, 100, 500]}
                customStyles={{
                    table: {
                        style: {
                            border: 'solid 0.1px',
                            borderColor: '#b5aab3',

                        }
                    },

                    headRow: {
                        style: {
                            minHeight: '40px',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            color: 'rgba(51, 51, 51, 0.9)',
                        }
                    },
                    rows: {
                        style: {
                            fontSize: '10px',
                            minHeight: '35px',
                            ":hover": {
                                backgroundColor: "#33d49d50",
                            },
                            cursor: 'pointer'
                        },
                    }
                }}
            />

        </div>
    )
}

export default AktivitasUserDashboard