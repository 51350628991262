import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardRatingDetail, Input, SideBar } from "../../components";
import { getRating } from "../../config/redux/action/ratingAction";

function RatingDetailPage() {
  const { RatingDriverKonsumen, RatingWorkshopKonsumen } = useSelector(
    (state) => state.ratingReducer
  );
  const [Data, setData] = useState(null);
  const [Komentar, setKomentar] = useState(0);
  const [Rate, setRate] = useState("all");
  const [Limit, setLimit] = useState(30);
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  let paramsType = params.get("type");
  let paramsUserId = params.get("user_id");

  useEffect(() => {
    if (paramsType === "workshop") {
      dispatch(
        getRating({
          url: "/rating/workshop",
          type: "RATING_WORKSHOP_KONSUMEN_LIST",
          user_id: paramsUserId,
          komentar: Komentar,
          rate: Rate,
          limit: Limit,
        }),
        dispatch
      );
    } else if (paramsType === "wash") {
      dispatch(
        getRating({
          url: "/rating/wash/detail",
          type: "RATING_WORKSHOP_KONSUMEN_LIST",
          user_id: paramsUserId,
          komentar: Komentar,
          rate: Rate,
          limit: Limit,
        }),
        dispatch
      );
    } else {
      dispatch(
        getRating({
          url: "/rating/driver",
          type: "RATING_DRIVER_KONSUMEN_LIST",
          user_id: paramsUserId,
          komentar: Komentar,
          rate: Rate,
          limit: Limit,
        }),
        dispatch
      );
    }
  }, [Komentar, Rate, Limit]);

  useEffect(() => {
    setData([]);

    if (paramsType === "workshop") {
      return setData(RatingWorkshopKonsumen);
    } else if (paramsType === "wash") {
      return setData(RatingWorkshopKonsumen);
    } else {
      return setData(RatingDriverKonsumen);
    }
  }, [RatingDriverKonsumen, RatingWorkshopKonsumen]);

  return (
    <div>
      <SideBar />
      <div className="content px-1">
        <div className="bg-white shadow rounded px-2 pt-2 pb-5">
          <div className="px-2 py-1 mt-1 mb-3 d-flex flex-column border border-1 rounded">
            <div className="fw-bold align-self-center fs-4">Filter:</div>

            <div className="row d-flex justify-content-center">
              <div className="col-3 col-md-3 col-lg-2">
                <Input
                  judul={"Rate"}
                  type={"select"}
                  value={Rate}
                  data={[
                    { id: "all", name: "semua" },
                    { id: 5, name: "5" },
                    { id: 4, name: "4" },
                    { id: 3, name: "3" },
                    { id: 2, name: "2" },
                    { id: 1, name: "1" },
                  ]}
                  onChange={(e) => setRate(e.target.value)}
                  className={"form-select form-select-sm"}
                />
              </div>
              <div className="col-5 col-md-3">
                <Input
                  judul={"Komentar"}
                  type={"select"}
                  value={Komentar}
                  data={[
                    { id: "0", name: "semua Komentar" },
                    { id: "1", name: "Ada Komentar" },
                    { id: "2", name: "Tidak ada Komentar" },
                  ]}
                  onChange={(e) => setKomentar(e.target.value)}
                  className={"form-select form-select-sm"}
                />
              </div>
              <div className="col-3 col-md-2">
                <Input
                  judul={"Limit"}
                  type={"select"}
                  value={Limit}
                  data={[
                    { id: "30", name: "30" },
                    { id: "50", name: "50" },
                    { id: "100", name: "100" },
                  ]}
                  onChange={(e) => setLimit(e.target.value)}
                  className={"form-select form-select-sm"}
                />
              </div>
            </div>
          </div>

          {Data !== null
            ? Data.map((val, key) => {
                return <CardRatingDetail key={key} data={ (paramsType === "wash")? val.rating_workshop: val} />;
              })
            : null}
        </div>
      </div>
    </div>
  );
}

export default RatingDetailPage;
