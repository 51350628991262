import axios from "axios";
import { nyonyaaAdminEndpoint } from "../../../utils";

export const getPromoList = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  axios
    .get(nyonyaaAdminEndpoint + payload.url, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      params: {
        user_id: session.data.id,
      },
    })
    .then((res) => {
      if (res.status == 200) {
        dispatch({ type: payload.type, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const savePromo = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("nama", payload.nama);
    data.append("kode", "VOUCER");
    data.append("image", payload.gambar);
    data.append("deskripsi", payload.deskripsi);
    data.append("tanggal_mulai", payload.tanggal_mulai);
    data.append("tanggal_selesai", payload.tanggal_selesai);
    data.append("aktif", payload.aktif);
    data.append("id", payload.id);
    data.append("path", payload.path);

    axios({
      method: payload.id === 0 ? "post" : "patch",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const deletePromo = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("id", payload.id);

    axios({
      method: "DELETE",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};
