const initialState = {
    OrderChartList: [],
    OrderBankChartList: [],
    ProdukChart: [],
    OrderBerlangsungChartList: [],
    OrderHutangChartList: []
}

const chartReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ORDER_CHART_LIST":
            return {
                ...state,
                OrderChartList: action.payload
            }
        case "ORDER_BANK_CHART_LIST":
            return {
                ...state,
                OrderBankChartList: action.payload
            }
        case "PRODUK_CHART_LIST":
            return {
                ...state,
                ProdukChart: action.payload
            }
        case "ORDER_BERLANGSUNG_CHART_LIST":
            return {
                ...state,
                OrderBerlangsungChartList: action.payload
            }
            case "ORDER_HUTANG_CHART_LIST":
                return {
                    ...state,
                    OrderHutangChartList: action.payload
                }
        default:
            return state
    }

}

export default chartReducer