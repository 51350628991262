import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { formatRupiah, statusBayar } from "../../../utils";
import moment from "moment";

function TabOrderBerlangganan({ data, onClick }) {
  const [Data, setData] = useState([]);
  const [Total, setTotal] = useState(0);

  useEffect(() => {
    if (data.length > 0) {
      let datas = [];
      let nilai = 0;
      let total = 0;
      let total_seleruh = 0;
      data.map((val) => {
        nilai = 0;
        total = 0;
        val.order_detail.map((result) => {
          nilai += result.nilai;
        });

        total += nilai + val.diskon + val.biaya_aplikasi+val.ongkos;

        total_seleruh += total;

        let diskon = val.diskon;

        datas.push({
          no_order: val.no_order,
          tanggal_pesan: moment(new Date(val.tanggal_pesan)).format("LLL"),
          nama: val.user.nama,
          status_bayar: statusBayar(val.status),
          status_order: val.tanggal_terima === null ? "Berlangsung" : "Selesai",
          order:
            val.berlangganan !== null
              ? val.berlangganan.berlangganan_detail.length +
                "/" +
                val.berlangganan.stok
              : "None",
          total: formatRupiah(String(total), "Rp. "),
          val,
        });
      });

      setTotal(total_seleruh);
      setData(datas);
    } else {
      setData([]);
    }
  }, [data]);

  const column = [
    {
      name: "No Order",
      selector: (row) => row.no_order,
      wrap: true,
    },
    {
      name: "Tanggal Pesan",
      selector: (row) => row.tanggal_pesan,
      wrap: true,
    },
    {
      name: "Nama",
      selector: (row) => row.nama,
      wrap: true,
    },
    {
      name: "Status Bayar",
      selector: (row) => row.status_bayar,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row) => row.val.status === 1,
          style: {
            backgroundColor: "#D8274E",
            color: "white",
          },
        },
        {
          when: (row) => row.val.status === 2,
          style: {
            backgroundColor: "#173c74",
            color: "white",
          },
        },
        {
          when: (row) => row.val.status === 3 || row.val.status === 5,
          style: {
            backgroundColor: "#FCFF43",
            color: "black",
          },
        },
        {
          when: (row) => row.val.status === 4 || row.val.status === 6,
          style: {
            backgroundColor: "#33D49D",
            color: "black",
          },
        },
      ],
    },
    {
      name: "Status Order",
      selector: (row) => row.status_order,
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row) => row.val.tanggal_terima === null,
          style: {
            backgroundColor: "#FCFF43",
            color: "black",
          },
        },
        {
          when: (row) => row.val.tanggal_terima !== null,
          style: {
            backgroundColor: "#33D49D",
            color: "black",
          },
        },
      ],
    },
    {
      name: "Order",
      selector: (row) => row.order,
      wrap: true,
      right: true,
    },
    {
      name: "Nilai",
      selector: (row) => row.total,
      wrap: true,
      right: true,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Tampilkan ",
    rangeSeparatorText: " dari ",
  };

  return (
    <div>
      <DataTable
        columns={column}
        data={Data}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        customStyles={{
            table: {
              style: {
                border: "solid 0.1px",
                borderColor: "#b5aab3",
              },
            },
  
            headRow: {
              style: {
                minHeight: "40px",
                fontSize: "13px",
                fontWeight: "bold",
                color: "rgba(51, 51, 51, 0.9)",
                borderRight: "solid 0.1px",
                borderLeft: "solid 0.1px",
                borderColor: "#b5aab3",
                backgroundColor: "lavender",
              },
            },
            rows: {
              style: {
                fontSize: "12px",
                minHeight: "50px",
                ":hover": {
                  backgroundColor: "rgba(255, 0, 144, 0.5)",
                },
                cursor: "pointer",
              },
            },
            cells: {
              style: {
                // border: "solid 0.1px",
                borderRight: "solid 0.1px",
                borderLeft: "solid 0.1px",
                borderColor: "#b5aab3",
              },
            },
            headCells: {
              style: {
                // border: "solid 0.1px",
                borderRight: "solid 0.1px",
                borderColor: "#b5aab3",
              },
            },
          }}
          onRowClicked={(val) =>
            onClick(val.val)
          }
      />
    </div>
  );
}

export default TabOrderBerlangganan;
