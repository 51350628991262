import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Input } from "../../";
import { formatNormali, formatRupiah } from "../../../utils";

function EditProduk({ show, handleClose, onchange, data }) {
  const [Qty, setQty] = useState(0);
  const [Harga, setHarga] = useState(0);
  const [Nilai, setNilai] = useState(0);
  const [Potong, setPotong] = useState(0);
  const [Kg, setKg] = useState(0);
  const handleFocus = (event) => event.target.select();

  useEffect(() => {
    if (data !== null) {
      console.log(data);
      setQty(data.qty);
      setPotong(data.potong);
      setKg(data.kg);
      setHarga(formatRupiah(String(data.harga), "Rp ."));
      setNilai(formatRupiah(String(data.nilai), "Rp ."));
    }
  }, [data]);

  useEffect(() => {
    let nilai = parseFloat(Qty) * parseFloat(formatNormali(String(Harga)));

    setNilai(formatRupiah(String(nilai.toFixed(0)), "Rp ."));
  }, [Qty, Harga]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{`Edit Produk`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          <Input
            judul={"Jumlah Qty"}
            placeholder={"Jumlah Qty"}
            type={"number"}
            value={Qty}
            onFocus={handleFocus}
            onChange={(e) => setQty(e.target.value)}
          />
          <Input
            judul={"Harga"}
            placeholder={"Harga"}
            type={"text"}
            value={Harga}
            onFocus={handleFocus}
            onChange={(e) =>
              setHarga(formatRupiah(String(e.target.value), "Rp ."))
            }
          />
          <Input
            judul={"Kilogram"}
            placeholder={"Kilogram"}
            type={"number"}
            value={Kg}
            onFocus={handleFocus}
            onChange={(e) => setKg(e.target.value)}
          />
          {data !== null ? (
            data.produk.kategori_id !== null ? (
              <Input
                judul={"Jumlah Potong"}
                placeholder={"Jumlah Potong"}
                type={"number"}
                value={Potong}
                onFocus={handleFocus}
                onChange={(e) => setPotong(e.target.value)}
              />
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          <div className="mb-3">
            <label>Total : </label>
            <div className="fs-4">{formatRupiah(String(Nilai), "Rp .")}</div>
          </div>
        </div>
        <div className="d-flex justify-content-between mx-3">
          <button
            className="btn btn-danger"
            onClick={() =>
              onchange({
                title: "hapus",
                id: data.id,
              })
            }
          >
            Hapus
          </button>
          <button
            className="btn btn-primary"
            onClick={() =>
              onchange({
                title: "update",
                id: data.id,
                potong: Potong,
                qty: Qty,
                harga: formatNormali(String(Harga)),
                nilai: formatNormali(String(Nilai)),
                kg: Kg,
              })
            }
          >
            Update
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EditProduk;
