import { faUser, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../..";
import {
  deleteUser,
  tambahUser,
  updateUser,
} from "../../../config/redux/action/userAction";
import { emaiValidation, nyonyaaAdminEndpoint } from "../../../utils";
import { isValidPhoneNumber } from "libphonenumber-js";
import AlamatUser from "./AlamatUser";

function ModalUser({
  handleClose,
  show,
  data,
  role,
  update,
  kategoriUserData,
}) {
  const images = useRef(null);
  const [IdUser, setIdUser] = useState(0);
  const [Kode, setKode] = useState("");
  const [Nama, setNama] = useState("");
  const [Email, setEmail] = useState("");
  const [NoTelp, setNoTelp] = useState("");
  const [Aktif, setAktif] = useState(true);
  const [GambarUrl, setGambarUrl] = useState("");
  const [Role, setRole] = useState("");
  const [RoleVal, setRoleVal] = useState("");
  const [TypePassword, setTypePassword] = useState(false);
  const [Password, setPassword] = useState("");
  const [RePassword, setRePassword] = useState("");
  const [phoneVerify, setPhoneVerify] = useState(false);
  const [Gambar, setGambar] = useState("");
  const [Error, setError] = useState("");
  const { ListRole } = useSelector((state) => state.userReducer);
  const [KategoriUser, setKategoriUser] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data === null) {
      setIdUser(0);
      setKode("");
      setNama("");
      setEmail("");
      setNoTelp("");
      setAktif(true);
      setGambarUrl("");
      setGambar("");
      setPassword("");
      setRePassword("");
      setError("");
      setPhoneVerify(false);
      roleChange(1);
      setKategoriUser(1);
    } else {
      setIdUser(data.id);
      setKode(data.kode);
      setNama(data.nama);
      setEmail(data.email);
      setNoTelp(data.notelp);
      setAktif(data.validasi);
      roleChange(role);
      setError("");
      setPassword("");
      setRePassword("");
      setGambar("");
      setPhoneVerify(data.phone_verify);
      if (data.gambar === null) {
        setGambarUrl("");
      } else if (data.gambar.includes("https://")) {
        setGambarUrl(data.gambar);
      } else {
        setGambarUrl(process.env.REACT_APP_STORAGE + data.gambar);
      }
      setKategoriUser(data.kategori_user_id);
    }
  }, [data]);

  const dataKategoriUser = () => {
    let data = [];
    kategoriUserData.map((val) => {
      data.push({
        id: val.id,
        name: val.nama,
      });
    });
    return data;
  };

  const imageChange = (val) => {
    setGambar(val[0]);
    setGambarUrl(URL.createObjectURL(val[0]));
  };

  const roleChange = (val) => {
    setRole(val);
    ListRole.map((data) => {
      if (parseInt(val) === parseInt(data.id)) {
        return setRoleVal([data.name]);
      }
    });
  };

  const manageUser = (val) => {
    if (Nama.length < 5) return setError("Nama minimal 5 karakter");
    if (emaiValidation(Email) === null) return setError("Email tidak sesuai");
    if (!isValidPhoneNumber(String(NoTelp), "ID"))
      return setError("No Telp tidak sesuai");

    if (data === null) {
      if (Password !== RePassword)
        return setError("Password yang anda ketik tidak sama");
      if (Password.length < 5)
        return setError("Panjang Password tidak lebih sama dengan 5");
    }

    if (val === "tambah") {
      dispatch(
        tambahUser({
          url: "/user",
          nama: Nama,
          email: Email,
          notelp: NoTelp,
          role: RoleVal,
          validasi: Aktif,
          password: Password,
          gambar: Gambar,
          aktif: Aktif,
          kategori_user_id: KategoriUser,
          phone_verify: phoneVerify,
        })
      ).then((res) => {
        if (res === "ada") {
          return setError("Email sudah dipakai");
        }
        handleClose();
        update();
      });
    } else if (val === "update") {
      dispatch(
        updateUser({
          url: "/user",
          id: IdUser,
          kode: Kode,
          nama: Nama,
          email: Email,
          notelp: NoTelp,
          role: RoleVal,
          validasi: Aktif,
          password: Password,
          gambar: Gambar,
          aktif: Aktif,
          kategori_user_id: KategoriUser,
          phone_verify: phoneVerify,
        })
      ).then((res) => {
        // if (res === "ada") {
        //   return setError("User tidak bisa di hapus");
        // }
        handleClose();
        update();
      });
    } else if (val === "hapus") {
      dispatch(
        deleteUser({
          url: "/user",
          id: IdUser,
        }),
        dispatch
      ).then((res) => {
        handleClose();
        update();
      });
    }
  };

  return (
    <Modal show={show} size={"xl"} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {data === null ? <>Tambah User</> : <>Edit User</>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="d-flex flex-column">
              <div className="m-3 text-center rounded pointer-event">
                {GambarUrl === "" ? (
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    size="10x"
                    className="set-pointer opacity-50 "
                    onClick={() => images.current.click()}
                  />
                ) : (
                  <img
                    src={GambarUrl}
                    className="set-pointer img-fluid rounded-circle"
                    onClick={() => images.current.click()}
                    height={180}
                    width={180}
                  />
                )}
              </div>
              <div className="mx-3">
                <input
                  type="file"
                  accept="image/*"
                  ref={images}
                  className="form-control d-none"
                  onChange={(e) => imageChange(e.target.files)}
                />
              </div>
              {Error !== "" ? (
                <div
                  className="text-danger align-self-center px-4 rounded-pill mb-3"
                  style={{
                    backgroundColor: "rgba(255, 166, 166, 0.5)",
                  }}
                >
                  {Error}
                </div>
              ) : (
                <></>
              )}
              <div className="d-flex">
                <div className="w-50 align-self-center">
                  <Input
                    judul={"Nama"}
                    type="text"
                    placeholder={"Masukan Nama User"}
                    value={Nama}
                    onChange={(e) => setNama(e.target.value)}
                  />
                </div>
                <div className="w-50 ps-1 align-self-center">
                  <Input
                    judul={"Role"}
                    type="select"
                    value={Role}
                    data={ListRole}
                    onChange={(e) => roleChange(e.target.value)}
                  />
                </div>
              </div>
              <Input
                judul={"Email"}
                type="text"
                placeholder={"Email"}
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="d-flex">
                <div className="w-50 align-self-center">
                  <Input
                    judul={"No Telp"}
                    type="number"
                    placeholder={"No Telp User"}
                    value={NoTelp}
                    onChange={(e) => setNoTelp(e.target.value)}
                  />
                </div>

                <div className="w-25 text-center align-self-center">
                  <Input
                    judul={"Aktif"}
                    type="aktif"
                    checked={Aktif}
                    onChange={(e) => setAktif(e.target.checked)}
                  />
                </div>
                <div className="w-25 text-center align-self-center">
                  <Input
                    judul={"Nomor Aktif"}
                    type="aktif"
                    checked={phoneVerify}
                    onChange={(e) => setPhoneVerify(e.target.checked)}
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="w-50 align-self-start">
                  <Input
                    judul={"Password "}
                    type={TypePassword ? `text` : `password`}
                    onChange={(e) => setPassword(e.target.value)}
                    value={Password}
                    placeholder="Password"
                  />
                  <div className="d-flex w-100">
                    <div className="form-check form-switch py-2 align-self-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={TypePassword}
                        onChange={(e) => setTypePassword(e.target.checked)}
                      />
                    </div>
                    <label
                      className="align-self-center set-pointer"
                      onClick={() =>
                        TypePassword
                          ? setTypePassword(false)
                          : setTypePassword(true)
                      }
                    >
                      Show Password
                    </label>
                  </div>
                  {/* <div className='w-50 ps-1 align-self-start'> */}

                  {/* </div> */}
                </div>
                <div className="w-50 ps-1 align-self-start flex-column">
                  <Input
                    judul={"Re Passwod"}
                    type={TypePassword ? `text` : `password`}
                    placeholder="Re-Password"
                    value={RePassword}
                    onChange={(e) => setRePassword(e.target.value)}
                  />
                  <Input
                    judul={"Kategori User"}
                    type={"select"}
                    data={dataKategoriUser()}
                    value={KategoriUser}
                    onChange={(e) => setKategoriUser(e.target.value)}
                  />
                </div>
              </div>

              {Error !== "" ? (
                <div
                  className="text-danger align-self-center px-4 rounded-pill mb-3"
                  style={{
                    backgroundColor: "rgba(255, 166, 166, 0.5)",
                  }}
                >
                  {Error}
                </div>
              ) : (
                <></>
              )}

              <div className="w-100 pt-3 border-top d-flex justify-content-center">
                {data !== null ? (
                  <div className="d-flex justify-content-around w-100">
                    {/* <button
                  className="btn btn-danger px-4"
                  onClick={() => manageUser("hapus")}
                >
                  Hapus
                </button> */}

                    <button
                      className="btn btn-primary px-4"
                      onClick={() => manageUser("update")}
                    >
                      Update
                    </button>
                  </div>
                ) : (
                  <button
                    className="btn btn-primary px-4"
                    onClick={() => manageUser("tambah")}
                  >
                    Simpan
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-3 mt-lg-0">
            <div className="mb-3 fw-bold">Daftar alamat pengguna</div>
            {data !== null && <AlamatUser user_id={data.id} show={show} />}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalUser;
