import React, { useCallback, useEffect, useState } from "react";
import { ImageZoom, TablePagination } from "../../components";
import { Chrono } from "react-chrono";
import { getDataAPI } from "../../config/redux/action/newRequestAction";
import { useDispatch } from "react-redux";
import moment from "moment";

function OrderDetailTimeline({ DataProduksi, no_order }) {
  const [isZoomed, setIsZoomed] = useState(false);
  const [Image, setImage] = useState("");
  const [Alt, setAlt] = useState("");
  const header = ["Judul", "Keterangan", "User", 'Tanggal'];
  const [data, setData] = useState(null);
  const [dataLength, setDataLength] = useState(0);
  const [dataRequest, setDataRequest] = useState({
    page: 1,
    limit: 10,
    no_order,
  });

  const dispatch = useDispatch();

  const handleImgLoad = useCallback(() => {
    setIsZoomed(true);
  }, []);

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  useEffect(() => {
    getDataAPI({
      url: "/logs/order",
      data: dataRequest,
    })(dispatch)
      .then((res) => {
        // console.log({ res: res.data });
        setData(
          res.data.rows.map((val, key) => {
            return (
              <tr key={key} style={{fontSize:10}}>
                <td>{val.judul}</td>
                <td>{val.keterangan}</td>
                <td>{val.user.nama}</td>
                <td>{moment(new Date(val.createdAt)).format('LLL')}</td>
              </tr>
            );
          })
        );
        setDataLength(res.data.count);
      })
      .catch((err) => {});
  }, [dataRequest, DataProduksi]);

  return (
    <div className="row mt-2">
      {DataProduksi.length > 0 && (
        <div className="col-12 col-md-12 col-lg-6">
          <div className="bg-white px-2 py-1 mb-2">
            <div className="fw-bold fs-5 mt-1 mb-2 text-center">
              Timeline Produksi
            </div>
            <Chrono
              items={DataProduksi}
              activeItemIndex={-1}
              mode="VERTICAL"
              disableClickOnCircle={true}
              onItemSelected={(val) => {
                if (val.active === true) {
                  setIsZoomed(true);
                  setImage(val.media.source.url);
                  setAlt(val.cardTitle);
                }
              }}
              hideControls
              theme={{
                primary: "dodgerblue",
                secondary: "",
                cardBgColor: "",
                cardForeColor: "",
                titleColor: "black",
                titleColorActive: "black",
              }}
              fontSizes={{
                cardSubtitle: "0.7rem",
                cardText: "0.7rem",
                cardTitle: "0.7rem",
                title: "0.7rem",
              }}
            />
            {isZoomed ? (
              <ImageZoom
                isZoomed={isZoomed}
                image={Image}
                handleImgLoad={handleImgLoad}
                zoomChange={handleZoomChange}
                alt={Alt}
              />
            ) : null}
          </div>
        </div>
      )}
      <div className="col-12 col-md-12 col-lg-6">
        <div className="bg-white px-2 py-1">
        <div className="fw-bold fs-5 mt-1 mb-2 text-center">
              Aktivitas
            </div>
          <TablePagination
            data={data}
            currentPage={dataRequest.page}
            pageSize={dataRequest.limit}
            header={header}
            setCurrentPage={(page) => {
              setDataRequest((prev) =>({ ...prev, page }));
            }}
            totalCount={dataLength}
          />
        </div>
      </div>
    </div>
  );
}

export default OrderDetailTimeline;
