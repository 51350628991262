import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderChartList } from '../../../config/redux/action/chartAction'
import DataTable from 'react-data-table-component';
import { jenisOrder } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import ALertNotification from '../AlertNotification';
import moment from 'moment';

function OrderBerlangsung() {
    const { OrderBerlangsungChartList } = useSelector(state => state.chartReducer)
    const [Data, setData] = useState([])
    const [show, setShow] = useState(false);
    const [Pesan, setPesan] = useState('');
    const handleClose = () => setShow(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getOrderChartList({
            url: '/chart/order/berlangsung',
            type: 'ORDER_BERLANGSUNG_CHART_LIST'
        }), dispatch)
    }, [])

    useEffect(() => {
        if (OrderBerlangsungChartList.length > 0) {
            let datas = []
            OrderBerlangsungChartList.map(val => {
                datas.push({
                    no_order: val.no_order,
                    tanggal: moment(new Date(val.tanggal_pesan)).format('LLL'),
                    nama: val.user.nama,
                    jenis_order: jenisOrder(val.jenis_order),
                    bayar: val.payment_accept?.nama || 'Default Payment',
                    val
                })
            })

            setData(datas)
        }
    }, [OrderBerlangsungChartList])

    const column = [
        {
            name: 'No Order',
            selector: row => row.no_order,
            sortable: true,
        },
        {
            name: 'Tanggal',
            selector: row => row.tanggal,
            sortable: true,
        },
        {
            name: 'Nama',
            selector: row => row.nama,
        },
        {
            name: 'Jenis Order',
            selector: row => row.jenis_order,
        },
        {
            name: 'Pembayaran',
            selector: row => row.bayar,
        },
    ]

    const paginationComponentOptions = {
        rowsPerPageText: 'Tampilkan ',
        rangeSeparatorText: ' dari ',
    };


    return (
        <div className='bg-white px-2 py-1 shadow rounded h-100'>
            <div className='w-100 text-center text-secondary mb-3' style={{ fontSize: '14px', fontWeight: 'bold' }}>
                Order Berlangsung
            </div>


            <DataTable
                columns={column}
                data={Data}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 50]}
                customStyles={{
                    table: {
                        style: {
                            border: 'solid 0.1px',
                            borderColor: '#b5aab3',

                        }
                    },

                    headRow: {
                        style: {
                            minHeight: '40px',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            color: 'rgba(51, 51, 51, 0.9)',
                        }
                    },
                    rows: {
                        style: {
                            fontSize: '10px',
                            minHeight: '35px',
                            ":hover": {
                                backgroundColor: "#33d49d50",
                            },
                            cursor: 'pointer'
                        },
                    }
                }}
                onRowClicked={data => {
                    if (data.val.jenis_order === 'berlangganan') {
                        if (data.val.status === 4 || data.val.status === 6) {
                            navigate('/order/berlangganan/detail?no_order=' + data.val.no_order)
                        } else {
                            setShow(true)
                            return setPesan('No Order ini belum melakukan pembayaran')
                        }
                    } else {
                        navigate('/order/detail?no_order=' + data.val.no_order)
                    }
                }}
            />
            <ALertNotification
                show={show}
                handleClose={handleClose}
                pesan={Pesan}
            />
        </div>
    )
}

export default OrderBerlangsung