import { faImage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Input } from '../..'
import { deleteDriver, saveDriver, updateDriver } from '../../../config/redux/action/driverAction'
import { nyonyaaAdminEndpoint } from '../../../utils'

function ModalDriver({ show, handleClose, data, update }) {
    const images = useRef(null)
    const [DriverID, setDriverID] = useState(0)
    const [Nama, setNama] = useState('')
    const [Alamat, setAlamat] = useState('')
    const [PlatNomor, setPlatNomor] = useState('')
    const [Aktif, setAktif] = useState(true)
    const [GambarUrl, setGambarUrl] = useState('')
    const [Gambar, setGambar] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        if (data !== null) {
            setDriverID(data.id)
            setNama(data.nama)
            setAlamat(data.alamat)
            setPlatNomor(data.plat_nomor)
            setAktif((data.status === 1) ? true : false)
            setGambarUrl(nyonyaaAdminEndpoint + data.gambar)
        } else {
            setDriverID(0)
            setNama('')
            setAlamat('')
            setPlatNomor('')
            setAktif(true)
            setGambarUrl('')
            setGambar('')
        }
    }, [data])

    const imageChange = (val) => {
        setGambar(val[0])
        setGambarUrl(URL.createObjectURL(val[0]))
    }

    const manageDriver = (val) => {
        if (val === 'tambah') {
            dispatch(saveDriver({
                url: '/driver',
                gambar: Gambar,
                nama: Nama,
                alamat: Alamat,
                plat_nomor: PlatNomor,
                aktif: Aktif,
            }), dispatch)
                .then(() => {
                    handleClose()
                    update()
                })
        } else if (val === 'update') {
            dispatch(updateDriver({
                url: '/driver/update',
                driver_id: DriverID,
                gambar: Gambar,
                nama: Nama,
                alamat: Alamat,
                plat_nomor: PlatNomor,
                aktif: Aktif,
            }), dispatch)
                .then(() => {
                    handleClose()
                    update()
                })
        } else {
            dispatch(deleteDriver({
                url: '/driver/delete',
                driver_id: DriverID,
            }))
                .then(() => {
                    handleClose()
                    update()
                })
        }


    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {`Tambah Driver`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column'>
                    <div className='m-3 text-center rounded pointer-event'>
                        {
                            (GambarUrl === '') ?
                                <FontAwesomeIcon icon={faImage} size='10x' className='set-pointer opacity-50 ' onClick={() => images.current.click()} />
                                :
                                <img src={GambarUrl} className='set-pointer img-fluid w-50' onClick={() => images.current.click()} />
                        }
                    </div>
                    <div className='mx-3'>
                        <input type='file' ref={images} className='form-control d-none' onChange={e => imageChange(e.target.files)} />
                    </div>
                    <Input judul={'Nama'} type='text' placeholder={'Masukan Nama Driver'}
                        value={Nama}
                        onChange={(e) => setNama(e.target.value)}
                    />
                    <Input judul={'Alamat'} type='textarea' placeholder={'Alamat'}
                        value={Alamat}
                        onChange={(e) => setAlamat(e.target.value)}
                    />
                    <div className='d-flex'>
                        <div className='w-50 align-self-center'>
                            <Input judul={'Plat Nomor'} type='text' placeholder={'Plat Nomor Driver'}
                                value={PlatNomor}
                                onChange={(e) => setPlatNomor(e.target.value)}
                            />
                        </div>

                        <div className='w-50 text-center align-self-center'>
                            <Input judul={'Aktif'} type='aktif' placeholder={'Jumlah Pesan'}
                                checked={Aktif}
                                onChange={(e) => setAktif(e.target.checked)}
                            />
                        </div>
                    </div>

                    <div className='w-100 pt-3 border-top d-flex justify-content-center'>
                        {
                            (data !== null) ?
                                <div className='d-flex justify-content-around w-100'>
                                    <button
                                        className='btn btn-danger px-4'
                                        onClick={() => manageDriver('hapus')}
                                    >
                                        Hapus
                                    </button>

                                    <button
                                        className='btn btn-primary px-4'
                                        onClick={() => manageDriver('update')}
                                    >
                                        Update
                                    </button>
                                </div>
                                :
                                <button
                                    className='btn btn-primary px-4'
                                    onClick={() => manageDriver('tambah')}
                                >
                                    Simpan
                                </button>

                        }
                    </div>
                </div>


            </Modal.Body>
        </Modal>
    )
}

export default ModalDriver