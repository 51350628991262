const initialState = {
    ReportListOrder: []
}


const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REPORT_LIST_ORDER":
            return {
                ...state,
                ReportListOrder: action.payload
            }
        default:
            return state
    }

}

export default reportReducer