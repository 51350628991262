import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getUserList } from '../../../config/redux/action/userAction'
import { getDataAPI } from '../../../config/redux/action/newRequestAction'

function AddDriver({ show, handleClose, onchange }) {
    const { ListUser } = useSelector(state => state.userReducer)
    const dispatch = useDispatch()

    useEffect(() => {
        if (show === true) {
            dispatch(
                getDataAPI({
                  url: "/user",
                  type: "LIST_USER_CHANGE",
                  data: {
                    role_id: 3,
                    validasi: true,
                  },
                })
              );
        }
    }, [show])

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {`Add  Driver`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (ListUser.length > 0) ?
                        ListUser.map(val => {
                            return (
                                <div key={val.user.id}
                                    className='d-flex flex-column hover border rounded py-1 px-2'
                                    onClick={() => {
                                        onchange(val.user.id)
                                        handleClose()
                                    }}
                                >
                                    <div>
                                        Nama : {val.user.nama}
                                    </div>
                                    <div>
                                        Email : {val.user.email}
                                    </div>
                                    <div>
                                        Alamat : -
                                    </div>
                                </div>
                            )
                        })
                        :
                        <></>
                }
            </Modal.Body>
        </Modal>
    )
}

export default AddDriver