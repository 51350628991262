import React from "react";
import TableComponent from "./TableComponent";
import Pagination from "./Pagination";

function TablePagination({
  header,
  pageSize,
  data,
  setCurrentPage,
  currentPage,
  totalCount,
}) {
  return (
    <div>
      <TableComponent header={header} data={data} />
      <Pagination
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
}

export default TablePagination;
