import React from "react";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ReportFilter({
  dateRange,
  setDateRange,
  status,
  setStatus,
  search,
  setSearch,
  setUpdate
}) {
  const [startDate, endDate] = dateRange;

  return (
    <div className="border px-3 py-2 d-flex flex-column">
      <div className="fw-bold align-self-center">Filter</div>
      <div
        className="row d-flex justify-content-center"
        style={{ fontSize: 12 }}
      >
        <div className="col-6 col-sm-4 col-md-4 col-lg-3">
          <div className="fw-bold">Tanggal</div>
          <ReactDatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              if (update[1] !== null) {
                update[1] = moment(update[1]).set({ h: 23, m: 59 }).toDate();
              }
              setDateRange(update);
            }}
            withPortal
            dateFormat="dd/MM/yyyy"
            className="form-control form-control-sm"
          />
        </div>
        <div className="col-6 col-sm-4 col-md-4 col-lg-3">
          <div className="fw-bold mb-1">Status Bayar</div>
          <select
            className="form-select form-select-sm"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value={0}>Semua</option>
            <option value={1}>Sudah Bayar</option>
            <option value={2}>Belum Bayar</option>
          </select>
        </div>
        <div className="col-6 col-sm-4 col-md-4 col-lg-3">
          <div className="fw-bold mb-1">Search</div>
          <div className="d-flex">
            <input
              type={"text"}
              className="form-control form-control-sm shadow-none me-1"
              placeholder="cari berdasarkan no order"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button
              className="btn btn-sm btn-primary"
              onClick={() => setUpdate()}
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportFilter;
