import axios from "axios";
import { nyonyaaAdminEndpoint } from "../../../utils";

export const getVoucerList = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  axios
    .get(nyonyaaAdminEndpoint + payload.url, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      params: {
        user_id: session.data.id,
      },
    })
    .then((res) => {
      if (res.status == 200) {
        dispatch({ type: payload.type, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const saveVoucer = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("kode", payload.kode.toUpperCase());
    data.append("nama", payload.nama);
    data.append("image", payload.gambar);
    data.append("deskripsi", payload.deskripsi);
    data.append("harga", payload.harga);
    data.append("persen", payload.persen);
    data.append("min_harga", payload.min_harga);
    data.append("max_harga", payload.max_harga);
    data.append("tanggal_mulai", payload.tanggal_mulai);
    data.append("tanggal_selesai", payload.tanggal_selesai);
    data.append("stok", payload.stok);
    data.append("aktif", payload.aktif);
    data.append("id", payload.id);
    data.append("jenis_order", payload.jenis_order);
    data.append("order", payload.order);
    data.append("kategori_user_id", payload.kategori_user_id);

    axios({
      method: payload.id === 0 ? "post" : "patch",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};
