import axios, { Axios } from "axios";
import { nyonyaaAdminEndpoint } from "../../../utils";

export const getDataAPI = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));

  return new Promise((resolve, reject) => {
    axios
      .get(nyonyaaAdminEndpoint + payload.url, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${session.data.jwt}`,
        },
        params: payload.data,
      })
      .then((res) => {
        if (res.status === 200) {
          if (payload.type !== undefined) {
            dispatch({ type: payload.type, payload: res.data.data });
          }
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const patchDataAPI = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  return new Promise((resolve, reject) => {
    axios({
      method: "patch",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data: payload.data,
    })
      .then((res) => {
        if (res.status === 202 || res.status === 201 || res.status === 200) {
          if (payload.type !== undefined)
            dispatch({ type: payload.type, payload: res.data.data });
          return resolve(res);
        }
        reject(true);
      })
      .catch((err) => {
        resolve(err);
      });
  });
};
