import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalProduk from "./ModalProduk";
import {
  getKategoriDetailChange,
  getProdukChange,
} from "../../../config/redux/action/produkAction";
import { useEffect, useState } from "react";
import { CardMaster } from "../../atoms";

function ModalMasterSatuan({ kategori_id }) {
  const { BerlanggananProduk, KategoriDetail } = useSelector(
    (state) => state.produkReducer
  );
  const [show, setShow] = useState(false);
  const [Data, setData] = useState(null);
  const [Update, setUpdate] = useState(1);
  const [KategoriDetailVal, setKategoriDetailVal] = useState(0);
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getKategoriDetailChange(
        {
          url: "/produk/kategori?kategori_produk_id=" + kategori_id,
          type: "KATEGORI_DETAIL_CHANGE",
        },
        dispatch
      )
    );
  }, [kategori_id]);

  useEffect(() => {
    if (KategoriDetail.length > 0) {
      dispatch(
        getProdukChange({
          url: "/produk",
          kategori_id,
          kategori_detail_id:
            KategoriDetailVal === 0 ? KategoriDetail[0].id : KategoriDetailVal,
          type: "BERLANGGANAN_CHANGE",
        }),
        dispatch
      );
    }
  }, [KategoriDetail, Update, KategoriDetailVal, kategori_id]);

  const showModal = (val) => {
    setData(val);
    setShow(true);
  };

  return (
    <div className="w-100">
      <div className="row py-2">
        <div className="col-sm-8 col-lg-6 d-flex align-items-center">
          <div className="pe-2 fw-bold">Kategori</div>
          <select
            className="form-select form-select-sm"
            onChange={(e) => setKategoriDetailVal(e.target.value)}
          >
            {KategoriDetail.length > 0 ? (
              KategoriDetail.map((val) => {
                return (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </select>
        </div>
        <div className="col-12 col-sm-4 col-lg-6 text-end mt-2 mt-sm-0">
          <button className="btn btn-primary" onClick={() => showModal(null)}>
            Tambah Produk
          </button>
        </div>
      </div>
      {BerlanggananProduk.length > 0 ? (
        BerlanggananProduk.map((val) => {
          return (
            <CardMaster
              key={val.id}
              val={val}
              click={(val) => showModal(val)}
            />
          );
        })
      ) : (
        <div className="d-flex justify-content-center p-2">
          Data tidak ditemukan
        </div>
      )}
      <ModalProduk
        show={show}
        handleClose={() => handleClose()}
        data={Data}
        update={() => setUpdate(Update + 1)}
        kategori_id={kategori_id}
      />
    </div>
  );
}

export default ModalMasterSatuan;
