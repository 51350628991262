import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../..";
import {
  deleteProduk,
  saveProduk,
  updateProduk,
} from "../../../config/redux/action/produkAction";
import { formatNormali, formatRupiah } from "../../../utils";
import ALertNotification from "../AlertNotification";

function ModalProduk({ show, handleClose, data, update, kategori_id }) {
  const { KategoriDetail } = useSelector((state) => state.produkReducer);
  const [IdProduk, setIdProduk] = useState(0);
  const [Judul, setJudul] = useState("");
  const [Nama, setNama] = useState("");
  const [Keterangan, setKeterangan] = useState("");
  const [Harga, setHarga] = useState("");
  const [Aktif, setAktif] = useState(true);
  const [Gambar, setGambar] = useState("");
  const [GambarUrl, setGambarUrl] = useState("");
  const [Kapasitas, setKapasitas] = useState("3");
  const [Stok, setStok] = useState(1);
  const [Satuan, setSatuan] = useState("");
  const [Status, setStatus] = useState(false);
  const [Account, setAccount] = useState("4-40000");
  const [KategoriDetailVal, setKategoriDetailVal] = useState("");
  const [Diskon, setDiskon] = useState(0);
  const [Show, setShow] = useState(false);
  const [Pesan, setPesan] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (data !== null) {
      setStatus(true);
      setIdProduk(data.id);
      setNama(data.nama);
      setHarga(formatRupiah(String(data.harga), "Rp ."));
      setAktif(data.aktif);
      setKeterangan(data.keterangan);
      setKapasitas(data.kapasitas);
      setStok(data.qty_order);
      setGambar(data.gambar);
      setGambarUrl(process.env.REACT_APP_STORAGE + data.gambar);
      setJudul(data.nama);
      setSatuan(data.satuan);
      setDiskon(formatRupiah(String(data.diskon), "Rp. "));
      setKategoriDetailVal(data.kategori_detail_id);
      setAccount(data.account_code);
    } else {
      setStatus(false);
      setIdProduk("");
      setNama("");
      setHarga("");
      setAktif("");
      setKeterangan("");
      setKapasitas(3);
      setStok(1);
      setGambar("");
      setJudul("");
      setSatuan("");
      setDiskon(formatRupiah("0", "Rp. "));
      setAccount("4-40000");
      setGambarUrl("");
    }
  }, [data]);

  useEffect(() => {
    if (KategoriDetail.length > 0) {
      setKategoriDetailVal(KategoriDetail[0].id);
    }
  }, [KategoriDetail]);

  const DataAccount = [
    { id: "4-40000", name: "4-40000 (Pendapatan)" },
    { id: "4-40101", name: "4-40101 (Pendapatan Ongkir)" },
  ];

  const imageChange = (val) => {
    setGambar(val[0]);
    setGambarUrl(URL.createObjectURL(val[0]));
  };

  const onTambah = () => {
    dispatch(
      saveProduk({
        url: "/produk",
        kategori_id,
        kategori_detail_id: KategoriDetailVal,
        nama: Nama,
        keterangan: Keterangan,
        harga: formatNormali(Harga),
        aktif: Aktif,
        gambar: Gambar,
        kapasitas: Kapasitas,
        stok: Stok,
        satuan: Satuan,
        diskon: formatNormali(Diskon),
        kode: "master",
        account_code: Account,
      }),
      dispatch
    ).then(() => {
      handleClose();
      update();
    });
  };

  const onUpdate = () => {
    dispatch(
      updateProduk({
        url: "/produk",
        id: IdProduk,
        kategori_id,
        kategori_detail_id: KategoriDetailVal,
        nama: Nama,
        keterangan: Keterangan,
        harga: formatNormali(Harga),
        aktif: Aktif,
        gambar: Gambar,
        kapasitas: Kapasitas,
        stok: Stok,
        satuan: Satuan,
        diskon: formatNormali(Diskon),
        kode: "master",
        account_code: Account,
      }),
      dispatch
    ).then(() => {
      handleClose();
      update();
    });
  };

  const onDelete = () => {
    dispatch(
      deleteProduk({
        url: "/produk",
        id: IdProduk,
      }),
      dispatch
    )
      .then(() => {
        handleClose();
        update();
      })
      .catch((result) => {
        if (result.response.status === 403) {
          setShow(true);
          return setPesan(
            "Tidak bisa di hapus, data digunakan pada transaksi Order"
          );
        }
      });
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{Judul === "" ? "Tambah Produk Baru" : Judul}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="w-100">
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-4">
            <div className="m-3 text-center border rounded d-flex justify-content-center align-items-center">
              {GambarUrl === "" ? (
                <FontAwesomeIcon
                  icon={faImage}
                  size="10x"
                  className="opacity-25 img-fluid px-3"
                />
              ) : (
                <img src={GambarUrl} className="img-fluid" />
              )}
            </div>
            <div className="d-flex justify-content-center mb-2 text-danger">
              Ukuran minimal 300 x 300
            </div>
            <div className="mx-3">
              <input
                type="file"
                className="form-control"
                onChange={(e) => imageChange(e.target.files)}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-8 mt-3 mt-sm-0 px-4 px-sm-2">
            <Input
              judul={"Kategori Detail"}
              type="select"
              placeholder={"Kategori"}
              value={KategoriDetailVal}
              data={KategoriDetail}
              onChange={(e) => setKategoriDetailVal(e.target.value)}
            />
            <div className="row">
              <div className="col-6 col-sm-12 col-lg-8">
                <Input
                  judul={"Nama"}
                  type="text"
                  placeholder={"Nama Produk"}
                  value={Nama}
                  onChange={(e) => setNama(e.target.value)}
                />
              </div>
              <div className="col-6 col-sm-4 col-lg-3">
                <Input
                  judul={"Satuan"}
                  type="text"
                  placeholder={"Satuan Produk"}
                  value={Satuan}
                  onChange={(e) => setSatuan(e.target.value)}
                  maxLength={3}
                  style={{ textTransform: "uppercase" }}
                />
              </div>

              <Input
                judul={"Keterangan"}
                type="textarea"
                placeholder={"Keterangan Produk"}
                value={Keterangan}
                onChange={(e) => setKeterangan(e.target.value)}
              />

              <div className="col-6 col-sm-6 col-lg-3">
                <Input
                  judul={"Harga"}
                  type="text"
                  placeholder={"Rp 10.000"}
                  value={Harga}
                  onChange={(e) =>
                    setHarga(formatRupiah(String(e.target.value), "Rp ."))
                  }
                />
              </div>
              <div className="col-6 col-sm-6 col-lg-3">
                <Input
                  judul={"Diskon"}
                  type="text"
                  placeholder={"Rp 10.000"}
                  value={Diskon}
                  onChange={(e) =>
                    setDiskon(formatRupiah(String(e.target.value), "Rp ."))
                  }
                />
              </div>
              <div className="col-6 col-sm-6 col-lg-3">
                <Input
                  judul={"Harga aplikasi"}
                  type="text"
                  placeholder={"Rp 10.000"}
                  value={formatRupiah(
                    String(
                      formatNormali(String(Harga)) -
                        formatNormali(String(Diskon))
                    ),
                    "Rp. "
                  )}
                  disabled
                  onChange={(e) => {}}
                />
              </div>
              <div className="col-6 col-sm-6 col-lg-1">
                <Input
                  judul={"Aktif"}
                  type="aktif"
                  placeholder={"Jumlah Pesan"}
                  checked={Aktif}
                  onChange={(e) => setAktif(e.target.checked)}
                />
              </div>
              <div className="col-6 col-sm-6 col-lg-2">
                <Input
                  judul={"Pesan"}
                  type="number"
                  placeholder={"Jumlah Stok"}
                  value={Stok}
                  min={1}
                  onChange={(e) => setStok(e.target.value)}
                />
              </div>
              <div className="col-6 col-sm-6 col-lg-2">
                <Input
                  judul={"Kapasitas [KG]"}
                  type="number"
                  placeholder={"Jumlah Kapasitas KG"}
                  value={Kapasitas}
                  min={3}
                  onChange={(e) => setKapasitas(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-lg-5">
                <Input
                  judul={"Account"}
                  type="select"
                  data={DataAccount}
                  value={Account}
                  onChange={(e) => setAccount(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="d-flex justify-content-between mx-3 pt-2 pb-4 border-top">
        <div>
          {Status ? (
            <button className="btn btn-danger" onClick={() => onDelete()}>
              Hapus
            </button>
          ) : (
            <></>
          )}
        </div>
        <div>
          {Status ? (
            <button className="btn btn-primary ms-1" onClick={() => onUpdate()}>
              Update
            </button>
          ) : (
            <button className="btn btn-primary ms-1" onClick={() => onTambah()}>
              Tambah
            </button>
          )}
          <button
            className="btn btn-secondary ms-1"
            onClick={() => handleClose()}
          >
            Tutup
          </button>
        </div>
      </div>
      <ALertNotification
        show={Show}
        pesan={Pesan}
        handleClose={() => setShow(false)}
      />
    </Modal>
  );
}

export default ModalProduk;
