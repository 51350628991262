import axios from "axios";
import { nyonyaaAdminEndpoint } from "../../../utils";

export const getRating = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  axios
    .get(nyonyaaAdminEndpoint + payload.url, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      params: {
        user_id: payload.user_id || null,
        rate: payload.rate || null,
        komentar: payload.komentar || null,
        limit: payload.limit || null,
      },
    })
    .then((res) => {
      if (res.status == 200) {
        dispatch({ type: payload.type, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};
