import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import { getDataAPI } from "../../../config/redux/action/newRequestAction";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Jumlah kg dalam 30 hari",
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

function OrderSumKg() {
  const [BarData, setBarData] = useState({
    labels: [],
    datasets: [
      {
        label: "Kg",
        data: [],
        backgroundColor: "rgba(75, 192, 192, 0.8)",
      },
    ],
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDataAPI({
        url: "/chart/order/kg",
      })
    )
      .then((result) => {
        const data_result = result.data

        let labels = [];
        let datas = [];
  
        data_result.map((val) => {
          labels.push(moment(new Date(val.tanggal)).format('D-MMM'));
          datas.push(val.count_kg);
        });

        datas.reverse()
        labels.reverse()

        setBarData({
            labels,
            datasets: [
              {
                label: 'Kg',
                data: datas,
                backgroundColor: [
                  "rgba(75, 192, 192, 0.8)",
                ],
              },
            ],
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="bg-white px-2 rounded pb-3">
      <Bar options={options} data={BarData} style={{ minHeight: 200 }} />
    </div>
  );
}

export default OrderSumKg;
