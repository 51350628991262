import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SideBar } from "../../components";
import { getOrderList } from "../../config/redux/action/orderAction";
import { OrderDetailBerlangganan as ComponentOrderDetail } from "../../components";

function OrderDetailBerlanggananPage() {
  const params = new URLSearchParams(window.location.search);
  let paramsNoOrder = params.get("no_order");
  const { OrderDetailBerlangganan, OrderDetail } = useSelector(
    (state) => state.orderReducer
  );
  const [Update, setUpdate] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getOrderList({
        url: "/order/berlangganan/detail?no_order=" + paramsNoOrder,
        type: "ORDER_DETAIL_BERLANGGANAN_LIST_CHANGE",
      }),
      dispatch
    );

    dispatch(
      getOrderList({
        url: "/order/detail?no_order=" + paramsNoOrder,
        type: "ORDER_DETAIL_LIST_CHANGE",
      }),
      dispatch
    );
  }, [Update]);

  return (
    <div>
      <SideBar />
      <div className="content">
        {OrderDetailBerlangganan !== null && OrderDetail !== null ? (
          <ComponentOrderDetail
            data={OrderDetailBerlangganan}
            data_faktur={OrderDetail}
            onChange={() => setUpdate(Update + 1)}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default OrderDetailBerlanggananPage;
