import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import { getOrderChartList } from "../../../config/redux/action/chartAction";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../atoms";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Metode Pembayaran terbanyak",
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

function OrderBankPeriode() {
  const { OrderBankChartList } = useSelector((state) => state.chartReducer);
  const [BarData, setBarData] = useState({
    labels: [],
    datasets: [
      {
        label: "Payment",
        data: [],
        backgroundColor: "rgba(255, 0, 144, 0.7)",
      },
    ],
  });
  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const timeData = [
    {
      id: 0,
      value: [
        moment().subtract(1, "days").format("YYYY/MM/DD H:mm:ss"),
        moment().format("YYYY/MM/DD H:mm:ss"),
      ],
      name: "Hari",
    },
    {
      id: 1,
      value: [
        moment().subtract(7, "days").format("YYYY/MM/DD H:mm:ss"),
        moment().format("YYYY/MM/DD H:mm:ss"),
      ],
      name: "Minggu",
    },
    {
      id: 2,
      value: [
        moment().subtract(30, "days").format("YYYY/MM/DD H:mm:ss"),
        moment().format("YYYY/MM/DD H:mm:ss"),
      ],
      name: "Bulan",
    },
  ];

  const [Time, setTime] = useState(timeData[2]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getOrderChartList({
        url: `/chart/order/bank?time=${Time.value}`,
        type: "ORDER_BANK_CHART_LIST",
      }),
      dispatch
    );
  }, [Time]);

  useEffect(() => {
    if (OrderBankChartList.length > 0) {
      let labels = [];
      let datas = [];
      let colorData = [];

      OrderBankChartList.map((val) => {
        labels.push(val.bank);
        datas.push(val.count);
        colorData.push(getRandomColor());
      });

      setBarData({
        labels,
        datasets: [
          {
            label: "Total Pembayaran",
            data: datas,
            backgroundColor: [
              "rgba(255, 99, 132, 0.8)",
              "rgba(54, 162, 235, 0.8)",
              "rgba(255, 206, 86, 0.8)",
              "rgba(75, 192, 192, 0.8)",
              "rgba(153, 102, 255, 0.8)",
            ],
          },
        ],
      });
    } else {
      let labels = [];
      let datas = [];
      setBarData({
        labels,
        datasets: [
          {
            label: "Total Pembayaran",
            data: datas,
            backgroundColor: [
              "rgba(255, 99, 132, 0.8)",
              "rgba(54, 162, 235, 0.8)",
              "rgba(255, 206, 86, 0.8)",
              "rgba(75, 192, 192, 0.8)",
              "rgba(153, 102, 255, 0.8)",
            ],
          },
        ],
      });
    }
  }, [OrderBankChartList]);

  return (
    <div className="w-100">
      <div className="d-flex bg-white px-2 py-1 rounded shadow overflow-auto">
        <div className="px-1" style={{ flex: 4 }}>
          <Bar options={options} data={BarData} style={{ minHeight: 200 }} />
        </div>
        <div style={{ flex: 1 }}>
          <Input
            judul={"Payment"}
            size="12px"
            type={"select"}
            data={timeData}
            value={Time.id}
            onChange={(e) => {
              setTime(timeData[e.target.value]);
            }}
            style={{
              fontSize: "12px",
            }}
            className={'form-select form-select-sm'}
          />
        </div>
      </div>
    </div>
  );
}

export default OrderBankPeriode;
