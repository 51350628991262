import React from "react";
import ReactDatePicker from "react-datepicker";

function Input({ judul, placeholder, type, data, size, ...rest }) {
  return (
    <div
      className="mb-3"
      style={{
        fontSize: size !== undefined ? size : "12px",
      }}
    >
      <label className="form-label fw-bold">{judul}</label>
      {type === "textarea" ? (
        <textarea
          className="form-control form-control-sm"
          placeholder={placeholder}
          rows={5}
          {...rest}
        />
      ) : type === "aktif" ? (
        <div className="form-check form-switch py-2 d-flex justify-content-center">
          <input className="form-check-input" type="checkbox" {...rest} />
        </div>
      ) : type === "select" ? (
        <select className="form-select form-select-sm" {...rest}>
          {data.length > 0 ? (
            data.map((val) => {
              return (
                <option key={val.id} value={val.id}>
                  {val.name}
                </option>
              );
            })
          ) : (
            <option></option>
          )}
        </select>
      ) : type === "reactdatetime" ? (
        <ReactDatePicker
          // minDate={new Date().setDate(new Date().getDate() -1)}
          showDisabledMonthNavigation
          showTimeInput
          dateFormat="dd/MM/yyyy h:mm aa"
          className="form-control form-control-sm"
          {...rest}
        />
      ) : (
        <input
          type={type}
          className="form-control form-control-sm"
          placeholder={placeholder}
          {...rest}
        />
      )}
    </div>
  );
}

export default Input;
