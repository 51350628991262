import axios from "axios";
import { nyonyaaAdminEndpoint } from "../../../utils";

export const getOrderChartList = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  axios
    .get(nyonyaaAdminEndpoint + payload.url, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      params: {
        user_id: session.data.id,
        dateRange: payload.dateRange || null,
      },
    })
    .then((res) => {
      if (res.status == 200) {
        let data = [];

        if (res.data.data !== undefined) {
          data = res.data.data;
        }

        dispatch({ type: payload.type, payload: data });
      }
    })
    .catch((err) => console.log(err));
};

export const getOrderChartListNew = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  return new Promise((resolve, reject) => {
    axios
      .get(nyonyaaAdminEndpoint + payload.url, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${session.data.jwt}`,
        },
        params: {
          user_id: session.data.id,
          dateRange: payload.dateRange || null,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          let data = [];

          if (res.data.data !== undefined) {
            data = res.data.data;
          }

          resolve(data);
        }
        return reject(true);
      })
      .catch((err) => reject(err));
  });
};
