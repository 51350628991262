import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { ChangeStatus } from '../../../config/redux/action/orderAction'
import { Bayar } from '../../../utils'

function ModalBerlangganan({ show, handleClose, data, update }) {
    const [Id, setId] = useState(null)
    const [Status, setStatus] = useState(0)
    const [NoOrder, setNoOrder] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if (data !== null) {
            setId(data.id)
            setNoOrder(data.no_order)
            setStatus(data.status)
        }
    }, [data])

    return (
        <Modal show={show} onHide={handleClose} centered size='sm'>
            <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '16px' }}>{NoOrder}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (data !== null) ?
                        <div className='d-flex justify-content-center flex-column'>
                            <label className='mb-1'>Status Bayar</label>
                            <select className='form-control'
                                value={Status}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                {
                                    (Bayar.length > 0) ?
                                        Bayar.map((val, key) => {
                                            if (val.value !== 4) {
                                                return (
                                                    <option key={key} value={val.value}>
                                                        {val.keterangan}
                                                    </option>
                                                )
                                            }
                                        })
                                        :
                                        null
                                    // <option value={0}>Error</option>
                                }
                            </select>
                            <button className='btn btn-sm btn-primary mx-5 mt-3'
                                onClick={() => {
                                    if (Id === null) {
                                        handleClose()
                                    } else {
                                        dispatch(ChangeStatus({
                                            url: '/order/change/bayar',
                                            id: Id,
                                            status: Status,
                                            title: 'bayar'
                                        }), dispatch)
                                            .then(() => {
                                                update()
                                                handleClose()
                                            })
                                    }

                                }}
                            >
                                Ubah
                            </button>
                        </div>
                        :
                        <></>
                }
            </Modal.Body>
        </Modal>
    )
}

export default ModalBerlangganan