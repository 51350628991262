import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { Routers, store } from '../config'
import './App.css';

function App() {
  return (
    <Provider store={store} >
      <BrowserRouter>
        <Routers />
      </BrowserRouter>
    </Provider>
  )
}

export default App