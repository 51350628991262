import React, { useCallback, useState } from "react";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const ImageZoom = ({isZoomed, image, handleImgLoad, zoomChange}) => {


  return (
    <ControlledZoom isZoomed={isZoomed} onZoomChange={zoomChange}>
      <img
        alt="that wanaka tree"
        onLoad={handleImgLoad}
        src={image}
        width="500"
      />
    </ControlledZoom>
  );
};

export default ImageZoom;
