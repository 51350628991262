const initialState = {
    VoucerList: []
}


const voucerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "VOUCER_LIST":
            return {
                ...state,
                VoucerList: action.payload
            }
        default:
            return state
    }

}

export default voucerReducer