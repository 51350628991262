import axios from "axios";
import { nyonyaaAdminEndpoint } from "../../../utils";

export const getMitraList = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  axios
    .get(nyonyaaAdminEndpoint + payload.url, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      params: {
        user_id: session.data.id,
      },
    })
    .then((res) => {
      if (res.status == 200) {
        dispatch({ type: payload.type, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const manageMitraList = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("id", payload.id);
    data.append("kode", "MITRA");
    data.append("image", payload.image);
    data.append("nama", payload.nama);
    data.append("title", payload.title);
    data.append("alamat", payload.alamat);
    data.append("notelp", payload.notelp);
    data.append("longlat", payload.longlat);
    data.append("aktif", payload.aktif);
    data.append("jam_oprasional", payload.jam_oprasional);

    axios({
      method: "post",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};
