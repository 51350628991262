const initialState = {
    MitraList: []
}


const mitraReducer = (state = initialState, action) => {
    switch (action.type) {
        case "MITRA_LIST":
            return {
                ...state,
                MitraList: action.payload
            }
        default:
            return state
    }

}

export default mitraReducer