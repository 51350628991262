import { faCircleNotch, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Logo from '../../assets/img/logo.png'

function LoadingPage() {
    return (
        <div className='d-flex flex-grow-1 flex-column min-vh-100 justify-content-center align-items-center'>
            <img src={Logo} alt='logo' className='align-self-center shadow' height={100} width={100}/>
            <FontAwesomeIcon icon={faCircleNotch} size='1x' className='mt-4 fa-spin text-primary' />
        </div>
    )
}

export default LoadingPage
