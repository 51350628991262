import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Gap, LoadingState, SideBar } from "../../components";
import { getDataAPI } from "../../config/redux/action/newRequestAction";
import UserMigrateModal from "./UserMigrateModal";
import UserMigrateRow from "./UserMigrateRow";

function UserMigrationPage() {
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (update) {
      dispatch(
        getDataAPI({
          url: "/user-migrate",
        })
      )
        .then((res) => {
          setData(res.data);
          setUpdate(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [update]);

  return (
    <div>
      <SideBar />
      <div className="content">
        <div
          className="bg-white rounded shadow px-3 py-2 mb-5"
          style={{ minHeight: 600 }}
        >
          <div className="d-flex flex-column">
            <div className="fs-5 fw-bold">Daftar pengajuan hapus akun</div>
            <Gap height={10} />
            {update ? (
              <LoadingState />
            ) : data.length > 0 ? (
              data.map((val, key) => {
                return (
                  <UserMigrateRow
                    key={key}
                    val={val}
                    onClick={(val) => {
                      setShowModal(true);
                      setModalData(val);
                    }}
                  />
                );
              })
            ) : (
              <div
                className="d-flex justify-content-center align-items-center bg-light rounded text-secondary"
                style={{ minHeight: 200 }}
              >
                Tidak ada pengajuan
              </div>
            )}
          </div>
          <UserMigrateModal
            show={showModal}
            data={modalData}
            handleClose={() => {
              setModalData(null);
              setShowModal(false);
              setUpdate(true);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default UserMigrationPage;
