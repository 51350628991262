import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);

function ProdukKategoriOrderChart() {
  const { ProdukChart } = useSelector((state) => state.chartReducer);
  const [PieData, setPieData] = useState({
    labels: [],
    datasets: [
      {
        label: "Order Periode",
        data: [],
        backgroundColor: "rgba(255, 0, 144, 0.7)",
      },
    ],
  });

  useEffect(() => {
    if (ProdukChart.length > 0) {
      let labels = [];
      let datas = [];

      ProdukChart.map((val) => {
        if (labels.length === 0) {
          try {
            labels.push(val.produk.kategori_produk.name);
          } catch (error) {
            labels.push(val.produk.kategori_detail.name);
          }
          datas.push(parseInt(val.count));
        } else {
          let status = false;
          for (let i = 0; i < labels.length; i++) {
            const element = labels[i];

            try {
              if (element === val.produk.kategori_produk.name) {
                datas[i] += parseInt(val.count);
                status = true;
                break;
              }
            } catch (error) {
              if (element === val.produk.kategori_detail.name) {
                datas[i] += parseInt(val.count);
                status = true;
                break;
              }
            }
          }

          if (!status) {
            try {
              labels.push(val.produk.kategori_produk.name);
            } catch (error) {
              labels.push(val.produk.kategori_detail.name);
            }
            datas.push(parseInt(val.count));
          }
        }
      });

      setPieData({
        labels,
        datasets: [
          {
            data: datas,
            backgroundColor: [
              "rgba(255, 99, 132, 0.8)",
              "rgba(54, 162, 235, 0.8)",
              "rgba(255, 206, 86, 0.8)",
              "rgba(75, 192, 192, 0.8)",
              "rgba(153, 102, 255, 0.8)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
            ],
            borderWidth: 1,
            hoverOffset: 2,
          },
        ],
      });
    }
  }, [ProdukChart]);

  return (
    <div className="row d-flex justify-content-center align-items-center">
      <div className="col-10">
        <Pie
          data={PieData}
          options={{
            plugins: {
              title: {
                display: true,
                text: "Produk Terjual Kategori Terbanyak",
              },
              legend: {
                position: "right",
                labels: {
                  boxWidth: 10,
                  font: {
                    size: 10,
                  },
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default ProdukKategoriOrderChart;
