import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  getDataAPI,
  patchDataAPI,
} from "../../config/redux/action/newRequestAction";
import AsyncSelect from "react-select/async";
import { ButtonLoading, Input } from "../../components";
import moment from "moment";

function UserMigrateModal({ show, handleClose, data }) {
  const dispatch = useDispatch();
  const [role, setRole] = useState(0);
  const [checked, setChecked] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    dispatch(
      getDataAPI({
        url: "/role",
      })
    ).then((res) => {
      let role_pick = 0;
      for (let i = 0; i < res.data.length; i++) {
        const role = res.data[i];
        if (role.name === "USER") setRole(role.id);
      }
    });
  }, []);

  useEffect(() => {
    if (!show) {
      setUser(null);
      setChecked(false);
      setBtnDisable(false);
    }
  }, [show]);

  const promiseOptions = (search) => {
    return new Promise((resolve, reject) => {
      dispatch(
        getDataAPI({
          url: "/user",
          data: { role_id: role, limit: 10, nama: search },
        })
      )
        .then((result) => {
          resolve(
            result.data.map((val) => {
              return {
                value: val.user.id,
                label: (
                  <>
                    <b>{`${val.user.nama}`}</b>
                    {` - (${val.user.email})`}
                  </>
                ),
              };
            })
          );
        })
        .catch(() => {
          reject([]);
        });
    });
  };

  const onUpdateData = () => {
    setBtnDisable(true);

    let formData = new FormData();
    formData.append("pk", data.id);
    formData.append("user_id", user.value);

    dispatch(
      patchDataAPI({
        url: "/user-migrate",
        data: formData,
      })
    )
      .then((res) => {
        setBtnDisable((prev) => !prev);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>Update data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data !== null ? (
          <div className="row">
            <div className="col-6 col-lg-3">
              <div className="d-flex justify-content-center fw-bold mb-3">
                Alasan dihapus
              </div>
              <div
                className="px-2 py-1 bg-body-secondary text-dark fst-italic overflow-auto"
                style={{ fontSize: 12, height: 200 }}
              >
                {data.description}
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="d-flex justify-content-center fw-bold mb-3">
                Perhatian
              </div>
              <div
                className="px-2 py-1 bg-warning text-dark fst-italic overflow-auto"
                style={{ fontSize: 12, height: 200 }}
              >
                Anda mensetujui bahwa pengguna tersebut benar benar menghapus
                akun dan anda setuju dipindahkan ke akun yang sesuai atau
                diperintahkan. Perlu diingat ketika anda mensetujui maka tidak
                bisa dikembalikan ke pengguna sebelumnya
              </div>
            </div>
            {data.approve ? (
              <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                <div className="d-flex justify-content-center fw-bold mb-3">
                  Telah disetujui
                </div>
                <div className="d-flex justify-content-center flex-column text-center">
                <div>Di setujui oleh {data.user_approve.nama}</div>
                <div style={{fontSize:10, fontStyle:'italic'}}>{data.user_approve.email}</div>
                <div className="mt-2">Tanggal :</div>
                <div style={{fontSize:10, fontStyle:'italic'}}>{moment(new Date(data.updatedAt)).format("D/M/yyyy hh:mm:ss")}</div>
              </div>
              </div>
            ) : (
              <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                <div className="d-flex justify-content-center fw-bold mb-3">
                  Pilih Pengguna
                </div>
                <div>
                  {role > 0 ? (
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      loadingMessage={() => "Mencari"}
                      noOptionsMessage={() => "Pengguna tidak ditemukan"}
                      placeholder={"Masukan nama user atau email"}
                      loadOptions={promiseOptions}
                      onChange={(e) => setUser(e)}
                      styles={{
                        container: (base, state) => ({
                          ...base,
                          fontSize: 12,
                        }),
                        option: (base, state) => ({
                          ...base,
                          ":hover": {
                            backgroundColor: "#33d49d50",
                          },
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#33d49d50",
                          primary: "#33d49d",
                        },
                      })}
                    />
                  ) : null}
                </div>

                <div className="mt-3 d-flex justify-content-center">
                  <div className="form-check" style={{ fontSize: 12 }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                    <label
                      className="form-check-label"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setChecked(!checked);
                      }}
                    >
                      Setuju untuk memindahkan semua data order dan alamat
                    </label>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-5">
                  <ButtonLoading
                    loading={btnDisable}
                    disabled={user === null || !checked ? true : false}
                    onClick={() => onUpdateData()}
                  >
                    Pindah Data
                  </ButtonLoading>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  );
}

export default UserMigrateModal;
