import axios from "axios"
import { nyonyaaAdminEndpoint } from "../../../utils"

export const getJamList = (payload) => async (dispatch) => {
    const session = JSON.parse(localStorage.getItem("session_user"))
    axios.get(nyonyaaAdminEndpoint + payload.url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${session.data.jwt}`,
        },
        params: {
            user_id: session.data.id,
        }
    })
        .then(res => {
            if (res.status == 200) {
                dispatch({ type: payload.type, payload: res.data.data })
            }
        })
        .catch(err => console.log(err))
}

export const manageJamList = (payload) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        const session = JSON.parse(localStorage.getItem("session_user"))
        let data = new FormData()
        data.append('nama', payload.nama)
        data.append('title', payload.title)
        data.append('id', payload.id)

        axios({
            method: 'post',
            url: nyonyaaAdminEndpoint + payload.url,
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${session.data.jwt}`,
            },
            data
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.log(err);
                return reject(err)
            })
    })

}

export const getPaymentAcceptList = (payload) => async (dispatch) => {
    const session = JSON.parse(localStorage.getItem("session_user"))
    axios.get(nyonyaaAdminEndpoint + payload.url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${session.data.jwt}`,
        },
        params: {
            user_id: session.data.id,
        }
    })
        .then(res => {
            if (res.status == 200) {
                dispatch({ type: payload.type, payload: res.data.data })
            }
        })
        .catch(err => console.log(err))
}

export const managePaymentAcceptList = (payload) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        const session = JSON.parse(localStorage.getItem("session_user"))
        let data = new FormData()
        data.append('title', payload.title)
        data.append('id', payload.id)
        data.append('image', payload.gambar)
        data.append('kode', payload.kode)
        data.append('nama', payload.nama)
        data.append('deskripsi', payload.deskripsi)
        data.append('min_order', payload.min_order)
        data.append('fee', payload.fee)
        data.append('tipe', payload.tipe)

        axios({
            method: 'post',
            url: nyonyaaAdminEndpoint + payload.url,
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${session.data.jwt}`,
            },
            data
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.log(err);
                return reject(err)
            })
    })

}