import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  DriverPage,
  HomePage,
  LoadingPage,
  LoginPage,
  MasterPage,
  MitraPage,
  OrderDetailBerlanggananPage,
  OrderDetailPage,
  OrderPage,
  PromoPage,
  RatingDetailPage,
  RatingPage,
  SettingPage,
  UserPage,
  VoucerPage,
  WorkshopPage,
  CreateOrderPage,
  ReportPage,
  UserMigrationPage,
} from "../../pages";
import { retrieveUserSession } from "../redux/action/sessionLogin";
import moment from 'moment';
import 'moment/locale/id';

function Routers() {
  const { login } = useSelector((state) => state.sessionReducer);
  const { PageLoading } = useSelector((state) => state.globalReducer);
  const dispatch = useDispatch();

  const session = JSON.parse(localStorage.getItem("session_user")) || [];

  useEffect(() => {
    moment.locale('id')
    dispatch(retrieveUserSession(), dispatch);
  }, [dispatch]);

  if (PageLoading) {
    return <LoadingPage />;
  }

  return login ? (
    <Routes>
      <Route path="/dashboard" element={<HomePage />} />
      <Route path="/master" element={<MasterPage />} />
      <Route path="/order" element={<OrderPage />} />
      <Route path="/order/create" element={<CreateOrderPage />} />
      <Route path="/order/detail" element={<OrderDetailPage />} />
      <Route
        path="/order/berlangganan/detail"
        element={<OrderDetailBerlanggananPage />}
      />
      <Route path="/driver" element={<DriverPage />} />
      <Route path="/workshop" element={<WorkshopPage />} />
      <Route path="/voucer" element={<VoucerPage />} />
      <Route path="/user" element={<UserPage />} />
      <Route path="/promo" element={<PromoPage />} />
      <Route path="/setting" element={<SettingPage />} />
      <Route path="/mitra" element={<MitraPage />} />
      <Route path="/rating" element={<RatingPage />} />
      <Route path="/rating/detail" element={<RatingDetailPage />} />
      <Route path="/report" element={<ReportPage />} />
      <Route path="/user/migration" element={<UserMigrationPage />} />
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default Routers;
