import React, { useEffect, useState } from 'react'
import Export from "react-data-table-component"
import ReactDatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderChartList } from '../../../config/redux/action/chartAction'
import DataTable from 'react-data-table-component';
import { formatRupiah, jenisOrder } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import ALertNotification from '../AlertNotification';
import { CSVDownload, CSVLink } from 'react-csv'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'

function OrderHutang() {
    const { OrderHutangChartList } = useSelector(state => state.chartReducer)
    const [dateRange, setDateRange] = useState([
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate()
    ]);
    const [startDate, endDate] = dateRange;
    const [Data, setData] = useState([])
    const [DataCSV, setDataCSV] = useState([])
    const [show, setShow] = useState(false);
    const [Pesan, setPesan] = useState('');
    const [TotalOrder, setTotalOrder] = useState(0)
    const handleClose = () => setShow(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if (dateRange[0] !== null && dateRange[1] !== null) {
            dispatch(getOrderChartList({
                url: '/chart/order/hutang',
                dateRange,
                type: 'ORDER_HUTANG_CHART_LIST'
            }), dispatch)
        }
    }, [dateRange])

    useEffect(() => {
        if (OrderHutangChartList.length > 0) {
            let total = 0
            let datas = []
            let datascsv = []
            let nilai = 0
            OrderHutangChartList.map(val => {
                nilai = 0
                val.order_detail.map(result => {
                    nilai += result.nilai
                })

                total += nilai + val.diskon + val.biaya_aplikasi
                datas.push({
                    no_order: val.no_order,
                    tanggal: moment(new Date(val.tanggal_pesan)).format('LLL'),
                    nama: val.user.nama,
                    jenis_order: jenisOrder(val.jenis_order),
                    bayar: val.payment_accept?.nama || "Default Payment",
                    total: formatRupiah(String(nilai + val.diskon + val.biaya_aplikasi), 'Rp. '),
                    val
                })
                datascsv.push({
                    no_order: val.no_order,
                    tanggal: new Date(val.tanggal_pesan).toLocaleString(),
                    nama: val.user.nama,
                    jenis_order: val.jenis_order,
                    bayar: val.payment_accept?.nama || "Default Payment",
                    total: (nilai + val.diskon + val.biaya_aplikasi),
                })
            })

            setData(datas)
            setDataCSV(datascsv)
            setTotalOrder(total)
        } else {
            setData([])
            setTotalOrder(0)
        }
    }, [OrderHutangChartList])

    const column = [
        {
            name: 'No Order',
            selector: row => row.no_order,
            sortable: true,
        },
        {
            name: 'Tanggal',
            selector: row => row.tanggal,
            sortable: true,
        },
        {
            name: 'Nama',
            selector: row => row.nama,
        },
        {
            name: 'Jenis Order',
            selector: row => row.jenis_order,
        },
        {
            name: 'Pembayaran',
            selector: row => row.bayar,
            center: true
        },
        {
            name: 'Total',
            selector: row => row.total,
            right: true
        }
    ]

    const paginationComponentOptions = {
        rowsPerPageText: 'Tampilkan ',
        rangeSeparatorText: ' dari ',
    };

    return (
        <div className='bg-white px-2 py-1 shadow rounded h-100'>
            <div className='w-100 text-center text-secondary mb-3' style={{ fontSize: '14px', fontWeight: 'bold' }}>
                AR / Piutang Periode
            </div>

            <div className='mb-2 d-flex justify-content-between mx-2'>
                <div className=' d-flex align-items-center'>
                    <div className='align-self-center me-2'>
                        Sortir :
                    </div>
                    <ReactDatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        onChange={(update) => {
                            if (update[1] !== null) {
                                update[1] = moment(update[1]).set({ h: 23, m: 59 }).toDate()
                            }
                            setDateRange(update);
                        }}
                        withPortal
                        showMonthDropdown
                        showYearDropdown
                        className='form-control form-control-sm'
                    />
                </div>
                <div className=' d-flex'>
                    <div className='align-self-center me-3'>
                        Total AR : <b className='text-danger' >{formatRupiah(String(TotalOrder), 'Rp. ')}</b>
                    </div>

                    <CSVLink
                        data={DataCSV}
                        filename={`Data AR - ${moment().format('LLLL')}`}
                        className="btn btn-success text-white"
                        target="_blank"
                        separator=';'
                    >
                        Download &nbsp;<FontAwesomeIcon icon={faFileCsv} size='lg' />
                    </CSVLink>

                </div>
            </div>

            <DataTable
                columns={column}
                data={Data}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 50]}
                customStyles={{
                    table: {
                        style: {
                            border: 'solid 0.1px',
                            borderColor: '#b5aab3',

                        }
                    },

                    headRow: {
                        style: {
                            minHeight: '40px',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            color: 'rgba(51, 51, 51, 0.9)',
                        }
                    },
                    rows: {
                        style: {
                            fontSize: '10px',
                            minHeight: '35px',
                            ":hover": {
                                backgroundColor: "#33d49d50",
                            },
                            cursor: 'pointer'
                        },
                    }
                }}
                onRowClicked={data => {
                    if (data.val.jenis_order === 'berlangganan') {
                        if (data.val.status === 4 || data.val.status === 6) {
                            navigate('/order/berlangganan/detail?no_order=' + data.val.no_order)
                        } else {
                            setShow(true)
                            return setPesan('No Order ini belum melakukan pembayaran')
                        }
                    } else {
                        navigate('/order/detail?no_order=' + data.val.no_order)
                    }
                }}
            />


            <ALertNotification
                show={show}
                handleClose={handleClose}
                pesan={Pesan}
            />
        </div>
    )
}

export default OrderHutang