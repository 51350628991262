const initialState = {
    BtnLoginDisable: false,
    PageLoading: true,
    PositionMap: [],
    AlamatGlobal: [],
    ManageAlamat: {
        Alamat: '',
        KodePos: '',
        LongLat: ''
    },
    AktivitasUserList:[]
}

const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case "BTN_LOGIN_DISABLE":
            return {
                ...state,
                BtnLoginDisable: action.payload
            }
        case "PAGE_LOADING":
            return {
                ...state,
                PageLoading: action.payload
            }
        case "POSITION_MAP_CHANGE":
            return {
                ...state,
                PositionMap: action.payload
            }
        case "CHANGE_ALAMAT_GLOBAL":
            return {
                ...state,
                AlamatGlobal: action.payload
            }
        case "MANAGE_ALAMAT_CHANGE":
            return {
                ...state,
                ManageAlamat: action.payload
            }
        case "AKTIVITAS_USER_LIST":
            return{
                ...state,
                AktivitasUserList:action.payload
            }
        default:
            return state
    }

}

export default globalReducer