const initialState = {
    RatingDriver: [],
    RatingWorkshop: [],
    RatingDriverKonsumen: [],
    RatingWorkshopKonsumen: [],
}

const ratingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "RATING_DRIVER_LIST":
            return {
                ...state,
                RatingDriver: action.payload
            }
        case "RATING_WORKSHOP_LIST":
            return {
                ...state,
                RatingWorkshop: action.payload
            }
        case "RATING_DRIVER_KONSUMEN_LIST":
            return {
                ...state,
                RatingDriverKonsumen: action.payload
            }
        case "RATING_WORKSHOP_KONSUMEN_LIST":
            return {
                ...state,
                RatingWorkshopKonsumen: action.payload
            }
        default:
            return state
    }

}

export default ratingReducer