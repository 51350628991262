import { faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAlamat,
  getAlamatList,
} from "../../../config/redux/action/alamatAction";

function ModalOrderAlamat({ show, handleClose, update }) {
  const { AlamatList } = useSelector((state) => state.alamatReducer);
  const [Search, setSearch] = useState("");
  const [Update, setUpdate] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      dispatch(
        getAlamatList({
          url: "/alamat",
          type: "ALAMAT_LIST",
          Search,
        })
      );
    }
  }, [show, Update]);

  const DeleteAlamat = (id) => {
    dispatch(
      deleteAlamat({
        url: "/alamat",
        id,
      }),
      dispatch
    )
      .then(() => {
        setUpdate(Update + 1);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Pilih Alamat</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        <div className="d-flex mb-2">
          <input
            type={"text"}
            className="form-control form-control-sm me-2 bg-light"
            placeholder="Ketikan Nama, no Telp atau Alamat terkait"
            value={Search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <button
            className="btn btn-primary btn-sm"
            onClick={() => setUpdate(Update + 1)}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>

        {AlamatList.length > 0 ? (
          AlamatList.map((val) => {
            return (
              <div key={val.id} className="w-100 rounded mb-1 d-flex border">
                <div
                  className="d-flex flex-column p-2"
                  style={{
                    flex: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let alamat = `${val.nama_penerima}\n${val.telp_penerima}\n${val.jalan}\n${val.kode_pos}`;
                    handleClose();
                    update(alamat, val.latlong);
                  }}
                >
                  <div className="d-flex flex-row">
                    {val.label !== null && (
                      <div className="bg-success text-white rounded me-1 px-2 fs-6">
                        {val.label}
                      </div>
                    )}
                    <div className="fw-bold">{'- '+val.nama_penerima}</div>
                  </div>
                  <div className="">{val.telp_penerima}</div>
                  <div className="">{val.jalan}</div>
                  <div className="">{val.kode_pos}</div>
                  <div className="fst-italic text-secondary" style={{fontSize:12}} >{val.description}</div>
                </div>
                <div
                  className="text-center mt-3"
                  style={{
                    flex: 1,
                  }}
                >
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => DeleteAlamat(val.id)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <div className="w-100 text-center">Data tidak deitemukan</div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModalOrderAlamat;
