import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderChartList,
  getOrderChartListNew,
} from "../../../config/redux/action/chartAction";
import { formatRupiah, statusBayar } from "../../../utils";

ChartJS.register(ArcElement, Tooltip, Legend);

function OrderOngkirSum() {
  const [Total, setTotal] = useState(0);
  const [PieData, setPieData] = useState({
    labels: [],
    datasets: [
      {
        label: "Jumlah Order Ongkir Bulan ini",
        data: [],
        backgroundColor: "rgba(255, 0, 144, 0.7)",
      },
    ],
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getOrderChartListNew({
        url: `/chart/order/ongkir`,
      })
    )
      .then((result) => {
        let labels = [];
        let datas = [];
        let total = 0;

        result.map((val) => {
          labels.push(statusBayar(val.status_order));
          datas.push(val.total);
          total += val.total;
        });

        setTotal(total);

        setPieData({
          labels: labels,
          datasets: [
            {
              data: datas,
              backgroundColor: [
                "rgba(255, 99, 132, 0.8)",
                "rgba(54, 162, 235, 0.8)",
                "rgba(255, 206, 86, 0.8)",
                "rgba(75, 192, 192, 0.8)",
                "rgba(153, 102, 255, 0.8)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
              ],
              borderWidth: 1,
              hoverOffset: 2,
            },
          ],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="bg-white rounded shadow p-2" >
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-10">
          <Pie
            data={PieData}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: "Jumlah Order Ongkir Bulan ini",
                },
                legend:{
                  position:'right',
                  labels:{
                    boxWidth:10,
                    font:{
                      size:10
                  }
                }
              }
              },
            }}
          />
        </div>
        <div className="col-4">
          <div className="align-self-center mt-3 my-2 fw-bold" style={{fontSize:12}}>
            Total Ongkir Bulan ini {formatRupiah(String(Total), "Rp. ")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderOngkirSum;
