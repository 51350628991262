import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getJamList } from '../../../config/redux/action/settingReducer'
import ModalJam from '../ModalJam'

function SettingJam() {
    const { JamList } = useSelector(state => state.settingReducer)
    const [Update, setUpdate] = useState(1)
    const [ShowJam, setShowJam] = useState(false)
    const [DataJam, setDataJam] = useState(null)
    const handleCloseJam = () => setShowJam(false)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getJamList({
            url: '/setting/jam',
            type: 'JAM_LIST'
        }))
    }, [Update])

    return (
        <div className='bg-white rounded px-2 py-1 shadow d-flex justify-content-center flex-column me-2 mb-3'>
            <div className='fw-bold mb-3 text-center'>List Jam</div>
            <button className='btn btn-sm btn-primary fw-bold mb-2'
                onClick={() => {
                    setDataJam(null)
                    setShowJam(true)
                }}
            >
                Tambah Jam
            </button>
            {
                (JamList.length > 0) ?
                    JamList.map(val => {
                        return (
                            <div key={val.id}
                                className='border rounded hover text-center mb-1'
                                onClick={() => {
                                    setDataJam(val)
                                    setShowJam(true)
                                }}
                            >
                                {val.nama}
                            </div>
                        )
                    })
                    :
                    <>Tidak Ada</>
            }

            <ModalJam
                show={ShowJam}
                handleClose={handleCloseJam}
                data={DataJam}
                update={() => setUpdate(Update + 1)}
            />

        </div>
    )
}

export default SettingJam