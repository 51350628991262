import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ModalDriver, SideBar } from '../../components'
import { getDriverChange } from '../../config/redux/action/driverAction'
import { nyonyaaAdminEndpoint } from '../../utils'

function DriverPage() {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const { ListDriver } = useSelector(state => state.driverReducer)
  const [Update, setUpdate] = useState(1)
  const [Data, setData] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDriverChange({ url: '/driver', type: 'LIST_DRIVER_CHANGE' }), dispatch)
  }, [Update])


  return (
    <div>
      <SideBar />
      <div className='content'>
        <div className='mx-2 p-2 bg-white rounded shadow'>
          <div className='d-flex flex-column mb-3'>
            <div className='align-self-end'>
              <button className='btn btn-primary' onClick={() => {
                setData(null)
                setShow(true)
              }}>Tambah Driver</button>
            </div>
          </div>
          {
            (ListDriver.length > 0) ?
              ListDriver.map(val => {
                return (
                  <div key={val.id}
                    className='d-flex justify-content-around border rounded py-1 my-1 hover'
                    onClick={() => {
                      setData(val)
                      setShow(true)
                    }}
                  >
                    <div>
                      <img src={`${nyonyaaAdminEndpoint}${val.gambar}`}
                        className=''
                        width={'200px'}
                        height={'200px'}
                        alt={val.gambar}
                      />
                    </div>
                    <div className='d-flex flex-column'>
                      <b>Nama</b>
                      {val.nama}
                    </div>
                    <div className='d-flex flex-column'>
                      <b>Alamat</b>
                      {val.alamat}
                    </div>
                    <div className='d-flex flex-column'>
                      <b>Plat Nomor</b>
                      {val.plat_nomor}
                    </div>
                    <div className='d-flex flex-column'>
                      <b>status</b>
                      {(val.status === 1) ? 'aktif' : 'tidak aktif'}
                    </div>
                  </div>
                )
              })
              :
              <div
                className='w-100 text-center my-5'
              >
                Tidak ada data
              </div>
          }
        </div>
      </div>
      <ModalDriver
        show={show}
        handleClose={handleClose}
        data={Data}
        update={() => setUpdate(Update + 1)}
      />
    </div>
  )
}

export default DriverPage