import React, { useEffect, useState } from "react";
import { ReportData, ReportFilter, SideBar } from "../../components";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getReportListOrder } from "../../config/redux/action/reportAction";
import { getDataAPI } from "../../config/redux/action/newRequestAction";
import ReportReceive from "./ReportReceive";

function ReportPage() {
  const { ReportListOrder } = useSelector((state) => state.reportReducer);
  const dispatch = useDispatch();
  const [dataReceive, setDataReceive] = useState([]);
  const [Status, setStatus] = useState(0);
  const [Search, setSearch] = useState("");
  const [Update, setUpdate] = useState(0);
  const [dateRange, setDateRange] = useState([
    moment().startOf("month").toDate(),
    moment().endOf("month").toDate(),
  ]);
  const tabsHeader = ["Penjualan", "Pembayaran"];
  const [tabIndexs, setTabIndexs] = useState(0);

  useEffect(() => {
    if (dateRange[0] !== null && dateRange[1] !== null) {
      console.log(dateRange);
      dispatch(
        getReportListOrder({
          url: "/report",
          type: "REPORT_LIST_ORDER",
          dateRange,
          Status,
          Search,
        }),
        dispatch
      );

      dispatch(
        getDataAPI({
          url: "/report/receive",
          data: {
            dateRange,
            search: Search,
          },
        })
      ).then((res) => {
        console.log(res);
        setDataReceive(res.data);
      });
    }
  }, [dateRange, Status, Update]);

  // console.log(ReportListOrder);

  return (
    <div>
      <SideBar />
      <div className="content">
        <div className="px-2 py-1 border shadow rounded d-flex flex-column bg-white ">
          <div className="mb-2">
            <ReportFilter
              dateRange={dateRange}
              setDateRange={(update) => setDateRange(update)}
              status={Status}
              setStatus={(status) => setStatus(status)}
              search={Search}
              setSearch={(search) => setSearch(search)}
              setUpdate={() => setUpdate(Update + 1)}
            />
          </div>

          <div className="p-2 d-flex flex-row column-gap-1">
            {tabsHeader.map((val, key) => {
              return (
                <button
                  key={key}
                  className={`btn btn-sm fw-bold flex-grow-1 ${
                    key === tabIndexs ? "btn-primary " : "btn-light"
                  }`}
                  onClick={() => setTabIndexs(key)}
                >
                  {val}
                </button>
              );
            })}
          </div>

          <div>
            {tabIndexs === 0 ? (
              <ReportData data={ReportListOrder} />
            ) : (
              <ReportReceive data={dataReceive} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportPage;
