import React, { useEffect, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalProduk from "./ModalProduk";
import { getProdukChange } from "../../../config/redux/action/produkAction";
import { CardMaster } from "../../atoms";

function ModalMaster({ kategori_id, kategori_detail_id }) {
  const { BerlanggananProduk } = useSelector((state) => state.produkReducer);
  const [show, setShow] = useState(false);
  const [Data, setData] = useState(null);
  const [Update, setUpdate] = useState(1);
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getProdukChange({
        url: "/produk",
        kategori_id: kategori_id || 1,
        kategori_detail_id: kategori_detail_id || "null",
        type: "BERLANGGANAN_CHANGE",
      }),
      dispatch
    );
  }, [Update, kategori_id, kategori_detail_id]);

  const showModal = (val) => {
    setData(val);
    setShow(true);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-end py-2">
        <button className="btn btn-primary" onClick={() => showModal(null)}>
          {/* <FontAwesomeIcon icon={faPlus} className="pe-1" /> */}
          Tambah Produk
        </button>
      </div>
      {BerlanggananProduk.length > 0 ? (
        BerlanggananProduk.map((val) => {
          return (
            <CardMaster
              key={val.id}
              val={val}
              click={(val) => showModal(val)}
            />
          );
        })
      ) : (
        <></>
      )}
      <ModalProduk
        show={show}
        handleClose={() => handleClose()}
        data={Data}
        update={() => setUpdate(Update + 1)}
        kategori_id={kategori_id}
        kategori_detail_id={kategori_detail_id}
      />
    </div>
  );
}

export default ModalMaster;
