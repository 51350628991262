import { faFileCsv, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { LoadingState, ModalUser, RoleList, SideBar } from "../../components";
import moment from "moment";
import appleLogo from "../../assets/img/apple.png";
import googleLogo from "../../assets/img/google.png";
import { CSVLink } from "react-csv";
import { getDataAPI } from "../../config/redux/action/newRequestAction";
import ReactDatePicker from "react-datepicker";

function UserPage() {
  const { ListUser } = useSelector((state) => state.userReducer);
  const { ListKategoriUser } = useSelector((state) => state.userReducer);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Role, setRole] = useState(0);
  const [Data, setData] = useState(null);
  const [Update, setUpdate] = useState(false);
  const [Limit, setLimit] = useState(100);
  const [datalistuser, setDatalistuser] = useState([]);
  const [dataUserCSV, setDataUserCSV] = useState([]);
  const [Nama, setNama] = useState("");
  const [dateRange, setDateRange] = useState([
    moment().startOf("month").toDate(),
    moment().endOf("month").toDate(),
  ]);
  const [startDate, endDate] = dateRange;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDataAPI({
        url: "/user/kategori",
        type: "LIST_KATEGORI_USER_CHANGE",
      })
    ).then(() => {
      setUpdate(true);
    });
  }, []);

  useEffect(() => {
    if (Role > 0 && Update) {
      let data = { role_id: Role, limit: Limit, nama: Nama };
      if (Role === "1") {
        data = { role_id: Role, limit: Limit, nama: Nama, date: dateRange };
      }
      dispatch(
        getDataAPI({
          url: "/user",
          type: "LIST_USER_CHANGE",
          data,
        })
      ).then(() => {
        setUpdate(false);
      });
    }
  }, [Role, Update, Limit]);

  useEffect(() => {
    const dateNow = moment(new Date());

    if (ListUser.length > 0) {
      setDatalistuser(
        ListUser.map((val, key) => {
          let image = null;

          if (val.user.gambar === null || val.user.gambar === "") {
            image = (
              <FontAwesomeIcon
                icon={faUserCircle}
                className=" opacity-50"
                style={{ height: 50, width: 50 }}
              />
            );
          } else if (val.user.gambar.includes("https://")) {
            image = (
              <img
                src={val.user.gambar}
                className="rounded-circle"
                style={{ height: 50, width: 50 }}
              />
            );
          } else {
            image = (
              <img
                src={`${process.env.REACT_APP_STORAGE}${val.user.gambar}`}
                className="rounded-circle"
                style={{ height: 50, width: 50 }}
              />
            );
          }

          let link = "-";
          if (val.user.link !== null) {
            let linkTemp = JSON.parse(val.user.link);
            link = (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {linkTemp.google && (
                  <img src={googleLogo} height={20} width={20} />
                )}
                <div style={{ width: linkTemp.google ? 5 : 0 }} />
                {linkTemp.apple && (
                  <img src={appleLogo} height={20} width={20} />
                )}
              </div>
            );
          }

          return {
            image: image,
            name: val.user.nama,
            email: val.user.email,
            notelp: val.user.notelp,
            status: val.user.validasi ? "Aktif" : "Tidak Aktif",
            link,
            createdAt: moment(val.user.createdAt).format("DD-MMM-YYYY"),
            gender: val.user.gender,
            // birthday:val.user.birthday,
            birthday:
              val.user.birthday === null
                ? "-"
                : Math.trunc(
                    moment
                      // .duration(moment(dateNow).diff(new Date(val.user.birthday)))
                      .duration(
                        moment(dateNow).diff(new Date(val.user.birthday))
                      )
                      .asYears()
                  ),
            information: val.user.information,
            val: val.user,
          };
        })
      );

      console.log(ListUser);
      setDataUserCSV(
        ListUser.map((val, key) => {
          return {
            nama: val.user.nama,
            email: val.user.email,
            notelp: val.user.notelp === null? '-': val.user.notelp.toString(),
            aktif: val.user.validasi ? "Aktif" : "Tidak Aktif",
            daftar: moment(val.user.createdAt).format("DD-MMM-YYYY"),
            kelamin: val.user.gender,
            umur:
              val.user.birthday === null
                ? "-"
                : Math.trunc(
                    moment
                      // .duration(moment(dateNow).diff(new Date(val.user.birthday)))
                      .duration(
                        moment(dateNow).diff(new Date(val.user.birthday))
                      )
                      .asYears()
                  ),
            informasi: val.user.information,
            alamat:
              val.user.address.length > 0
                ? val.user.address[0].jalan + " " + val.user.address[0].kode_pos
                : "-",
          };
        })
      );
    } else {
      setDatalistuser([]);
      setDataUserCSV([]);
    }
  }, [ListUser]);

  const column = [
    {
      name: "Foto",
      selector: (row) => row.image,
      center: true,
    },
    {
      name: "Nama",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) => row.gender || "-",
    },
    {
      name: "Umur",
      selector: (row) => row.birthday || "-",
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "No Telp",
      selector: (row) => row.notelp,
    },
    {
      name: "Informasi",
      selector: (row) => row.information || "-",
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Link",
      selector: (row) => row.link,
    },
    {
      name: "Daftar",
      selector: (row) => row.createdAt,
      center: true,
      // sortable: true,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Tampilkan ",
    rangeSeparatorText: " dari ",
  };

  return (
    <div>
      <SideBar />
      <div className="content">
        <div className="mx-2 bg-white shadow p-2 d-flex flex-column">
          <div className="w-100 d-flex flex-column">
            <div className="col-12 mb-2 d-flex flex-column border justify-content-center rounded px-2 py-1 me-2">
              <div className="fw-bold fs-6 align-self-center">Filter</div>
              <div
                className="d-flex justify-content-center pb-2 pt-2"
                style={{ fontSize: "12px" }}
              >
                <div className="d-flex col-3 col-md-3 col-lg-2 col-xl-2 flex-column mx-1">
                  <div className="fw-bold">Role</div>
                  <RoleList
                    onChange={(e) => {
                      setRole(e.target.value);
                      setUpdate(true);
                    }}
                    onUtama={(val) => setRole(val)}
                  />
                </div>
                <div className="d-flex col-3 col-md-2 col-lg-2 col-xl-1 flex-column mx-1">
                  <div className="fw-bold">Limit</div>
                  <select
                    className="form-select form-select-sm"
                    value={Limit}
                    onChange={(e) => {
                      setLimit(e.target.value);
                      setUpdate(true);
                    }}
                  >
                    <option>10</option>
                    <option>50</option>
                    <option>100</option>
                    <option>200</option>
                    <option>500</option>
                    <option>1000</option>
                  </select>
                </div>
                <div className="d-flex  col-lg-3 flex-column mx-1">
                  <div className="fw-bold">Cari Nama</div>
                  <div className="d-flex">
                    <input
                      type={"text"}
                      className="form-control form-control-sm me-1"
                      placeholder="Masukan nama User"
                      value={Nama}
                      onChange={(e) => setNama(e.target.value)}
                    />
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => setUpdate(true)}
                    >
                      Cek
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-2">
              {Role === "1" && (
                <div className="col-12 col-sm-7 col-md-6 col-lg-4 mb-2 mb-sm-0">
                  <ReactDatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      if (update[1] !== null) {
                        update[1] = moment(update[1])
                          .set({ h: 23, m: 59 })
                          .toDate();
                      }
                      setDateRange(update);
                      if (update[0] !== null && update[1] !== null) {
                        setUpdate(true);
                      }
                    }}
                    withPortal
                    dateFormat="dd-MMMM-yyyy"
                    className="form-control form-control-sm"
                  />
                </div>
              )}
              <div
                className={`${
                  Role === "1"
                    ? "offset-0 offset-sm-0 offset-md-2 offset-lg-5"
                    : "offset-0 offset-sm-7 offset-md-8 offset-lg-9"
                } col-12 col-sm-5 col-md-4 col-lg-3 text-end`}
              >
                <CSVLink
                  data={dataUserCSV}
                  filename={`Data User - ${moment().format("LLLL")}.csv`}
                  className="btn btn-sm btn-success me-2"
                  target="_blank"
                  separator=";"
                >
                  <FontAwesomeIcon
                    icon={faFileCsv}
                    size="lg"
                    className="text-white"
                  />
                </CSVLink>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setData(null);
                    setShow(true);
                  }}
                >
                  Tambah User
                </button>
              </div>
            </div>
          </div>
          {Update ? (
            <LoadingState />
          ) : (
            <div className="table-responsive">
              <DataTable
                columns={column}
                data={datalistuser}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[
                  5, 10, 30, 50, 100, 200, 500, 1000,
                ]}
                customStyles={{
                  table: {
                    style: {
                      border: "solid 0.1px",
                      borderColor: "#b5aab3",
                    },
                  },

                  headRow: {
                    style: {
                      minHeight: "40px",
                      fontSize: "13px",
                      fontWeight: "bold",
                      color: "rgba(51, 51, 51, 0.9)",
                    },
                  },
                  rows: {
                    style: {
                      fontSize: "12px",
                      minHeight: "35px",
                      ":hover": {
                        backgroundColor: "#33d49d50",
                      },
                      cursor: "pointer",
                      paddingTop: 10,
                      paddingBottom: 10,
                    },
                  },
                }}
                onRowClicked={(data) => {
                  let val = data.val;
                  setData(val);
                  setShow(true);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <ModalUser
        show={show}
        handleClose={handleClose}
        data={Data}
        update={() => setUpdate(true)}
        role={Role}
        kategoriUserData={ListKategoriUser}
      />
    </div>
  );
}

export default UserPage;
