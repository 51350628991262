import { Modal } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlamatMaps, Input } from "../../atoms";
import { saveAlamat } from "../../../config/redux/action/alamatAction";

function ModalAlamatTambah({ show, handleClose, update }) {
  const { ManageAlamat } = useSelector((state) => state.globalReducer);
  const [Nama, setNama] = useState("");
  const [NoTelp, setNoTelp] = useState("");
  const [Jalan, setJalan] = useState("");
  const [KodePos, setKodePos] = useState("");
  const [Aktif, setAktif] = useState(1);
  const [LongLat, setLongLat] = useState("");
  const [label, setLabel] = useState("");
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const searchRef = useRef();
  const script = document.createElement("script");

  useEffect(() => {
    const Url =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyAogpEluAjRfvqXH5jBWRKof805hSYPpRU&libraries=places";
    script.src = Url;
    script.async = true;

    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (ManageAlamat.Alamat !== undefined) {
      setJalan(ManageAlamat.Alamat);
      setLongLat(`${ManageAlamat.LongLat.lat},${ManageAlamat.LongLat.lng}`);
    }
  }, [ManageAlamat]);

  const onSave = () => {
    dispatch(
      saveAlamat({
        url: "/alamat",
        Id: 0,
        Nama,
        NoTelp,
        Jalan,
        KodePos,
        Aktif,
        LongLat,
        label,
        description
      }),
      dispatch
    )
      .then(() => {
        update();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{"Tambah Alamat Penerima"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          <div className="w-100 mb-2">
            <div style={{ height: "200px", marginInline: "10px" }}>
              <AlamatMaps searchRef={searchRef} />
            </div>
          </div>
          <div className="row">
            <Input
              judul={"Alamat"}
              placeholder={"Alamat Detail"}
              type={"textarea"}
              value={Jalan}
              onChange={(e) => setJalan(e.target.value)}
            />
            <div className="col-6">
              <Input
                judul={"Kode Pos"}
                placeholder={"Kode Pos"}
                type={"text"}
                value={KodePos}
                onChange={(e) => setKodePos(e.target.value)}
              />
            </div>
            <div className="col-6">
              <Input
                judul={"Nama Penerima"}
                placeholder={"Nama Penerima"}
                type={"text"}
                value={Nama}
                onChange={(e) => setNama(e.target.value)}
              />
            </div>
            <div className="col-6">
              <Input
                judul={"No Telp"}
                placeholder={"No Telp"}
                type={"number"}
                value={NoTelp}
                onChange={(e) => setNoTelp(e.target.value)}
              />
            </div>
            <div className="col-6">
              <Input
                judul={"Label"}
                placeholder={"Rumah, Kantor, Apartement dll"}
                type={"text"}
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />
            </div>
            <Input
              judul={"Keterangan"}
              placeholder={"Detail Alamat"}
              type={"textarea"}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        <div className="d-flex mt-3 justify-content-end">
          <button className="btn btn-primary " onClick={() => onSave("save")}>
            Simpan
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalAlamatTambah;
