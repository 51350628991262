import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { getProdukAllChange } from "../../../config/redux/action/produkAction";
import { formatRupiah, nyonyaaAdminEndpoint } from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function ModalProduk({ show, handleClose, onchange }) {
  const { AllProduk } = useSelector((state) => state.produkReducer);
  const [Search, setSearch] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (show === true && Search.length === 0) {
      dispatch(
        getProdukAllChange({
          url: "/allproduk",
          type: "ALL_PRODUK_CHANGE",
          search: "",
        }),
        dispatch
      );
    }
  }, [show, Search]);

  useEffect(() => {
    if (show === true && Search.length > 3) {
      dispatch(
        getProdukAllChange({
          url: "/allproduk",
          type: "ALL_PRODUK_CHANGE",
          search: Search,
        }),
        dispatch
      );
    }
  }, [Search]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{`Add  Produk`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 position-relative">
          <input
            type={"text"}
            className="form-control form-control-sm mb-3"
            placeholder="masukan minimal 3 karakter"
            style={{ paddingLeft: "30px" }}
            value={Search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="position-absolute top-0 ps-2 pt-2"
            color="#6f717d"
          />
        </div>
        {AllProduk.length > 0 ? (
          AllProduk.map((val, key) => {
            return (
              <div
                key={key}
                className="d-flex border mb-1 hover rounded py-1 px-2"
                style={{ maxHeight: 150 }}
                onClick={() => {
                  onchange(val);
                  handleClose();
                }}
              >
                <div style={{ flex: 1, padding:5 }}>
                  <img
                    src={`${process.env.REACT_APP_STORAGE}${val.gambar}`}
                    alt={val.nama}
                    className="img-fluid"
                  />
                </div>
                <div style={{ flex:3 }} className="px-2">
                  <div className="fw-bold">{val.nama}</div>
                  <div>
                    {val.diskon > 0 ? (
                      <div className="d-flex">
                        <div className="text-decoration-line-through me-3">
                          {formatRupiah(String(val.harga), "Rp. ")}
                        </div>
                        <div className="text-danger">
                          {formatRupiah(String(val.harga - val.diskon), "Rp. ")}
                        </div>
                      </div>
                    ) : (
                      <div>{formatRupiah(String(val.harga), "Rp. ")}</div>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModalProduk;
