export const nyonyaaAdminEndpoint =
  process.env.REACT_APP_ENDPOINT || `https://sandbox.nyonyaa.com`;

export const formatRupiah = (angka, prefix = "") => {
  let number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  if (ribuan) {
    let separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
  return prefix + rupiah;
};

export const formatNormali = (value) => {
  let str = value;
  str = str.replace(/Rp./, "").replace(/\./g, "").replace(/\,/g, ".");
  return str;
};

export const emaiValidation = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const Bayar = [
  { value: 1, keterangan: "Batal Cuci" },
  { value: 2, keterangan: "Belum Bayar" },
  { value: 3, keterangan: "Pending" },
  { value: 4, keterangan: "Sudah Bayar" },
  { value: 5, keterangan: "Bayar COD" },
  { value: 6, keterangan: "Sudah Bayar (COD)" },
];

export const Driver = [
  { value: null, keterangan: "Menunggu jadwal driver" },
  { value: 2, keterangan: "Driver menjemput pakaian" },
  { value: 3, keterangan: "Driver antar ke workshop" },
  { value: 4, keterangan: "Driver menunggu cucian" },
  { value: 5, keterangan: "Driver mengantar cucian" },
  { value: 6, keterangan: "Pesanan selesai diantarkan" },
];

// export const Produksi = [
// 	{ value: null, keterangan: 'Menunggu pakaian' },
// 	{ value: 2, keterangan: 'Workshop sedang proses pengecekan Pakain' },
// 	{ value: 3, keterangan: 'Workshop menunggu validasi konsumen' },
// 	{ value: 4, keterangan: 'Batal mencuci' },
// 	{ value: 5, keterangan: 'Pakaian sedang dicuci' },
// 	{ value: 6, keterangan: 'Pakaian sedang disetrika' },
// 	{ value: 7, keterangan: 'Pakaian siap diantar' },
// ]

export const Produksi = [
  { value: null, keterangan: "Menunggu pakaian" },
  { value: 2, keterangan: "Workshop sedang proses pengecekan Pakain" },
  { value: 3, keterangan: "Workshop menunggu validasi konsumen" },
  { value: 4, keterangan: "Batal mencuci" },
  { value: 5, keterangan: "Pakaian sudah divalidasi konsumen" },
  { value: 6, keterangan: "Pakaian sedang dicuci" },
  { value: 7, keterangan: "Pakaian selesai dicuci" },
  { value: 8, keterangan: "Pakaian sedang disetrika" },
  { value: 9, keterangan: "Pakaian selesai disetrika" },
  { value: 10, keterangan: "Pakaian siap diantar" },
];

export const Order = [
  { value: "PaketSatuan", keterangan: "Paket Satuan" },
  { value: "PaketSetrika", keterangan: "Paket Setrika" },
  { value: "OrderSatuan", keterangan: "Order Satuan" },
  { value: "berlangganan", keterangan: "Berlangganan" },
  { value: "CuciKiloan", keterangan: "Cuci Kiloan" },
  { value: "CuciKarpet", keterangan: "Cuci Karpet" },
  { value: "SetrikaKiloan", keterangan: "Setrika Kiloan" },
  { value: "CuciDeluxe", keterangan: "Cuci Deluxe" },
  { value: "CuciLipat", keterangan: "Cuci Lipat" },
];

export const Account = [
  { value: 0, keterangan: "1-10005", properti: "Midtrans" },
  { value: 1, keterangan: "1-10004", properti: "Kas Kecil" },
  { value: 2, keterangan: "1-10002", properti: "Kas BCA Arbilly" },
  { value: 3, keterangan: "1-10006", properti: "Kas BCA Nyonyaa" },
];

export const statusBayar = (status) => {
  let bayar = "No data";

  for (let i = 0; i < Bayar.length; i++) {
    const val = Bayar[i];
    if (val.value === status) {
      bayar = val.keterangan;
    }
  }

  return bayar;
};

export const statusDriver = (status) => {
  let driver = Driver;

  return driver.map((val) => {
    if (val.value === status) {
      return val.keterangan;
    }
  });
};

export const statusProduksi = (status) => {
  let produksi = Produksi;

  return produksi.map((val) => {
    if (val.value === status) {
      return val.keterangan;
    }
  });
};

export const jenisOrder = (jenis) => {
  let order = Order[0].keterangan;

  for (let i = 0; i < Order.length; i++) {
    const val = Order[i];
    if (val.value === jenis) {
      order = val.keterangan;
    }
  }
  return order;
};

export const statusAccount = (status) => {
  let account = Account;

  return account.map((val) => {
    if (val.value === status) {
      return val.keterangan;
    }
  });
};
