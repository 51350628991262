import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getDataAPI } from "../../../config/redux/action/newRequestAction";
import moment from "moment";

function CopyOrder() {
  const dispatch = useDispatch();
  const [stateButton, setStateButton] = useState(0);

  const copyText = () => {
    setStateButton(1);
    dispatch(
      getDataAPI({
        url: "/order/oneday",
      })
    )
      .then((result) => {
        let data = result.data;
        let text = "WO " + moment().format("Do MMMM YYYY") + " \n\n";

        for (let i = 0; i < data.length; i++) {
          const val = data[i];
          text += val.data.no_order + "\n";
          text += val.tipe + "\n";
          text += "Nama Pemesan : " + val.data.nama + "\n";

          if (val.data.alamat_jemput === val.data.alamat_kirim) {
            text += "Alamat : \n" + val.data.alamat_jemput + "\n";
          } else {
            text += "Alamat Jemput: \n" + val.data.alamat_jemput + "\n";
            text += "Alamat Kirim: \n" + val.data.alamat_kirim + "\n";
          }

          text += "Jam :" + val.data.jam + "\n";
          text += "Note :" + val.data.note + "\n\n";
        }

        navigator.clipboard.writeText(text);
        setStateButton(2);
        setTimeout(() => {
          setStateButton(0);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <button className="btn btn-sm btn-dark" onClick={copyText}>
      {stateButton === 0 ? (
        <>Salin Order</>
      ) : stateButton === 1 ? (
        <>Ambil Data</>
      ) : (
        <>Berhasil Salin!</>
      )}
    </button>
  );
}

export default CopyOrder;
