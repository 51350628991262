const initialState = {
    AlamatList: []
}

const alamatReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ALAMAT_LIST":
            return {
                ...state,
                AlamatList: action.payload
            }
        default:
            return state
    }

}

export default alamatReducer