const initialState = {
  WorkshopList: [],
  UserListWorkshop: [],
};

const workshopReducer = (state = initialState, action) => {
  switch (action.type) {
    case "WORKSHOP_LIST_CHANGE":
      return {
        ...state,
        WorkshopList: action.payload,
      };
    case "USER_LIST_WORKSHOP":
      return {
        ...state,
        UserListWorkshop: action.payload,
      };
    default:
      return state;
  }
};

export default workshopReducer;
