import { faStar, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import moment from "moment";

function CardRatingDetail({ data, ...rest }) {
  return (
    <div className="py-1 ps-2 mx-1 rounded row hover mb-2 border" {...rest}>
      <div className="col-12 col-sm-3 col-lg-2 text-center">
        {data.user_konsumen.gambar === "" ||
        data.user_konsumen.gambar === null ? (
          <FontAwesomeIcon
            icon={faUserCircle}
            style={{
              height: 100,
              width: 100,
            }}
            className="rounded-circle border border-1 border-info"
          />
        ) : data.user_konsumen.gambar.substr(0, 4) === "http" ? (
          <img
            src={data.user_konsumen.gambar}
            alt={data.user_konsumen.nama}
            height={100}
            width={100}
            className="rounded-circle border border-1 border-info"
            style={{
              objectFit: "cover",
            }}
          />
        ) : (
          <img
            src={process.env.REACT_APP_STORAGE + data.user_konsumen.gambar}
            alt={data.user_konsumen.nama}
            height={100}
            width={100}
            className="rounded-circle border border-1 border-info"
            style={{
              objectFit: "cover",
            }}
          />
        )}
      </div>
      <div className="col-12 col-sm-9 col-lg-10  row">
        <div className="col-12 col-sm-6 col-lg-8 ">
          <div className="fw-bold">{data.user_konsumen.nama}</div>

          <div className="d-flex">
            <FontAwesomeIcon icon={faStar} className="align-self-center text-success" />
            <div className="ms-2 align-self-center">{data.rate}</div>
          </div>
          <div className="">{data.komentar}</div>
        </div>
        <div className="col-12 col-sm-6 col-lg-4 text-end mt-3 mt-sm-0">
          <div className="fw-bold">{data.no_order}</div>
          <div>{moment(data.createdAt).format("LLL")}</div>
        </div>
      </div>
    </div>
  );
}

export default CardRatingDetail;
