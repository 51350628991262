import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserKategoriList } from '../../../config/redux/action/userAction'

function SettingKategoriUser() {
    const { ListKategoriUser } = useSelector(state => state.userReducer)
    const [Update, setUpdate] = useState(1)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUserKategoriList({
            url: '/user/kategori',
            type: 'LIST_KATEGORI_USER_CHANGE'
        }), dispatch)

    }, [Update])


    return (
        <div className='bg-white rounded px-2 py-1 shadow d-flex justify-content-center flex-column me-2'>
            <div className='fw-bold mb-3 text-center'>Kategori User</div>
            {
                (ListKategoriUser.length > 0) ?
                    ListKategoriUser.map(val => {
                        return (
                            <div key={val.id}>
                                <div key={val.id}
                                    className='border rounded hover text-center mb-1'
                                // onClick={() => {
                                //     setDataJam(val)
                                //     setShowJam(true)
                                // }}
                                >
                                    {val.nama}
                                </div>
                            </div>
                        )
                    })
                    :
                    <>Tidak ada Data</>
            }
        </div>
    )
}

export default SettingKategoriUser