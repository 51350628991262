import React from "react";
import { usePagination, DOTS } from "./usePagination";
import "./pagination.scss";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={`pagination-container justify-content-end mt-3`}
    >
      <li
        className={`pagination-item  ${currentPage === 1&& 'opacity-3 disabled'}`}
        onClick={onPrevious}
      >
        <i className="fa fa-angle-left"></i>
      </li>
      {paginationRange.map((pageNumber, key) => {
        if (pageNumber === DOTS) {
          return <li key={key} className=" dots">&#8230;</li>;
        }

        return (
          <li
          key={key}
            className={`pagination-item  ${pageNumber === currentPage && 'selected'}`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={`pagination-item  ${currentPage === lastPage && 'opacity-3 disabled'}`}
        onClick={onNext}
      >
        <i className="fa fa-angle-right"></i>
      </li>
    </ul>
  );
};

export default Pagination;
