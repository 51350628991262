import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faBook,
  faDashboard,
  faGear,
  faPowerOff,
  faReceipt,
  faStore,
  faTag,
  faUserCheck,
  faUsers,
  faStar,
  faUserAlt,
  faStoreAlt,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { LogoutSession } from "../../../config/redux/action/sessionLogin";
import { useDispatch } from "react-redux";
import logo from "../../../assets/img/logo.png";

function Menu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const toPage = (val) => {
    navigate(val);
  };

  const signOut = () => {
    dispatch(LogoutSession("ok", dispatch));
  };

  return (
    <div className="pt-2 mx-2">
      <div className="d-block d-md-none justify-content-center d-flex rounded mb-3">
        <img
          src={logo}
          style={{ height: "100px", width: "100px" }}
          alt="logo"
          className="shadow-sm rounded"
        />
      </div>

      <div
        className={`hover rounded px-2 py-1 my-1 d-flex align-items-baseline ${
          pathname === "/dashboard" ? `active` : ``
        }`}
        onClick={() => toPage("/dashboard")}
      >
        <FontAwesomeIcon
          icon={faDashboard}
          size="1x"
          className="align-self-center pe-2"
        />
        <a className="text-white">Dashboard</a>
      </div>
      <div
        className={`rounded hover px-2 py-1 my-1 d-flex align-items-baseline ${
          pathname === "/master" ? `active` : ``
        }`}
        onClick={() => toPage("/master")}
      >
        <FontAwesomeIcon
          icon={faBook}
          size="1x"
          className="align-self-center pe-2"
        />
        <a className="text-white">Master</a>
      </div>
      <div
        className={`rounded hover px-2 py-1 my-1 d-flex align-items-baseline ${
          pathname === "/voucer" ? `active` : ``
        }`}
        onClick={() => toPage("/voucer")}
      >
        <FontAwesomeIcon
          icon={faTag}
          size="1x"
          className="align-self-center pe-2"
        />
        <a className="text-white">Voucer</a>
      </div>
      <div
        className={`rounded hover px-2 py-1 my-1 d-flex align-items-baseline ${
          pathname === "/promo" ? `active` : ``
        }`}
        onClick={() => toPage("/promo")}
      >
        <FontAwesomeIcon
          icon={faReceipt}
          size="1x"
          className="align-self-center pe-2"
        />
        <a className="text-white">Promo</a>
      </div>
      <div
        className={`rounded hover px-2 py-1 my-1 d-flex align-items-baseline ${
          pathname === "/rating" || pathname === "/rating/detail"
            ? `active`
            : ``
        }`}
        onClick={() => toPage("/rating")}
      >
        <FontAwesomeIcon
          icon={faStar}
          size="1x"
          className="align-self-center pe-2"
        />
        <a className="text-white">Rating</a>
      </div>
      <div
        className={`rounded hover px-2 py-1 my-1 d-flex align-items-baseline ${
          pathname === "/order/create" ? `active` : ``
        }`}
        onClick={() => toPage("/order/create")}
      >
        <FontAwesomeIcon
          icon={faCartPlus}
          size="1x"
          className="align-self-center pe-2"
        />
        <a className="text-white">Buat Order</a>
      </div>
      <div
        className={`rounded hover px-2 py-1 my-1 d-flex align-items-baseline ${
          pathname === "/order" ||
          pathname === "/order/detail" ||
          pathname === "/order/berlangganan/detail"
            ? `active`
            : ``
        }`}
        onClick={() => toPage("/order")}
      >
        <FontAwesomeIcon
          icon={faStore}
          size="1x"
          className="align-self-center pe-2"
        />
        <a className="text-white">Order</a>
      </div>
      <div
        className={`rounded hover px-2 py-1 my-1 d-flex align-items-baseline ${
          pathname === "/report" ? `active` : ``
        }`}
        onClick={() => toPage("/report")}
      >
        <FontAwesomeIcon
          icon={faChartBar}
          size="1x"
          className="align-self-center pe-2"
        />
        <a className="text-white">Report</a>
      </div>
      <div
        className={`rounded hover px-2 py-1 my-1 d-flex align-items-baseline ${
          pathname === "/user" ? `active` : ``
        }`}
        onClick={() => toPage("/user")}
      >
        <FontAwesomeIcon
          icon={faUsers}
          size="1x"
          className="align-self-center pe-2"
        />
        <a className="text-white">User Management</a>
      </div>
      <div
        className={`rounded hover px-2 py-1 my-1 d-flex align-items-baseline ${
          pathname === "/workshop" ? `active` : ``
        }`}
        onClick={() => toPage("/workshop")}
      >
        <FontAwesomeIcon
          icon={faStoreAlt}
          size="1x"
          className="align-self-center pe-2"
        />
        <a className="text-white">Workshop</a>
      </div>
      <div
        className={`rounded hover px-2 py-1 my-1 d-flex align-items-baseline ${
          pathname === "/user/migration" ? `active` : ``
        }`}
        onClick={() => toPage("/user/migration")}
      >
        <FontAwesomeIcon
          icon={faUserCheck}
          size="1x"
          className="align-self-center pe-2"
        />
        <a className="text-white">User Migration</a>
      </div>
      <div
        className={`rounded hover px-2 py-1 my-1 d-flex align-items-baseline ${
          pathname === "/setting" ? `active` : ``
        }`}
        onClick={() => toPage("/setting")}
      >
        <FontAwesomeIcon
          icon={faGear}
          size="1x"
          className="align-self-center pe-2"
        />
        <a className="text-white">Setting</a>
      </div>
      <div
        className={`rounded hover px-2 py-1 my-1 d-flex align-items-baseline`}
        onClick={() => signOut()}
      >
        <FontAwesomeIcon
          icon={faPowerOff}
          size="1x"
          className="align-self-center pe-2"
        />
        <a className="text-white">Logout</a>
      </div>
    </div>
  );
}

export default Menu;
