import React, { useState } from "react";
import {
  AktivitasUserDashboard,
  OrderBankPeriode,
  OrderBerlangsung,
  OrderHutang,
  OrderOngkirSum,
  OrderPeriode,
  OrderSumKg,
  ProdukKategoriOrderChart,
  ProdukOrderChart,
  SideBar,
} from "../../components";
import moment from "moment";
import ReactDatePicker from "react-datepicker";

function HomePage() {
  const [dateRange, setDateRange] = useState([
    moment().startOf("month").toDate(),
    moment().endOf("month").toDate(),
  ]);
  const [startDate, endDate] = dateRange;

  return (
    <div>
      <SideBar />
      <div className="content">
        <div className="row">
          <div className="col-12 col-md-6 px-1 mt-2">
            <OrderPeriode />
          </div>
          <div className="col-12 col-md-6 px-1 mt-2">
            <OrderBankPeriode />
          </div>
          <div className="col-12 col-md-4 px-1 mt-2">
            <OrderOngkirSum />
          </div>
          <div className="col-12 col-md-8 px-1 mt-2 px-3">
            <div className="row bg-white rounded shadow">
              <div className="col-12 d-flex justify-content-center align-items-center my-4">
                <div className="fw-bold me-2">Filter :</div>
                <ReactDatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(update) => {
                    if (update[1] !== null) {
                      update[1] = moment(update[1])
                        .set({ h: 23, m: 59 })
                        .toDate();
                    }
                    setDateRange(update);
                  }}
                  withPortal
                  showMonthDropdown
                  showYearDropdown
                  className="form-control form-control-sm"
                />
              </div>
              <div className="col-12 col-md-6">
                <ProdukOrderChart dateRange={dateRange} />
              </div>
              <div className="col-12 col-md-6">
                <ProdukKategoriOrderChart />
              </div>
            </div>
          </div>
          <div className="col-12 px-1 mt-2">
            <OrderSumKg />
          </div>
          <div className="col-12 col-md-6 px-1 mt-2">
            <OrderBerlangsung />
          </div>
          <div className="col-12 col-md-6 px-1 mt-2">
            <OrderHutang />
          </div>
          <div className="col-12 col-md-12 px-1 mt-2">
            <AktivitasUserDashboard />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
