import axios from "axios";
import { nyonyaaAdminEndpoint } from "../../../utils";

export const getUserList = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  axios
    .get(nyonyaaAdminEndpoint + payload.url, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      params: {
        user_id: session.data.id,
        role_id: payload.role,
        limit: payload.limit,
        nama: payload.nama || "",
      },
    })
    .then((res) => {
      if (res.status == 200) {
        dispatch({ type: payload.type, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const getRoleList = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  axios
    .get(nyonyaaAdminEndpoint + payload.url, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      params: {
        user_id: session.data.id,
      },
    })
    .then((res) => {
      if (res.status == 200) {
        dispatch({ type: payload.type, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const tambahUser = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("user_id", session.data.id);
    data.append("kode", payload.kode || "NEW_USER");
    data.append("roles[]", payload.role);
    // data.append("image", payload.gambar);
    data.append("nama", payload.nama);
    data.append("email", payload.email);
    data.append("notelp", payload.notelp);
    data.append("validasi", payload.aktif);
    data.append("password", payload.password);
    data.append("kategori_user_id", payload.kategori_user_id);
    data.append("phone_verify", payload.phone_verify);

    axios({
      method: "post",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        if (res.status === 403) {
          return resolve("ada");
        }
        return resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const updateUser = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("user_id", session.data.id);
    data.append("id", payload.id);
    data.append("kode", payload.kode || "NEW_USER");
    data.append("roles[]", payload.role);
    data.append("image", payload.gambar);
    data.append("nama", payload.nama);
    data.append("email", payload.email);
    data.append("notelp", payload.notelp);
    data.append("validasi", payload.aktif);
    data.append("password", payload.password);
    data.append("kategori_user_id", payload.kategori_user_id);
    data.append("phone_verify", payload.phone_verify);

    axios({
      method: "PATCH",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "multipart/form-control",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        // return console.log(res);
        if (res.status === 201) {
          return resolve("ada");
        }
        return resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const deleteUser = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("id", payload.id);
    data.append("user_id", session.data.id);

    axios({
      method: "DELETE",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const getUserKategoriList = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  axios
    .get(nyonyaaAdminEndpoint + payload.url, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
    })
    .then((res) => {
      if (res.status == 200) {
        dispatch({ type: payload.type, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const getAktifitasUserList = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  axios
    .get(nyonyaaAdminEndpoint + payload.url, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      params: {
        dateRange: payload.dateRange,
      },
    })
    .then((res) => {
      if (res.status == 200) {
        dispatch({ type: payload.type, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};
