import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

function AlamatMaps({ searchRef }) {
    const { AlamatGlobal } = useSelector(state => state.globalReducer)
    const ref = useRef()
    const dispatch = useDispatch()

    useEffect(() => {

        const map = new window.google.maps.Map(ref.current, {
            center: { lat: -6.9148621, lng: 107.6311379 },
            zoom: 10.7,
            disableDefaultUI: true,
        })

        const input = document.getElementById("pac-input");
        const searchBox = new window.google.maps.places.SearchBox(input);

        map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(input);
        // Bias the SearchBox results towards current map's viewport.
        map.addListener("bounds_changed", () => {
            searchBox.setBounds(map.getBounds());
        });

        let markers = [];

        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();

            if (places.length == 0) {
                return;
            }
            // map.setMapOnAll(null);

            // Clear out the old markers.
            markers.forEach((marker) => {
                marker.setMap(null);
            });
            markers = [];

            // For each place, get the icon, name and location.
            const bounds = new window.google.maps.LatLngBounds();

            places.forEach((place) => {
                if (!place.geometry || !place.geometry.location) {
                    // console.log("Returned place contains no geometry");
                    return;
                }

                // console.log(place);
                let Position = place.geometry.location.toJSON()
                dispatch({
                    type: 'MANAGE_ALAMAT_CHANGE',
                    payload: {
                        Alamat: place.formatted_address,
                        KodePos: place.address_components[place.address_components.length - 1].long_name,
                        LongLat: Position
                    }
                })


                // Create a marker for each place.
                markers.push(
                    new window.google.maps.Marker({
                        map,
                        title: place.name,
                        position: place.geometry.location,
                    })
                );
                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
            map.fitBounds(bounds);
        });

        setTimeout(() => {
          if (AlamatGlobal.longlat === undefined && input.value === '') {
            return true
          }

          let position = AlamatGlobal.longlat.split(',')
          let myLatLng = {
            lat: parseFloat(position[0]),
            lng: parseFloat(position[1])
          }

          new window.google.maps.Marker({
            position: myLatLng,
            map,
            title: AlamatGlobal.nama_penerima,
          });

          map.setZoom(18);
          map.panTo(myLatLng);
        }, 3000);
    }, [])




    return (
        <div className='w-100 h-100'>
            <input ref={searchRef} id='pac-input'
                className='form-control form-control-sm pac-container mt-1'
                placeholder='cari alamat'
            />

            <div ref={ref} className='w-100 h-100' />
        </div>
    )
}

export default AlamatMaps