import axios from "axios"
import { nyonyaaAdminEndpoint } from "../../../utils"

export const getUserWorkshopList = (payload) => async (dispatch) => {
    const session = JSON.parse(localStorage.getItem("session_user"))
    axios.get(nyonyaaAdminEndpoint + payload.url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${session.data.jwt}`,
        },
        params: {
            user_id: session.data.id,
            role_id: payload.role,
            limit: payload.limit,
            nama: payload.nama || ''
        }
    })
        .then(res => {
            if (res.status == 200) {
                dispatch({ type: payload.type, payload: res.data.data })
            }
        })
        .catch(err => console.log(err))
}

export const getWorkshopChange = (payload) => async (dispatch) => {
    const session = JSON.parse(localStorage.getItem("session_user"))
    axios.get(nyonyaaAdminEndpoint + payload.url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${session.data.jwt}`,
        },
        params: {
            user_id: session.data.id,
            workshop_id: payload.workshop_id,
        }
    })
        .then(res => {
            if (res.status == 200) {
                dispatch({ type: payload.type, payload: res.data.data })
            }
        })
        .catch(err => console.log(err))
}

export const saveWorkshop = (payload) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        const session = JSON.parse(localStorage.getItem("session_user"))
        let data = new FormData()
        data.append('user_id', session.data.id)
        data.append('kode', 'DRIVER')
        data.append('image', payload.gambar)
        data.append('nama', payload.nama)
        data.append('alamat', payload.alamat)
        data.append('plat_nomor', payload.plat_nomor)
        data.append('aktif', payload.aktif)

        axios({
            method: 'post',
            url: nyonyaaAdminEndpoint + payload.url,
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${session.data.jwt}`,
            },
            data
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.log(err);
                return reject(err)
            })
    })

}

export const updateWorkshop = (payload) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        const session = JSON.parse(localStorage.getItem("session_user"))
        let data = new FormData()
        data.append('user_id', session.data.id)
        data.append('driver_id', payload.driver_id)
        data.append('kode', 'DRIVER')
        data.append('image', payload.gambar)
        data.append('nama', payload.nama)
        data.append('alamat', payload.alamat)
        data.append('plat_nomor', payload.plat_nomor)
        data.append('aktif', payload.aktif)

        axios({
            method: 'post',
            url: nyonyaaAdminEndpoint + payload.url,
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${session.data.jwt}`,
            },
            data
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.log(err);
                return reject(err)
            })
    })

}

export const deleteWorkshop = (payload) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        const session = JSON.parse(localStorage.getItem("session_user"))
        let data = new FormData()
        data.append('id', payload.driver_id)
        data.append('user_id', session.data.id)

        axios({
            method: 'DELETE',
            url: nyonyaaAdminEndpoint + payload.url,
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${session.data.jwt}`,
            },
            data
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.log(err);
                return reject(err)
            })
    })
}

export const addUserWorkshop = (payload) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        const session = JSON.parse(localStorage.getItem("session_user"))
        let data = new FormData()
        data.append('user_id', payload.user_id)
        data.append('workshop_id', payload.workshop_id)

        axios({
            method: 'post',
            url: nyonyaaAdminEndpoint + payload.url,
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${session.data.jwt}`,
            },
            data
        })
            .then(res => {
                resolve(res.status)
            })
            .catch(err => {
                console.log(err);
                return reject(err)
            })
    })
}

export const deleteUserWorkshop = (payload) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        const session = JSON.parse(localStorage.getItem("session_user"))
        let data = new FormData()
        data.append('id', payload.id)

        axios({
            method: 'DELETE',
            url: nyonyaaAdminEndpoint + payload.url,
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${session.data.jwt}`,
            },
            data
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.log(err);
                return reject(err)
            })
    })
}