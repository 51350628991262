import { faArrowLeftLong, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDatePicker from "react-datepicker";
import {
  AddDriver,
  AddWorkshop,
  ALertNotification,
  EditProduk,
  ImageZoom,
  ModalProduk,
  SideBar,
} from "../../components";
import {
  addKeteranganOrder,
  AddOrderNonBeralangganan,
  addPembayaranTanggalOrder,
  ChangeStatus,
  deleteOrderNonBeralangganan,
  getOrderList,
  manageOrderNonBeralangganan,
  selesaiOrderTanggal,
  updateAccountJurnal,
} from "../../config/redux/action/orderAction";
import {
  Account,
  Bayar,
  Driver,
  formatRupiah,
  jenisOrder,
  nyonyaaAdminEndpoint,
  Order,
  Produksi,
  statusBayar,
  statusDriver,
  statusProduksi,
} from "../../utils";
import { Chrono } from "react-chrono";
import moment from "moment";
import { patchDataAPI } from "../../config/redux/action/newRequestAction";
import OrderDetailTimeline from "./OrderDetailTimeline";

function OrderDetailPage() {
  const driverRef = useRef(null);
  const workshopRef = useRef(null);
  const bayarRef = useRef(null);
  const [Data, setData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [PembayaranDate, setPembayaranDate] = useState(null);
  const [StatusBayar, setStatusBayar] = useState(0);
  const [StatusWorkshop, setStatusWorkshop] = useState(0);
  const [StatusDriver, setStatusDriver] = useState(0);
  const [AccountJurnal, setAccountJurnal] = useState(0);
  const [DriverId, setDriverId] = useState(0);
  const [WorkshopId, setWorkshopId] = useState(0);
  const [Update, setUpdate] = useState(0);
  const [Keterangan, setKeterangan] = useState("");
  const [BtnDisable, setBtnDisable] = useState(false);
  const [showDriver, setShowDriver] = useState(false);
  const handleCloseDriver = () => setShowDriver(false);
  const [showWorkshop, setShowWorkshop] = useState(false);
  const handleCloseWorkshop = () => setShowWorkshop(false);
  const [showProduk, setShowProduk] = useState(false);
  const handleCloseProduk = () => setShowProduk(false);
  const [showEditProduk, setShowEditProduk] = useState(false);
  const handleCloseEditProduk = () => setShowEditProduk(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const handleCloseAlert = () => setShowAlert(false);
  const [Pesan, setPesan] = useState("");
  const { OrderDetail } = useSelector((state) => state.orderReducer);
  const [DataProduksi, setDataProduksi] = useState([]);
  const params = new URLSearchParams(window.location.search);
  let paramsNoOrder = params.get("no_order");
  let total = 0;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getOrderList({
        url: "/order/detail?no_order=" + paramsNoOrder,
        type: "ORDER_DETAIL_LIST_CHANGE",
      }),
      dispatch
    );
  }, [Update]);

  useEffect(() => {
    if (OrderDetail !== null) {
      if (OrderDetail.status_driver !== null) {
        driverRef.current.value = OrderDetail.status_driver;
        setDriverId(OrderDetail.driver_id);
        setStatusDriver(OrderDetail.status_driver);
      }
      if (OrderDetail.status_produksi !== null) {
        workshopRef.current.value = OrderDetail.status_produksi;
        setWorkshopId(OrderDetail.workshop_id);
        setStatusWorkshop(OrderDetail.status_produksi);
      }

      bayarRef.current.value = OrderDetail.status;
      setStatusBayar(OrderDetail.status);

      if (OrderDetail.tanggal_terima !== null) {
        setStartDate(new Date(OrderDetail.tanggal_terima));
      }

      if (OrderDetail.tanggal_bayar !== null) {
        setPembayaranDate(new Date(OrderDetail.tanggal_bayar));
      }

      setAccountJurnal(OrderDetail.account_jurnal);

      setKeterangan(
        OrderDetail.keterangan !== "" && OrderDetail.keterangan !== null
          ? OrderDetail.keterangan
          : "-"
      );

      if (OrderDetail.gambar_produksi.length > 0) {
        let dataProduksi = [];
        OrderDetail.gambar_produksi.map((val) => {
          dataProduksi.push({
            title: moment(new Date(val.createdAt)).format("LLL"),
            cardSubtitle:
              val.keterangan !== "Driver"
                ? statusProduksi(parseInt(val.type))
                : statusDriver(parseInt(val.type)),
            // cardTitle: 'tes',
            media: {
              type: "IMAGE",
              source: {
                url: process.env.REACT_APP_STORAGE + val.lokasi,
              },
            },
          });
        });

        setDataProduksi(dataProduksi);
      }
    }
  }, [OrderDetail]);

  const changeDriver = (driver_id, status_driver) => {
    if (driver_id === null || driver_id === 0 || driver_id === undefined) {
      setPesan(`Terjadi kesalahan driver_id = ${driver_id}`);
      return setShowAlert(true);
    }

    dispatch(
      ChangeStatus({
        url: "/order/change/driver",
        id: OrderDetail.id,
        driver_id,
        status_driver,
        title: "driver",
      }),
      dispatch
    ).then(() => {
      setUpdate(Update + 1);
    });
  };

  const changeWorkshop = (workshop_id, status_workshop) => {
    if (
      workshop_id === null ||
      workshop_id === 0 ||
      workshop_id === undefined
    ) {
      setPesan(`Terjadi kesalahan workshop_id = ${workshop_id}`);
      return setShowAlert(true);
    }

    dispatch(
      ChangeStatus({
        url: "/order/change/workshop",
        id: OrderDetail.id,
        workshop_id,
        status_workshop,
        title: "workshop",
      }),
      dispatch
    ).then(() => {
      setUpdate(Update + 1);
    });
  };

  const changeBayar = (status) => {
    dispatch(
      ChangeStatus({
        url: "/order/change/bayar",
        id: OrderDetail.id,
        status,
        title: "bayar",
      }),
      dispatch
    ).then(() => {
      setUpdate(Update + 1);
    });
  };

  const addOrder = (val) => {
    dispatch(
      AddOrderNonBeralangganan({
        url: "/order/add",
        no_order: OrderDetail.no_order,
        produk_id: val.id,
        qty: 1,
        satuan: val.satuan,
        harga: val.harga - val.diskon,
        nilai: val.harga - val.diskon,
        potong: 1,
      }),
      dispatch
    ).then(() => {
      setUpdate(Update + 1);
    });
  };

  const manageOrder = (val) => {
    if (val.title === "update") {
      let formData = new FormData();
      formData.append("id", val.id);
      formData.append("qty", val.qty);
      formData.append("harga", val.harga);
      formData.append("nilai", val.nilai);
      formData.append("potong", val.potong);
      formData.append("kg", val.kg);
      formData.append("no_order", OrderDetail.no_order);

      dispatch(
        patchDataAPI({
          url: "/order/update",
          data: formData,
        })
      ).then(() => {
        setUpdate(Update + 1);
      });

      // dispatch(
      //   manageOrderNonBeralangganan({
      //     url: "/order/update",
      //     id: val.id,
      //     potong: val.potong,
      //     qty: val.qty,
      //     harga: val.harga,
      //     nilai: val.nilai,
      //     kg: val.kg,
      //   }),
      //   dispatch
      // ).then(() => {
      //   setUpdate(Update + 1);
      // });
    } else {
      dispatch(
        deleteOrderNonBeralangganan({
          url: "/order/delete",
          id: val.id,
        }),
        dispatch
      ).then(() => {
        setUpdate(Update + 1);
      });
    }
  };

  const orderSelesai = (val) => {
    setStartDate(val);

    dispatch(
      selesaiOrderTanggal({
        url: "/order/selesai",
        tanggal_selesai: val,
        title: "nonBerlangganan",
        no_order: OrderDetail.no_order,
      }),
      dispatch
    ).then(() => {
      setUpdate(Update + 1);
    });
  };

  const tanggalPembayaran = (val) => {
    setPembayaranDate(val);

    dispatch(
      addPembayaranTanggalOrder({
        url: "/order/tanggal_bayar",
        tanggal_bayar: val,
        no_order: OrderDetail.no_order,
      }),
      dispatch
    ).then(() => {
      setUpdate(Update + 1);
    });
  };

  const addKeterangan = () => {
    dispatch(
      addKeteranganOrder({
        url: "/order/keterangan/update",
        keterangan: Keterangan,
        no_order: OrderDetail.no_order,
      }),
      dispatch
    ).then(() => {
      setUpdate(Update + 1);
      setBtnDisable(false);
    });
  };

  const accountChange = (value) => {
    setAccountJurnal(value);

    dispatch(
      updateAccountJurnal({
        url: "/order/account_jurnal",
        account_jurnal: value,
        no_order: OrderDetail.no_order,
      }),
      dispatch
    ).then(() => {
      setUpdate(Update + 1);
    });
  };

  return (
    <div>
      <SideBar />
      <div className="content">
        <div className="bg-white" style={{ fontSize: "12px" }}>
          <div className="ps-2 text-primary">
            <div onClick={() => window.history.back()} className="set-pointer">
              <FontAwesomeIcon icon={faArrowLeftLong} /> Kembali
            </div>
          </div>
          <div className="row mx-2 w-100">
            {OrderDetail !== null ? (
              <>
                <ul className="list-group col-12 col-md-12 col-lg-6 mb-3">
                  <li className="list-group-item d-flex justify-content-start">
                    <div className="fw-bold w-25">No Order</div>
                    <div className="w-75">{OrderDetail.no_order}</div>
                  </li>
                  <li className="list-group-item d-flex justify-content-start">
                    <div className="fw-bold w-25">Nama</div>
                    <div className="w-75">{OrderDetail.user.nama}</div>
                  </li>
                  <li className="list-group-item d-flex justify-content-start">
                    <div className="fw-bold w-25">Alamat Penjemputan</div>
                    <div className="w-75 ws-preline position-relative">
                      {OrderDetail.alamat_detail}
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${OrderDetail.titik_jemput}`}
                        target={"_blank"}
                        className="position-absolute top-0 end-0"
                      >
                        Buka Map
                      </a>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-start">
                    <div className="fw-bold w-25">Alamat Pengiriman</div>
                    <div className="w-75 ws-preline position-relative">
                      {OrderDetail.alamat_kirim}
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${OrderDetail.titik_kirim}`}
                        target={"_blank"}
                        className="position-absolute top-0 end-0"
                      >
                        Buka Map
                      </a>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-start">
                    <div className="fw-bold w-25">Pengiriman</div>
                    <div className="w-75">
                      {OrderDetail.mitra_id === 0 ? "Pickup" : "Antar Mitra"}
                      <div>{`Tanggal : ${new Date(
                        OrderDetail.tanggal_pesan
                      ).toLocaleDateString()}`}</div>
                      <div>{`Jam : ${OrderDetail.jam_kirim}`}</div>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-start">
                    <div className="fw-bold w-25">Status Bayar</div>
                    <div className="w-75">
                      <select
                        ref={bayarRef}
                        className="form-select form-select-sm"
                        onChange={(e) => changeBayar(e.target.value)}
                      >
                        {Bayar.length > 0 ? (
                          Bayar.map((val) => {
                            return (
                              <option key={val.value} value={val.value}>
                                {val.keterangan}
                              </option>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </select>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-start">
                    <div className="fw-bold w-25">Tanggal Bayar</div>
                    <div className="w-75">
                      <div className="col-12 col-sm-6 col-md-5 col-lg-6">
                        <ReactDatePicker
                          selected={PembayaranDate}
                          onChange={(date) => tanggalPembayaran(date)}
                          minDate={new Date().setDate(new Date().getDate() - 3)}
                          showDisabledMonthNavigation
                          showTimeInput
                          isClearable={true}
                          dateFormat="dd/MM/yyyy h:mm aa"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-start">
                    <div className="fw-bold w-25">Account Jurnal</div>
                    <div className="w-75">
                      <select
                        className="form-select form-select-sm w-50"
                        value={OrderDetail.account_jurnal}
                        onChange={(e) => accountChange(e.target.value)}
                      >
                        {Account.map((val) => {
                          return (
                            <option
                              key={val.value}
                              value={val.value}
                            >{`(${val.keterangan}) ${val.properti}`}</option>
                          );
                        })}
                      </select>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-start">
                    <div className="fw-bold w-25">Status Driver</div>
                    <div className="w-75">
                      {statusDriver(OrderDetail.status_driver)}
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-start">
                    <div className="fw-bold w-25">Status Produksi</div>
                    <div className="w-75">
                      {statusProduksi(OrderDetail.status_produksi)}
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-start">
                    <div className="fw-bold w-25">Keterangan</div>
                    <div className="w-75 d-flex flex-column">
                      <textarea
                        value={Keterangan}
                        onChange={(e) => setKeterangan(e.target.value)}
                        className="form-control"
                      />
                      {BtnDisable ? (
                        <button className="btn btn-sm btn-primary mt-2">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            className="spinner"
                          />
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-primary mt-2"
                          onClick={() => {
                            addKeterangan();
                            setBtnDisable(true);
                          }}
                        >
                          Simpan
                        </button>
                      )}
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-start">
                    <div className="fw-bold w-25">Poin</div>
                    <div className="w-75">
                      {formatRupiah(String(OrderDetail.point), "")}
                    </div>
                  </li>
                </ul>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="text-center fw-bold fs-5">
                    Order {jenisOrder(OrderDetail.jenis_order)}
                  </div>
                  <div className="mx-2 d-flex justify-content-end">
                    {OrderDetail.status === 3 || OrderDetail.status === 5 ? (
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          setShowProduk(true);
                        }}
                      >
                        Tambah Order
                      </button>
                    ) : null}
                  </div>
                  <div className="mx-2 pt-2">
                    <div className="table-responsive">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th>Nama</th>
                            <th className="text-end">Harga</th>
                            <th className="text-end">Qty</th>
                            <th className="text-end">Potongan</th>
                            <th className="text-end">Kilogram</th>
                            <th className="text-end">Nilai</th>
                          </tr>
                        </thead>
                        <tbody>
                          {OrderDetail.order_detail.length > 0 ? (
                            OrderDetail.order_detail.map((val) => {
                              total += val.nilai;
                              return (
                                <tr
                                  key={val.id}
                                  className="hover"
                                  onClick={() => {
                                    setData(val);
                                    setShowEditProduk(true);
                                  }}
                                >
                                  <td>{val.produk.nama}</td>
                                  <td align="right">
                                    {formatRupiah(String(val.harga), "Rp.")}
                                  </td>
                                  <td align="right">{val.qty}</td>
                                  <td align="right">
                                    {val.potong <= 1 ? "-" : val.potong}
                                  </td>
                                  <td align="right">
                                    {val.kg <= 1 ? "-" : val.kg}
                                  </td>
                                  <td align="right">
                                    {formatRupiah(String(val.nilai), "Rp.")}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </tbody>
                        <tfoot>
                          {OrderDetail.diskon !== 0 ||
                          OrderDetail.biaya_aplikasi !== 0 ||
                          OrderDetail.ongkos !== 0 ? (
                            <tr>
                              <td colSpan={5}>Sub Total :</td>
                              <td className="text-end">
                                {formatRupiah(String(total), "Rp .")}
                              </td>
                            </tr>
                          ) : null}

                          {OrderDetail.diskon !== 0 ? (
                            <tr>
                              <td colSpan={5}>Diskon :</td>
                              <td className="text-end  text-danger">
                                {formatRupiah(
                                  String(OrderDetail.diskon),
                                  "Rp ."
                                )}
                              </td>
                            </tr>
                          ) : null}
                          {OrderDetail.biaya_aplikasi !== 0 ? (
                            <tr>
                              <td colSpan={5}>Diskon :</td>
                              <td className="text-end text-success">
                                {formatRupiah(
                                  String(OrderDetail.biaya_aplikasi),
                                  "Rp ."
                                )}
                              </td>
                            </tr>
                          ) : null}

                          {OrderDetail.ongkos !== 0 ? (
                            <tr>
                              <td colSpan={5}>Diskon Ongkir :</td>
                              <td className="text-end text-danger">
                                {formatRupiah(
                                  String(OrderDetail.ongkos),
                                  "Rp ."
                                )}
                              </td>
                            </tr>
                          ) : null}

                          <tr>
                            <th colSpan={3}>Total :</th>
                            <th
                              colSpan={3}
                              className="text-end"
                              style={{ fontSize: 18 }}
                            >
                              {formatRupiah(
                                String(
                                  total +
                                    OrderDetail.diskon +
                                    OrderDetail.biaya_aplikasi +
                                    OrderDetail.ongkos
                                ),
                                "Rp ."
                              )}
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div>
                      {OrderDetail.note.length > 0 ? (
                        <div className="ws-preline p-2 border border-info rounded">
                          {`Note :\n`}
                          {OrderDetail.note}
                        </div>
                      ) : null}
                    </div>
                    <ul className="list-group mt-2 mb-3">
                      <li className="list-group-item d-flex justify-content-start">
                        <div className="fw-bold w-25">Voucer</div>
                        <div className="w-75">
                          {OrderDetail.diskon < 0
                            ? `(${formatRupiah(
                                String(OrderDetail.diskon),
                                "Rp ."
                              )}) ${OrderDetail.voucers.kode}`
                            : "-"}
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-start">
                        <div className="fw-bold w-25">Bank / Payment</div>
                        <div className="w-75">
                          {OrderDetail.payment_accept?.nama || 'Default Payment'}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="bg-white mt-2 pt-2 pb-3" style={{ fontSize: "14px" }}>
          <div className="row">
            {OrderDetail !== null ? (
              <>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="fw-bold mb-2 text-center">
                    Status Workshop
                  </div>
                  <ul className="list-group mx-2">
                    <li className="list-group-item d-flex justify-content-start">
                      <div className="fw-bold w-25">WorkShop</div>
                      <div className="w-75 position-relative">
                        {OrderDetail.workshop === null ? (
                          <>Belum Ada</>
                        ) : (
                          <>{OrderDetail.workshop.nama}</>
                        )}
                        <div
                          className="position-absolute top-0 end-0 set-pointer text-decoration-underline text-primary px-2 rounded"
                          onClick={() => setShowWorkshop(true)}
                        >
                          Ganti
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-start">
                      <div className="fw-bold w-25">Status</div>
                      <div className="w-75">
                        <select
                          ref={workshopRef}
                          className="form-control form-control-sm"
                          onChange={(e) => {
                            let val =
                              e.target.value.length > 2 ? 0 : e.target.value;

                            setStatusDriver(val);
                            changeWorkshop(WorkshopId, val);
                          }}
                          disabled={
                            OrderDetail.workshop === null ? true : false
                          }
                        >
                          {Produksi.map((val) => {
                            return (
                              <option key={val.value} value={val.value}>
                                {val.keterangan}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="fw-bold mb-2 text-center">Status Driver</div>
                  <ul className="list-group mx-2">
                    <li className="list-group-item d-flex justify-content-start">
                      <div className="fw-bold w-25">Driver</div>
                      <div className="w-75 position-relative">
                        {OrderDetail.driver === null ? (
                          <>Belum Ada</>
                        ) : (
                          <>{OrderDetail.driver.nama}</>
                        )}
                        <div
                          className="position-absolute top-0 end-0 set-pointer text-decoration-underline text-primary px-2 rounded"
                          onClick={() => setShowDriver(true)}
                        >
                          Ganti
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-start">
                      <div className="fw-bold w-25">Status</div>
                      <div className="w-75">
                        <select
                          ref={driverRef}
                          className="form-control form-control-sm"
                          onChange={(e) => {
                            let val =
                              e.target.value.length > 2 ? 0 : e.target.value;

                            setStatusDriver(val);
                            changeDriver(DriverId, val);
                          }}
                          disabled={OrderDetail.driver === null ? true : false}
                        >
                          {Driver.map((val) => {
                            return (
                              <option key={val.value} value={val.value}>
                                {val.keterangan}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-start">
                      <div className="fw-bold w-25">Tgl Selesai</div>
                      <div className="w-50">
                        <ReactDatePicker
                          selected={startDate}
                          onChange={(date) => orderSelesai(date)}
                          minDate={new Date().setDate(new Date().getDate() - 1)}
                          showDisabledMonthNavigation
                          showTimeInput
                          isClearable={true}
                          dateFormat="dd/MM/yyyy h:mm aa"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {OrderDetail !== null ? (
          <OrderDetailTimeline
            DataProduksi={DataProduksi}
            no_order={OrderDetail.no_order}
          />
        ) : null}
      </div>
      <AddDriver
        show={showDriver}
        handleClose={handleCloseDriver}
        onchange={(val) => {
          setDriverId(val);
          changeDriver(val, StatusDriver);
        }}
      />

      <AddWorkshop
        show={showWorkshop}
        handleClose={handleCloseWorkshop}
        onchange={(val) => {
          setWorkshopId(val);
          changeWorkshop(val, StatusWorkshop);
        }}
      />

      <ModalProduk
        show={showProduk}
        handleClose={handleCloseProduk}
        onchange={(val) => addOrder(val)}
      />

      <EditProduk
        show={showEditProduk}
        handleClose={handleCloseEditProduk}
        data={Data}
        onchange={(val) => {
          manageOrder(val);
          handleCloseEditProduk();
        }}
      />
      <ALertNotification
        show={ShowAlert}
        handleClose={handleCloseAlert}
        pesan={Pesan}
      />
    </div>
  );
}

export default OrderDetailPage;
