import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ModalBerlangganan,
  SideBar,
  TabOrder,
  TabOrderBerlangganan,
} from "../../components";
import { getOrderList } from "../../config/redux/action/orderAction";
import {
  Bayar,
  Driver,
  formatRupiah,
  Produksi,
  statusBayar,
  statusDriver,
  statusProduksi,
} from "../../utils";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox, faFileCsv, faSearch } from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";

function OrderPage() {
  const dispatch = useDispatch();
  const { OrderList, OrderListBerlangganan, OrderListBerlanggananRekap } =
    useSelector((state) => state.orderReducer);
  const [show, setShow] = useState(false);
  const [Data, setData] = useState(null);
  const [DataOrderCSV, setDataOrderCSV] = useState([]);
  const [DataPaymentCSV, setDataPaymentCSV] = useState([]);
  const [Update, setUpdate] = useState(1);
  const handleClose = () => setShow(false);
  const [Tabs, setTabs] = useState(1);
  const [dateRange, setDateRange] = useState([
    moment().startOf("month").toDate(),
    moment().endOf("month").toDate(),
  ]);
  const [startDate, endDate] = dateRange;
  const [StatusBayar, setStatusBayar] = useState(0);
  const [StatusDriver, setStatusDriver] = useState(0);
  const [StatusProduksi, setStatusProduksi] = useState(0);
  const [StatusOrder, setStatusOrder] = useState(1);
  const [Search, setSearch] = useState("");
  const navigate = useNavigate();
  let dataOrder = [];
  let dataPayment = [];

  useEffect(() => {
    if (dateRange[0] !== null && dateRange[1] !== null) {
      dispatch(
        getOrderList({
          url: "/order",
          type: "ORDER_LIST_CHANGE",
          dateRange,
          status: StatusBayar,
          status_driver: StatusDriver,
          status_produksi: StatusProduksi,
          status_order: StatusOrder,
          search: Search,
        }),
        dispatch
      );

      dispatch(
        getOrderList({
          url: "/order/berlangganan/rekap",
          type: "ORDER_LIST_BERLANGGANAN_REKAP_CHANGE",
          dateRange,
          status: StatusBayar,
          status_driver: StatusDriver,
          status_produksi: StatusProduksi,
          status_order: StatusOrder,
          search: Search,
        }),
        dispatch
      );

      let date = new Date();
      date.setDate(date.getDate() - 120);

      // console.log(date);

      dispatch(
        getOrderList({
          url: "/order/berlangganan",
          type: "ORDER_LIST_BERLANGGANAN_CHANGE",
          dateRange: [date, dateRange[1]],
          status: StatusBayar,
          status_order: StatusOrder,
          search: Search,
        }),
        dispatch
      );
    }
  }, [
    dateRange,
    StatusBayar,
    StatusDriver,
    StatusProduksi,
    StatusOrder,
    Update,
  ]);

  useEffect(() => {
    if (OrderList.length > 0) {
      OrderList.map((val) => {
        let nilai = 0;
        let diskon = val.diskon;

        let paketBerlangganan = 0;
        let paketSatuan = 0;
        let paketSetrika = 0;
        let cuciKiloan = 0;
        let cuciKarpet = 0;
        let setrikaKiloan = 0;
        let cuciDeluxeBag = 0;
        let cuciDeluxeKg = 0;
        let cuciLipat = 0;
        let satuanOrder = 0;
        let kg = 0;

        if (val.status !== 1) {
          if (val.order_detail.length > 0) {
            val.order_detail.map((detail) => {
              if (
                detail.produk.kategori_id === 1 &&
                detail.produk.kategori_detail_id === null
              ) {
                paketBerlangganan += 1;
              } else if (
                detail.produk.kategori_id === 2 &&
                detail.produk.kategori_detail_id === null
              ) {
                paketSatuan += 1;
              } else if (
                detail.produk.kategori_id === 3 &&
                detail.produk.kategori_detail_id === null
              ) {
                paketSetrika += 1;
              } else if (
                detail.produk.kategori_id === 4 &&
                detail.produk.kategori_detail_id === null
              ) {
                cuciKiloan += 1;
              } else if (
                detail.produk.kategori_id === 5 &&
                detail.produk.kategori_detail_id === null
              ) {
                cuciKarpet += 1;
              } else if (
                detail.produk.kategori_id === 6 &&
                detail.produk.kategori_detail_id === 7
              ) {
                cuciDeluxeBag += 1;
              } else if (
                detail.produk.kategori_id === 6 &&
                detail.produk.kategori_detail_id === 8
              ) {
                cuciDeluxeKg += 1;
              } else if (
                detail.produk.kategori_id === 7 &&
                detail.produk.kategori_detail_id === null
              ) {
                setrikaKiloan += 1;
              } else if (
                detail.produk.kategori_id === 8 &&
                detail.produk.kategori_detail_id === null
              ) {
                cuciLipat += 1;
              } else if (detail.produk.kategori_id === 10) {
                satuanOrder += 1;
              }

              kg += detail.kg;
              nilai += detail.nilai;
            });

            dataOrder.push({
              "No Order": val.no_order,
              "Nama Pelanggan": val.user.nama,
              Mitra: val.mitra_id !== 0 ? val.mitra.nama : "Online",
              "Paket Berlangganan": paketBerlangganan,
              "Paket Satuan": paketSatuan,
              "Paket Setrika": paketSetrika,
              "Cuci Kiloan": cuciKiloan,
              "Cuci Lipat": cuciLipat,
              "Cuci Karpet": cuciKarpet,
              "Setrika Kiloan": setrikaKiloan,
              "Kiloan Premium": cuciDeluxeBag,
              "Satuan Order": satuanOrder,
              "Deluxe Bag": cuciDeluxeBag,
              "Deluxe Kg": cuciDeluxeKg,
              KG: kg > 0 ? parseFloat(kg).toFixed(2).replace(/\./g, ",") : kg,
              Nilai: nilai,
              Diskon: diskon,
              Total: nilai + diskon,
              Point: val.point,
              "Tanggal Pesan": moment(val.tanggal_pesan).format("DD-MM-YY"),
              "Tanggal Selesai":
                val.tanggal_terima === null
                  ? "-"
                  : moment(val.tanggal_terima).format("DD-MM-YY"),
              Bayar:
                val.status === 2 || val.status === 3 || val.status === 5
                  ? "Belum Bayar"
                  : "Sudah Bayar",
              Alamat: val.alamat_detail
                .replace(/"/g, "'")
                .replace(/,/g, "-")
                .replace(/(?:\r\n|\r|\n)/g, "-"),
              Keterangan: `${
                val.keterangan !== null && val.keterangan !== ""
                  ? val.keterangan
                      .replace(/"/g, "'")
                      .replace(/,/g, "-")
                      .replace(/(?:\r\n|\r|\n)/g, "-")
                  : ""
              }`,
              Note: val.note
                .replace(/"/g, "'")
                .replace(/,/g, "-")
                .replace(/(?:\r\n|\r|\n)/g, "-"),
              Voucer:
                val.voucer !== null
                  ? `Menggunakan Kode (${val.voucers.kode})`
                  : "-",
            });
          }
        }
      });
    }

    if (OrderListBerlanggananRekap.length > 0) {
      OrderListBerlanggananRekap.map((val) => {
        let nilai = 0;
        let diskon = 0;

        let paketBerlangganan = 1;
        let paketSatuan = 0;
        let paketSetrika = 0;
        let cuciKiloan = 0;
        let cuciKarpet = 0;
        let setrikaKiloan = 0;
        let cuciDeluxeBag = 0;
        let cuciDeluxeKg = 0;
        let cuciLipat = 0;
        let satuanOrder = 0;
        let kg = val.kg;


        val.berlangganan.order.order_detail.length > 0 &&
          val.berlangganan.order.order_detail.map(
            (detail) => {
              nilai += detail.harga;
            }
          );

        dataOrder.push({
          "No Order": val.no_order,
          "Nama Pelanggan": val.user.nama,
          Mitra: val.mitra_id !== 0 ? val.mitra.nama : "Online",
          "Paket Berlangganan": paketBerlangganan,
          "Paket Satuan": paketSatuan,
          "Paket Setrika": paketSetrika,
          "Cuci Kiloan": cuciKiloan,
          "Cuci Lipat": cuciLipat,
          "Cuci Karpet": cuciKarpet,
          "Setrika Kiloan": setrikaKiloan,
          "Kiloan Premium": cuciDeluxeBag,
          "Satuan Order": satuanOrder,
          "Deluxe Bag": cuciDeluxeBag,
          "Deluxe Kg": cuciDeluxeKg,
          KG:
            kg !== 0 && kg !== null
              ? parseFloat(kg).toFixed(2).replace(/\./g, ",")
              : 0,
          Nilai: nilai / val.berlangganan.stok,
          Diskon: diskon,
          Total: nilai / val.berlangganan.stok + diskon,
          Point:val.berlangganan.order.point,
          "Tanggal Pesan": moment(val.tanggal_kirim).format("DD-MM-YY"),
          "Tanggal Selesai":
            val.tanggal_terima === null
              ? "-"
              : moment(val.tanggal_terima).format("DD-MM-YY"),
          Bayar: "Sudah Bayar",
          Alamat: val.alamat
            .replace(/"/g, "'")
            .replace(/,/g, "-")
            .replace(/(?:\r\n|\r|\n)/g, "-"),
          Keterangan: "-",
          Note: val.note
            .replace(/"/g, "'")
            .replace(/,/g, "-")
            .replace(/(?:\r\n|\r|\n)/g, "-"),
          Voucer: "-",
        });
      });
    }

    setDataOrderCSV(dataOrder);
    setDataPaymentCSV(dataPayment);
  }, [OrderList, OrderListBerlangganan, OrderListBerlanggananRekap]);

  const moveTab = (val) => {
    // navigate(val)
    window.location.href = val;
  };

  const moveTabBerlangganan = (val) => {
    setData(val);
    if (val.status === 2 || val.status === 3 || val.status === 5) {
      return setShow(true);
    }

    // navigate('/order/berlangganan/detail?no_order=' + val.no_order)
    window.location.href =
      "/order/berlangganan/detail?no_order=" + val.no_order;
  };

console.log(DataOrderCSV);

  return (
    <div>
      <SideBar />
      <div className="content">
        <div className="bg-white mx-1 py-2 px-3 shadow">
          <div
            className="border w-100 p-2 mb-3 d-flex justify-content-center flex-column"
            style={{ fontSize: "14px" }}
          >
            <div className="fw-bold fs-6 align-self-center">Filter</div>
            <div
              className="row d-flex justify-content-center"
              style={{ fontSize: "12px" }}
            >
              <div className="col-6 col-md-4 col-lg-3 mb-2">
                <div className="fw-bold">Tanggal</div>
                <ReactDatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    if (update[1] !== null) {
                      update[1] = moment(update[1])
                        .set({ h: 23, m: 59 })
                        .toDate();
                    }
                    setDateRange(update);
                  }}
                  withPortal
                  dateFormat="dd/MM/yyyy"
                  className="form-control form-control-sm"
                />
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-2">
                <div className="fw-bold">Status Bayar</div>
                {
                  <select
                    className="form-select form-select-sm"
                    value={StatusBayar}
                    onChange={(e) => setStatusBayar(e.target.value)}
                  >
                    <option key={0} value={0}>
                      {"Semua"}
                    </option>
                    {Bayar.length > 0 ? (
                      Bayar.map((val, key) => {
                        return (
                          <option key={val.value} value={val.value}>
                            {val.keterangan}
                          </option>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </select>
                }
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-2">
                <div className="fw-bold">Status Driver</div>
                {
                  <select
                    className="form-select form-select-sm"
                    value={StatusDriver}
                    onChange={(e) => setStatusDriver(e.target.value)}
                  >
                    <option key={0} value={0}>
                      {"Semua"}
                    </option>
                    {Driver.length > 0 ? (
                      Driver.map((val, key) => {
                        return (
                          <option key={val.value} value={val.value}>
                            {val.keterangan}
                          </option>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </select>
                }
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-2">
                <div className="fw-bold">Status Produksi</div>
                {
                  <select
                    className="form-select form-select-sm"
                    value={StatusProduksi}
                    onChange={(e) => setStatusProduksi(e.target.value)}
                  >
                    <option key={0} value={0}>
                      {"Semua"}
                    </option>
                    {Produksi.length > 0 ? (
                      Produksi.map((val, key) => {
                        return (
                          <option key={val.value} value={val.value}>
                            {val.keterangan}
                          </option>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </select>
                }
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-2">
                <div className="fw-bold">Status Order</div>
                {
                  <select
                    className="form-select form-select-sm"
                    value={StatusOrder}
                    onChange={(e) => setStatusOrder(e.target.value)}
                  >
                    <option value={1}>Semua</option>
                    <option value={2}>Berlangsung</option>
                    <option value={3}>Selesai</option>
                  </select>
                }
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-2">
                <div className="fw-bold">Cari Nama</div>
                <div className="d-flex">
                  <input
                    type={"text"}
                    className="form-control form-control-sm shadow-none me-1"
                    placeholder="cari berdasarkan nama dan alamat"
                    value={Search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => setUpdate(Update + 1)}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-6 mb-2 pt-3 d-flex justify-content-center justify-content-lg-end">
                <CSVLink
                  data={DataOrderCSV}
                  filename={`Rekap Invoice- ${moment().format("LLLL")}.csv`}
                  target="_blank"
                  className={`btn btn-sm btn-success`}
                  separator={";"}
                >
                  <div className="d-flex align-items-center">
                    <div className="me-2 text-white">Export Rekap Invoice</div>
                    <FontAwesomeIcon size="2x" icon={faFileCsv} className='text-white' />
                  </div>
                </CSVLink>
              </div>
            </div>
          </div>
          <nav>
            <div className="nav nav-tabs w-100 justify-content-around mb-2">
              <button
                className={`nav-link flex-grow-1 border-0 text-decoration-none text-dark d-flex justify-content-center align-items-center `}
                onClick={() => setTabs(1)}
                style={Tabs === 1 ? { backgroundColor: "#173c74" } : null}
              >
                <FontAwesomeIcon icon={faBox} className={`${Tabs === 1 ?'text-white': ''}`} />
                <div className={`px-2 ${Tabs === 1 ?'text-white': ''}`}>Order Biasa</div>
              </button>
              <button
                className={`nav-link flex-grow-1 border-0 text-decoration-none text-dark d-flex justify-content-center align-items-center `}
                onClick={() => setTabs(2)}
                style={Tabs === 2 ? { backgroundColor: "#173c74" } : null}
              >
                <FontAwesomeIcon icon={faBox} className={`${Tabs === 2 ?'text-white': ''}`} />
                <div className={`px-2 ${Tabs === 2 ?'text-white': ''}`}>Order Berlangganan</div>
              </button>
            </div>
          </nav>
          {Tabs === 1 ? (
            <TabOrder data={OrderList} onClick={(val) => moveTab(val)} />
          ) : (
            <TabOrderBerlangganan
              data={OrderListBerlangganan}
              onClick={(val) => moveTabBerlangganan(val)}
            />
          )}
        </div>
      </div>
      <ModalBerlangganan
        show={show}
        handleClose={() => handleClose()}
        data={Data}
        update={() => setUpdate(Update + 1)}
      />
    </div>
  );
}

export default OrderPage;
