import axios from "axios";
import { nyonyaaAdminEndpoint } from "../../../utils";

export const getProdukAllChange = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  axios
    .get(nyonyaaAdminEndpoint + payload.url, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      params: {
        user_id: session.data.id,
        search: payload.search,
        limit: payload.limit || 10,
      },
    })
    .then((res) => {
      if (res.status == 200) {
        dispatch({ type: payload.type, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const getProdukChange = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  axios
    .get(nyonyaaAdminEndpoint + payload.url, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      params: {
        user_id: session.data.id,
        kategori_id: payload.kategori_id,
        kategori_detail_id: payload.kategori_detail_id,
      },
    })
    .then((res) => {
      if (res.status == 200) {
        dispatch({ type: payload.type, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const saveProduk = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("user_id", session.data.id);
    data.append("kategori_id", payload.kategori_id);
    data.append("kategori_detail_id", payload.kategori_detail_id);
    data.append("nama", payload.nama);
    data.append("keterangan", payload.keterangan);
    data.append("kode", payload.kode);
    data.append("harga", payload.harga);
    data.append("aktif", payload.aktif);
    data.append("image", payload.gambar);
    data.append("kapasitas", payload.kapasitas);
    data.append("satuan", payload.satuan);
    data.append("stok", payload.stok);
    data.append("diskon", payload.diskon || 0);
    data.append("account_code", payload.account_code || 0);

    axios({
      method: "post",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const updateProduk = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("id", payload.id);
    data.append("user_id", session.data.id);
    data.append("kategori_id", payload.kategori_id);
    data.append("kategori_detail_id", payload.kategori_detail_id);
    data.append("nama", payload.nama);
    data.append("keterangan", payload.keterangan);
    data.append("kode", payload.kode);
    data.append("harga", payload.harga);
    data.append("aktif", payload.aktif);
    data.append("image", payload.gambar);
    data.append("kapasitas", payload.kapasitas);
    data.append("satuan", payload.satuan);
    data.append("stok", payload.stok);
    data.append("diskon", payload.diskon || 0);
    data.append("account_code", payload.account_code || 0);

    axios({
      method: "PATCH",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const deleteProduk = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("id", payload.id);

    axios({
      method: "DELETE",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data:{
        id: payload.id
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const getKategoriDetailChange = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  axios
    .get(nyonyaaAdminEndpoint + payload.url, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      params: {
        user_id: session.data.id,
        kategori_id: payload.kategori_id,
        kategori_detail_id: payload.kategori_detail_id,
      },
    })
    .then((res) => {
      if (res.status == 200) {
        dispatch({ type: payload.type, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};
