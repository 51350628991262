import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { nyonyaaAdminEndpoint } from "../../../utils";
import { Input } from "../../atoms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePromo,
  savePromo,
} from "../../../config/redux/action/promoAction";

function ModalPromo({ show, handleClose, data, update }) {
  const { pathPromo } = useSelector((state) => state.promoReducer);
  const [Id, setId] = useState(0);
  const [Judul, setJudul] = useState("");
  const [Gambar, setGambar] = useState("");
  const [GambarUrl, setGambarUrl] = useState("");
  const [Nama, setNama] = useState("");
  const [Status, setStatus] = useState(1);
  const [TanggalMulai, setTanggalMulai] = useState(moment().toDate());
  const [TanggalSelesai, setTanggalSelesai] = useState(moment().toDate());
  const [Deskripsi, setDeskripsi] = useState("");
  const [pathData, setPathData] = useState([]);
  const [pathValue, setPathValue] = useState(undefined);
  const dispatch = useDispatch();

  const dataStatus = [
    { id: 0, name: "Tidak Aktif" },
    { id: 1, name: "Aktif" },
  ];

  useEffect(() => {
    if (data !== null) {
      setId(data.id);
      setJudul("Edit Promo");
      setGambarUrl(
        data.gambar === null || data.gambar === ""
          ? ""
          : process.env.REACT_APP_STORAGE + data.gambar
      );
      setNama(data.nama);
      setStatus(data.aktif);
      setTanggalMulai(moment(data.tanggal_mulai).toDate());
      setTanggalSelesai(moment(data.tanggal_selesai).toDate());
      setDeskripsi(data.deskripsi);
      setPathValue(data.path);
    } else {
      setId(0);
      setJudul("Tambah Promo");
      setGambarUrl("");
      setNama("");
      setStatus(1);
      setTanggalMulai(moment().toDate());
      setTanggalSelesai(moment().toDate());
      setDeskripsi("");
      setPathValue(undefined)
    }
  }, [data]);

  useEffect(() => {
    if (pathPromo.length > 0) {
      setPathData(
        pathPromo.map((val) => {
          return {
            id: JSON.stringify(val),
            name: val.name,
          };
        })
      );
    }
  }, [pathPromo]);

  const imageChange = (val) => {
    setGambar(val[0]);
    setGambarUrl(URL.createObjectURL(val[0]));
  };

  const onSave = () => {
    dispatch(
      savePromo({
        url: "/promo",
        nama: Nama,
        gambar: Gambar,
        deskripsi: Deskripsi,
        tanggal_mulai: TanggalMulai,
        tanggal_selesai: TanggalSelesai,
        aktif: Status,
        id: Id,
        path: pathValue || pathData[0].id
      }),
      dispatch
    ).then(() => {
      update();
      handleClose();
    });
  };

  const onUpdate = () => {
    dispatch(
      savePromo({
        url: "/promo",
        nama: Nama,
        gambar: Gambar,
        deskripsi: Deskripsi,
        tanggal_mulai: TanggalMulai,
        tanggal_selesai: TanggalSelesai,
        aktif: Status,
        id: Id,
        path: pathValue || pathData[0].id
      }),
      dispatch
    ).then(() => {
      update();
      handleClose();
    });
  };

  const onDelete = () => {
    dispatch(
      deletePromo({
        url: "/promo",
        id: Id,
      }),
      dispatch
    ).then(() => {
      update();
      handleClose();
    });
  };

  return (
    <Modal show={show} onHide={handleClose} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>{Judul}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="m-3 text-center border rounded">
              {GambarUrl === "" ? (
                <FontAwesomeIcon
                  icon={faImage}
                  size="10x"
                  className="opacity-50"
                />
              ) : (
                <img src={GambarUrl} className="img-fluid" />
              )}
            </div>
            <div className="mx-3">
              <input
                type="file"
                className="form-control"
                onChange={(e) => imageChange(e.target.files)}
              />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="row mt-3 mt-lg-0">
              <div className="col-6">
                <Input
                  judul={"Nama"}
                  placeholder="Nama Voucer"
                  type={"text"}
                  value={Nama}
                  onChange={(e) =>
                    e.target.value.length < 20 ? setNama(e.target.value) : null
                  }
                />
              </div>
              <div className="col-6">
                <Input
                  judul={"Status"}
                  type={"select"}
                  data={dataStatus}
                  value={Status}
                  onChange={(e) => setStatus(e.target.value)}
                />
              </div>
              <div className="col-6">
                <Input
                  judul={"Tanggal Mulai"}
                  placeholder={"Tanggal Mulai"}
                  type={"reactdatetime"}
                  selected={TanggalMulai}
                  onChange={(date) => setTanggalMulai(date)}
                />
              </div>
              <div className="col-6">
                <Input
                  judul={"Tanggal Selesai"}
                  placeholder={"Tanggal Selesai"}
                  type={"reactdatetime"}
                  selected={TanggalSelesai}
                  onChange={(date) => setTanggalSelesai(date)}
                />
              </div>
              <div className="col-8">
                <Input
                  judul={"Menu Aplikasi"}
                  type={"select"}
                  data={pathData}
                  value={pathValue}
                  onChange={(e) => setPathValue(e.target.value)}
                />
              </div>
              <div className="col-12">
                <Input
                  judul={"Deskripsi"}
                  placeholder={"Deksripsi"}
                  type={"textarea"}
                  value={Deskripsi}
                  onChange={(e) => setDeskripsi(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        {data !== null ? (
          <div className="d-flex justify-content-between mx-3 mt-3">
            <button className="btn btn-danger" onClick={() => onDelete()}>
              Delete
            </button>
            <button className="btn btn-primary" onClick={() => onUpdate()}>
              Update
            </button>
          </div>
        ) : (
          <div className="d-flex justify-content-end mt-3">
            <button className="btn btn-primary me-2" onClick={() => onSave()}>
              Simpan
            </button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModalPromo;
