import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { nyonyaaAdminEndpoint } from '../../../utils'

function CuciTab({ data, onClick }) {
    return (
        <div className='table-responsive'>
            <table className='table table-bordered' style={{ fontSize: '12px' }}>
                <thead>
                    <tr>
                        <th>Profile</th>
                        <th>Nama</th>
                        <th>Email</th>
                        <th>No telp</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (data.length > 0) ?
                            data.map(val => {
                                return (
                                    <tr key={val.user.id}>
                                        <td align='center'>
                                            {
                                                (val.user.gambar === null) ?
                                                    <FontAwesomeIcon icon={faUserCircle}
                                                        className=' opacity-50'
                                                        style={{ height: 50, width: 50 }} />
                                                    :
                                                    <img src={`${process.env.REACT_APP_STORAGE}${val.user.gambar}`}
                                                        className='rounded-circle'
                                                        style={{ height: 50, width: 50 }} />

                                            }
                                        </td>
                                        <td>{val.user.nama}</td>
                                        <td>{val.user.email}</td>
                                        <td>{val.user.notelp}</td>
                                        <td align='center'>
                                            <button
                                                className='btn btn-danger'
                                                onClick={() => onClick(val)}
                                            >
                                                Hapus
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={4} align={'center'}>User tidak ditemukan</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default CuciTab