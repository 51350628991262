import {
  faEye,
  faEyeSlash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ALertNotification } from "../../components";
import { loginSession } from "../../config/redux/action/sessionLogin";

function LoginPage() {
  const { BtnLoginDisable } = useSelector((state) => state.globalReducer);
  const { ResponseLogin } = useSelector((state) => state.sessionReducer);
  const [ShowPassword, setShowPassword] = useState(false);
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [Pesan, setPesan] = useState("");
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();

  const LoginRequest = () => {
    dispatch({ type: "BTN_LOGIN_DISABLE", payload: true });
    setTimeout(() => {
      dispatch(loginSession({ Username, Password }), dispatch);
    }, 1000);
  };

  return (
    <div className="w-100 vh-100 d-flex justify-content-center align-item-center flex-column px-3 bg-light">
      <div className="card col-12 col-md-6 col-lg-4 align-self-center shadow">
        <div className="card-header bg-primary fw-bold text-white">
          Login Admin Nyonyaa
        </div>
        <div className="card-body">
          {ResponseLogin.length > 0 ? (
            <div
              className="align-self-center text-danger text-center"
              style={{ whiteSpace: "pre-line" }}
            >
              {ResponseLogin}
            </div>
          ) : null}
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="name@example.com"
              value={Username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <div className="d-flex justif-conten-center align-items-baseline">
              <input
                type={ShowPassword ? `text` : `password`}
                className="form-control "
                id="password"
                placeholder="sttt!"
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && LoginRequest()}
              />
              <div
                className="position-relative"
                onClick={() =>
                  ShowPassword ? setShowPassword(false) : setShowPassword(true)
                }
              >
                {ShowPassword ? (
                  <FontAwesomeIcon
                    icon={faEye}
                    className="position-absolute bottom-0 end-0 mx-3"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    className="position-absolute bottom-0 end-0 mx-3"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="pt-3 w-100 d-flex justify-content-center align-items-center flex-column">
            <button
              className="btn btn-primary btn-sm w-50 text-center"
              onClick={() => LoginRequest()}
              disabled={BtnLoginDisable}
            >
              {BtnLoginDisable ? (
                <FontAwesomeIcon icon={faSpinner} className="spinner" />
              ) : (
                `Sign In`
              )}
            </button>
          </div>
        </div>
      </div>
      <ALertNotification
        show={show}
        handleClose={() => handleClose()}
        pesan={Pesan}
      />
    </div>
  );
}

export default LoginPage;
