const initialState = {
  PromoList: [],
  pathPromo: [],
};

const promoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PROMO_LIST":
      return {
        ...state,
        PromoList: action.payload,
      };
    case "PROMO_PATH_LIST":
      return {
        ...state,
        pathPromo: action.payload,
      };
    default:
      return state;
  }
};

export default promoReducer;
