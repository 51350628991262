import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalPromo, SideBar } from "../../components";
import { getDataAPI } from "../../config/redux/action/newRequestAction";
import { getPromoList } from "../../config/redux/action/promoAction";
import { nyonyaaAdminEndpoint } from "../../utils";

function PromoPage() {
  const { PromoList, pathPromo } = useSelector((state) => state.promoReducer);
  const [show, setShow] = useState(false);
  const [Data, setData] = useState(null);
  const handleClose = () => setShow(false);
  const [Update, setUpdate] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getPromoList({
        url: "/promo",
        type: "PROMO_LIST",
      }),
      dispatch
    );

    dispatch(
      getDataAPI({
        url: "/promo/path",
        type: "PROMO_PATH_LIST",
      })
    );
  }, [Update]);

  const statusPromo = (status) => {
    if (status === 1) {
      return "Aktif";
    } else {
      return "Tidak Aktif";
    }
  };

  return (
    <div>
      <SideBar />
      <div className="content">
        <div className="mx-2 px-2 py-1 bg-white rounded shadow">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-sm btn-primary me-2 mb-2 mt-3"
                onClick={() => {
                  setData(null);
                  setShow(true);
                }}
              >
                Tambah Promo
              </button>
            </div>
            <div className="table-responsive">
              <table
                className="table table-bordered"
                style={{ fontSize: "12px" }}
              >
                <thead>
                  <tr>
                    <th>Gambar</th>
                    <th>Nama</th>
                    <th>Deskripsi</th>
                    <th>Tanggal</th>
                    <th>Aktif</th>
                  </tr>
                </thead>
                <tbody>
                  {PromoList.length > 0 ? (
                    PromoList.map((val) => {
                      return (
                        <tr
                          className="hover"
                          key={val.id}
                          onClick={() => {
                            setData(val);
                            setShow(true);
                          }}
                        >
                          <td align="center">
                            {val.gambar === null || val.gambar === "" ? (
                              <FontAwesomeIcon
                                icon={faImage}
                                className=" opacity-50"
                                style={{ height: 50, width: 120 }}
                              />
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_STORAGE}${val.gambar}`}
                                className=""
                                style={{ height: 50, width: 120 }}
                              />
                            )}
                          </td>
                          <td>{val.nama}</td>
                          <td className="ws-preline">{val.deskripsi}</td>
                          <td className="ws-preline" align="center">
                            {new Date(val.tanggal_mulai).toLocaleString() +
                              " \n s/d \n" +
                              new Date(val.tanggal_selesai).toLocaleString()}
                          </td>
                          <td>{statusPromo(val.aktif)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td align="center" colSpan={5}>
                        Data tidak ditemukan
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {pathPromo.length > 0 && (
        <ModalPromo
          show={show}
          handleClose={handleClose}
          data={Data}
          update={() => setUpdate(Update + 1)}
        />
      )}
    </div>
  );
}

export default PromoPage;
