import { faStar, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { nyonyaaAdminEndpoint } from "../../../utils";

function CardRating({ data, ...rest }) {
  return (
    <div
      className="w-100 rounded py-1 px-2 rounded d-flex hover mb-2 border"
      {...rest}
    >
      <div className="tex-exter" style={{ width: 100, height: 100 }}>
        {data.user.gambar !== null ? (
          <img
            src={process.env.REACT_APP_STORAGE + data.user.gambar}
            alt={data.user.nama}
            className="img-fluid rounded-circle border border-1 border-info"
          />
        ) : (
          <FontAwesomeIcon
            icon={faUserCircle}
            style={{ width: 100, height: 100 }}
          />
        )}
      </div>
      <div className="d-flex flex-column ms-3">
        <div className="fw-bold">{data.user.nama}</div>
        <div className="d-flex">
          <FontAwesomeIcon
            icon={faStar}
            className="align-self-center text-success"
          />
          <div className="ms-2 align-self-center">
            {parseFloat(data.total_rating).toFixed(2)} / ({data.total_order})
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardRating;
