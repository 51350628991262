import axios from "axios";
import { nyonyaaAdminEndpoint } from "../../../utils";

export const getOrderList = (payload) => async (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  axios
    .get(nyonyaaAdminEndpoint + payload.url, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      params: {
        user_id: session.data.id,
        dateRange: payload.dateRange,
        status: payload.status,
        status_driver: payload.status_driver,
        status_produksi: payload.status_produksi,
        status_order: payload.status_order,
        search: payload.search || "",
      },
    })
    .then((res) => {
      if (res.status == 200) {
        dispatch({ type: payload.type, payload: res.data.data });
      }
    })
    .catch((err) => console.log(err));
};

export const ChangeStatus = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    if (payload.title === "driver") {
      data.append("driver_id", payload.driver_id);
      data.append("status_driver", payload.status_driver);
    } else if (payload.title === "workshop") {
      data.append("workshop_id", payload.workshop_id);
      data.append("status_produksi", payload.status_workshop);
    } else if (payload.title === "bayar") {
      data.append("status", payload.status);
    }
    data.append("id", payload.id);
    data.append("user_id", session.data.id);

    axios({
      method: "POST",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        // return console.log(res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const AddOrderNonBeralangganan = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("user_id", session.data.id);
    data.append("no_order", payload.no_order);
    data.append("produk_id", payload.produk_id);
    data.append("qty", payload.qty);
    data.append("satuan", payload.satuan);
    data.append("harga", payload.harga);
    data.append("nilai", payload.nilai);
    data.append("potong", payload.potong);

    axios({
      method: "POST",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        // return console.log(res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const manageOrderNonBeralangganan = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("user_id", session.data.id);
    data.append("id", payload.id);
    data.append("qty", payload.qty);
    data.append("harga", payload.harga);
    data.append("nilai", payload.nilai);
    data.append("potong", payload.potong);
    data.append("kg", payload.kg);

    axios({
      method: "POST",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        // return console.log(res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const deleteOrderNonBeralangganan = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("user_id", session.data.id);
    data.append("id", payload.id);

    axios({
      method: "DELETE",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const selesaiOrderTanggal = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("no_order", payload.no_order);
    data.append("tanggal_selesai", payload.tanggal_selesai);
    data.append("title", payload.title);
    data.append("id", payload.id || 0);

    axios({
      method: "POST",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const selesaiOrderBerlanggananTanggal =
  (payload) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      const session = JSON.parse(localStorage.getItem("session_user"));
      let data = new FormData();
      data.append("no_order", payload.no_order);
      data.append("status", payload.status);

      axios({
        method: "POST",
        url: nyonyaaAdminEndpoint + payload.url,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${session.data.jwt}`,
        },
        data,
      })
        .then((res) => {
          // console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
        });
    });
  };

export const createOrder = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("kode_user", session.data.kode);
    data.append("jenis_order", payload.jenis_order);
    data.append("tanggal_pesan", payload.tanggal_pesan);
    data.append("jam_kirim", payload.jam_kirim);
    data.append("alamat_detail", payload.alamat_detail);
    data.append("titik_jemput", payload.titik_jemput);
    data.append("alamat_kirim", payload.alamat_kirim);
    data.append("titik_kirim", payload.titik_kirim);
    data.append("note", payload.note);
    data.append("keterangan", payload.keterangan);
    data.append("mitra_id", payload.mitra_id);

    axios({
      method: "POST",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        // return console.log(res);

        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const addKeteranganOrder = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("no_order", payload.no_order);
    data.append("keterangan", payload.keterangan);

    axios({
      method: "POST",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        // return console.log(res);

        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const addPembayaranTanggalOrder = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("no_order", payload.no_order);
    data.append("tanggal_bayar", payload.tanggal_bayar);

    axios({
      method: "POST",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        // return console.log(res);

        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const updateAccountJurnal = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("no_order", payload.no_order);
    data.append("account_jurnal", payload.account_jurnal);

    axios({
      method: "POST",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const updateBerlanggananKg = (payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const session = JSON.parse(localStorage.getItem("session_user"));
    let data = new FormData();
    data.append("id", payload.id);
    data.append("kg", payload.kg);

    axios({
      method: "POST",
      url: nyonyaaAdminEndpoint + payload.url,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${session.data.jwt}`,
      },
      data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};
