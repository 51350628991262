import React, { useEffect, useState } from "react";
import {
  Account,
  formatRupiah,
  jenisOrder,
  statusAccount,
} from "../../../utils";
import DataTable from "react-data-table-component";
import moment from "moment";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import ALertNotification from "../AlertNotification";

function ReportData({ data }) {
  const [Data, setData] = useState([]);
  const [Total, setTotal] = useState(0);
  const [DataOrderCSV, setDataOrderCSV] = useState([]);
  const [recapProductData, setRecapProductData] = useState([]);
  let dataOrder = [];
  let dataProdcutRecap = [];
  const [show, setShow] = useState(false);
  const [Pesan, setPesan] = useState("");
  const handleClose = () => setShow(false);

  useEffect(() => {
    if (data.length > 0) {
      let datas = [];
      let nilai = 0;
      let total = 0;
      data.map((val) => {
        nilai = 0;
        val.order_detail.map((result) => {
          nilai += result.nilai;
        });

        total += nilai + val.diskon + val.biaya_aplikasi;

        let diskon = val.diskon;

        datas.push({
          no_order: val.no_order,
          tanggal: moment(new Date(val.createdAt)).format("LLL"),
          nama: val.user.nama,
          jenis_order: jenisOrder(val.jenis_order),
          bayar: val.payment_accept?.nama || 'Default Payment' ,
          total: formatRupiah(
            String(nilai + val.diskon + val.biaya_aplikasi + val.ongkos),
            "Rp. "
          ),
          status:
            val.status === 3 || val.status === 5
              ? "Belum Bayar"
              : "Sudah Bayar",
          tanggal_bayar:
            val.tanggal_bayar !== null
              ? moment(new Date(val.tanggal_bayar)).format("LLL")
              : "-",
          val,
        });

        if (val.order_detail.length > 0) {
          val.order_detail.map((detail) => {
            dataOrder.push({
              "*Customer": "Konsumen Aplikasi",
              Email: val.user.email,
              "*InvoiceDate": moment(new Date(val.createdAt)).format("yyyy-M-D hh:mm:ss"),
              "*DueDate": moment(new Date(val.createdAt)).format("yyyy-M-D hh:mm:ss"),
              "*InvoiceNumber": val.no_order,
              "*ProductName": detail.produk.nama,
              "*Quantity": detail.qty,
              "*UnitPrice": detail.harga,
              Description: detail.produk.keterangan
                .replace(/"/g, "'")
                .replace(/,/g, "-"),
              WitholdingAmount:
                val.diskon === 0 ? "" : (val.diskon + val.ongkos) * -1,
              WitholdingAccountCode: val.diskon === 0 ? "" : "4-40100",
              Paid: "No",
              "Paid To Account Code": "4-40100",
              "Payment Method": "online",
              Tag: val.status === 3 || val.status === 4 ? "Online" : "COD",
              Memo: `${val.user.nama} - ${
                val.keterangan !== null && val.keterangan !== ""
                  ? val.keterangan
                      .replace(/"/g, "'")
                      .replace(/,/g, "-")
                      .replace(/(?:\r\n|\r|\n)/g, "-")
                  : ""
              }`,
              Message:
                val.voucer !== null
                  ? `Menggunakan Kode (${val.voucers.kode})`
                  : "-",
            });

            dataProdcutRecap.push({
              "No Order": val.no_order,
              "Order Date": moment(new Date(val.createdAt)).format(
                "yyyy/M/D"
              ),
              "Payment Date":
                val.tanggal_bayar === null
                  ? "-"
                  : moment(new Date(val.tanggal_bayar)).format("yyyy/M/D"),
              Payment:
                val.status === 4 || val.status === 6
                  ? "Sudah Bayar"
                  : "Belum Bayar",
              // Payment: statusBayar(val.status),
              "Tipe Order": jenisOrder(val.jenis_order),
              "Name Product": detail.produk.nama,
              Price: detail.harga,
              Quantity: parseFloat(detail.qty),
              "Total Price": detail.nilai,
            });
          });

          
        }
      });
      setTotal(total);
      setDataOrderCSV(dataOrder);
      setRecapProductData(dataProdcutRecap);
      setData(datas);
    } else {
      setData([]);
      setDataOrderCSV([]);
      setRecapProductData([]);
      setTotal(0);
    }
  }, [data]);

  const column = [
    {
      name: "No Order",
      selector: (row) => row.no_order,
      sortable: true,
    },
    {
      name: "Tanggal",
      selector: (row) => row.tanggal,
      sortable: true,
    },
    {
      name: "Nama",
      selector: (row) => row.nama,
    },
    {
      name: "Jenis Order",
      selector: (row) => row.jenis_order,
    },
    {
      name: "Pembayaran",
      selector: (row) => row.bayar,
    },
    {
      name: "Status Bayar",
      selector: (row) => row.status,
      conditionalCellStyles: [
        {
          when: (row) => row.status === "Belum Bayar",
          style: {
            backgroundColor: "#D8274E",
            color: "white",
          },
        },
        {
          when: (row) => row.status === "Sudah Bayar",
          style: {
            backgroundColor: "#33D49D",
            color: "black",
          },
        },
      ],
    },
    {
      name: "Tanggal Bayar",
      selector: (row) => row.tanggal_bayar,
    },
    {
      name: "Total",
      selector: (row) => row.total,
      right: true,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Tampilkan ",
    rangeSeparatorText: " dari ",
  };

  const ExpandedComponent = (data) => {
    return (
      <pre className="container-fluid pt-2 px-3 pb-2 mb-0">
        <div className="row border bg-light">
          <div className="col-4">
            <div className="fw-bold">Keterangan</div>
            <div>
              {data.data.val.keterangan === "" ||
              data.data.val.keterangan === null
                ? "- "
                : data.data.val.keterangan}
            </div>
          </div>
          <div className="col-4">
            <div className="fw-bold">Voucher</div>
            <div>
              {data.data.val.voucers === null
                ? "-"
                : data.data.val.voucers.nama +
                  " (" +
                  formatRupiah(String(data.data.val.diskon), "Rp. ") +
                  ")"}
            </div>
          </div>
          <div className="col-4">
            <div className="fw-bold">Akun</div>
            <div>
              {data.data.val.account_jurnal === null
                ? "-"
                : statusAccount(data.data.val.account_jurnal)}
            </div>
          </div>
        </div>
      </pre>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col-12 col-sm-6 d-flex ">
          <CSVLink
            data={DataOrderCSV}
            filename={`Sales Invoice - ${moment().format("LLLL")}.csv`}
            target="_blank"
            className={`btn btn-sm btn-success d-flex align-content-center align-items-center align-baseline me-2`}
            enclosingCharacter={``}
          >
            <div className="me-1 fw-bold">Export Sales Invoice</div>
            <FontAwesomeIcon size="2x" className="text-white" icon={faFileCsv} />
          </CSVLink>
          <CSVLink
            data={recapProductData}
            filename={`Recap Product - ${moment().format("LLLL")}.csv`}
            target="_blank"
            className={`btn btn-sm btn-success d-flex align-content-center align-items-center align-baseline me-2`}
            enclosingCharacter={``}
          >
            <div className="me-1 fw-bold">Export Detail Product</div>
            <FontAwesomeIcon size="2x" className="text-white" icon={faFileCsv} />
          </CSVLink>
        </div>
        <div
          className="col-12 col-sm-6 d-flex justify-content-end align-self-center"
          style={{ fontSize: 16 }}
        >
          <div>Total : </div>
          <div className="fw-bold text-danger ms-1">
            {formatRupiah(String(Total), "Rp. ")}
          </div>
        </div>
      </div>
      <DataTable
        columns={column}
        data={Data}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        customStyles={{
          table: {
            style: {
              border: "solid 0.1px",
              borderColor: "#b5aab3",
            },
          },

          headRow: {
            style: {
              minHeight: "40px",
              fontSize: "13px",
              fontWeight: "bold",
              color: "rgba(51, 51, 51, 0.9)",
            },
          },
          rows: {
            style: {
              fontSize: "12px",
              minHeight: "35px",
              ":hover": {
                backgroundColor: "#33d49d50",
              },
              cursor: "pointer",
            },
          },
        }}
        onRowClicked={(data) => {
          if (data.val.jenis_order === "berlangganan") {
            if (data.val.status === 4 || data.val.status === 6) {
              window.open(
                "/order/berlangganan/detail?no_order=" + data.val.no_order,
                "_blank"
              );

              // window.location.href = "/order/berlangganan/detail?no_order=" + data.val.no_order
              // navigate(
              //   "/order/berlangganan/detail?no_order=" + data.val.no_order
              // );
            } else {
              setShow(true);
              return setPesan("No Order ini belum melakukan pembayaran");
            }
          } else {
            window.open(
              "/order/detail?no_order=" + data.val.no_order,
              "_blank"
            );
            // window.location.href = "/order/detail?no_order=" + data.val.no_order
            // navigate("/order/detail?no_order=" + data.val.no_order);
          }
        }}
      />

      <ALertNotification show={show} handleClose={handleClose} pesan={Pesan} />
    </div>
  );
}

export default ReportData;
