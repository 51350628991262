import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import moment from 'moment'
import { getOrderChartList } from '../../../config/redux/action/chartAction';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '../../atoms';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    plugins: {
        title: {
            display: true,
            text: 'Penjualan Periode',
        },
    },
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};


function OrderPeriode() {
    const { OrderChartList } = useSelector(state => state.chartReducer)
    const [BarData, setBarData] = useState({
        labels: [],
        datasets: [{
            label: "Order Periode",
            data: [],
            backgroundColor: "rgba(255, 0, 144, 0.7)"
        }]
    })
    const [Time, setTime] = useState('week')
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getOrderChartList({
            url: `/chart/order?time=${Time}`,
            type: 'ORDER_CHART_LIST'
        }), dispatch)

    }, [Time])

    useEffect(() => {
        if (OrderChartList.length > 0) {
            let labels = []
            let datas = []


            OrderChartList.map((val) => {
                labels.push(moment(val.tanggal).format('DD-MMM-YYYY'))
                datas.push(val.count)
            })

            setBarData({
                labels: labels.reverse(),
                datasets: [{
                    label: "Order Periode",
                    data: datas.reverse(),
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.8)',
                        'rgba(54, 162, 235, 0.8)',
                        'rgba(255, 206, 86, 0.8)',
                        'rgba(75, 192, 192, 0.8)',
                        'rgba(153, 102, 255, 0.8)',
                    ],
                }]
            })
        }
    }, [OrderChartList])


    const timeData = [
        { id: 'day', name: 'Hari' },
        { id: 'week', name: 'Minggu' },
        { id: 'month', name: 'Bulan' },
    ]

    return (
        <div className='w-100'>
            {
                (OrderChartList.length > 0) ?
                    <div className='d-flex bg-white px-2 py-1 rounded shadow overflow-auto'>
                        <div className='px-1' style={{ flex: 4 }} >
                            <Bar
                                options={options}
                                data={BarData}
                                style={{minHeight:200}}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Input judul={'Pilih Periode'}
                                size='12px'
                                type={'select'}
                                data={timeData}
                                value={Time}
                                onChange={e => setTime(e.target.value)}
                                style={{
                                    fontSize:'12px'
                                }}
                                className={'form-select form-select-sm'}
                            />
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default OrderPeriode