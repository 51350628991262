import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

function ALertNotification({ show, handleClose, pesan }) {


    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body className='text-center'>{pesan}</Modal.Body>
            {/* <Modal.Footer> */}
                <button className='bg-yellow-nyonyaa border-0 text-dark mx-5 p-1 mb-3 hover' onClick={handleClose}>
                    Ok
                </button>
            {/* </Modal.Footer> */}
        </Modal>
    )
}

export default ALertNotification;
