import React from "react";

function LoadingState({ height, shadow }) {
  return (
    <div
      className={`d-flex w-100 justify-content-center align-items-center fade-page bg-white rounded-3 ${shadow && 'shadow'}`}
      style={{ height: height || 700 }}
    >
      <i
        className={`fas fa-circle-notch fa-spin ${
          height > 100 || (height === undefined && "fa-2x")
        }`}
      ></i>
    </div>
  );
}

export default LoadingState;
