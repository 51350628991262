import { faImage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Input } from '../../atoms'
import { formatNormali, formatRupiah, nyonyaaAdminEndpoint } from '../../../utils'
import { managePaymentAcceptList } from '../../../config/redux/action/settingReducer'

function SettingPaymentAcceptModal({ show, handleClose, data, update }) {
    const [Id, setId] = useState(0)
    const [Judul, setJudul] = useState('')
    const [Nama, setNama] = useState('')
    const [Kode, setKode] = useState('')
    const [Deskripsi, setDeskripsi] = useState('')
    const [Gambar, setGambar] = useState('')
    const [GambarUrl, setGambarUrl] = useState('')
    const [MinOrder, setMinOrder] = useState('')
    const [Fee, setFee] = useState('')
    const [Tipe, setTipe] = useState(5)
    const dispatch = useDispatch()

    const tipeData = [
        { id: 5, name: 'COD' },
        { id: 3, name: 'Online' },
    ]

    useEffect(() => {
        if (data !== null) {
            setId(data.id)
            setJudul('Edit')
            setNama(data.nama)
            setKode(data.kode)
            setDeskripsi(data.deskripsi)
            setGambarUrl((data.gambar === null || data.gambar === '') ? '' : process.env.REACT_APP_STORAGE + data.gambar)
            setMinOrder(formatRupiah(String(data.min_order), 'Rp. '))
            setFee(formatRupiah(String(data.fee), 'Rp. '))
            setTipe(data.tipe)
        } else {
            setId(0)
            setJudul('Tambah')
            setNama('')
            setKode('')
            setDeskripsi('')
            setGambarUrl('')
            setMinOrder('Rp .0')
            setFee('Rp .0')
            setTipe(5)
        }
    }, [data])

    const imageChange = (val) => {
        setGambar(val[0])
        setGambarUrl(URL.createObjectURL(val[0]))
    }

    const onManagaPayment = (val) => {
        dispatch(managePaymentAcceptList({
            url: '/setting/payment',
            title: val,
            id: Id,
            gambar: Gambar,
            kode: Kode,
            nama: Nama,
            deskripsi: Deskripsi,
            min_order: formatNormali(MinOrder),
            fee: formatNormali(Fee),
            tipe: Tipe,
        }), dispatch)
            .then(() => {
                handleClose()
                update()
            })
    }

    return (
        <Modal show={show} onHide={handleClose} size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title>{Judul}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex'>
                    <div className='w-50'>
                        <div className='m-3 text-center border rounded'>
                            {
                                (GambarUrl === '') ?
                                    <FontAwesomeIcon icon={faImage} size='10x' className='opacity-50' />
                                    :
                                    <img src={GambarUrl} className='img-fluid' />
                            }
                        </div>
                        <div className='mx-3'>
                            <input type='file' className='form-control' onChange={e => imageChange(e.target.files)} />
                        </div>
                    </div>
                    <div className='w-50'>
                        <div className='row'>
                            <div className='col-6'>
                                <Input judul={'Nama'} placeholder='Nama Payment'
                                    type={'text'}
                                    value={Nama}
                                    onChange={e => (e.target.value.length < 20) ? setNama(e.target.value) : null}
                                />
                            </div>
                            <div className='col-6'>
                                <Input judul={'Kode'} placeholder='Kode Payment'
                                    type={'text'}
                                    value={Kode}
                                    onChange={e => (e.target.value.length < 20) ? setKode(e.target.value) : null}
                                />
                            </div>
                            <div className='col-12'>
                                <Input judul={'Deskripsi'} placeholder='Deskripsi'
                                    type={'textarea'}
                                    value={Deskripsi}
                                    onChange={e => setDeskripsi(e.target.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <Input judul={'Min Harga'} type='text' placeholder={'Rp 10.000'}
                                    value={MinOrder}
                                    onChange={(e) => setMinOrder(formatRupiah(String(e.target.value), 'Rp .'))}
                                />
                            </div>
                            <div className='col-6'>
                                <Input judul={'Fee'} type='text' placeholder={'Rp 10.000'}
                                    value={Fee}
                                    onChange={(e) => setFee(formatRupiah(String(e.target.value), 'Rp .'))}
                                />
                            </div>
                            <div className='col-6'>
                                <Input judul={'Tipe'}
                                    type={'select'}
                                    data={tipeData}
                                    value={Tipe}
                                    onChange={(e) => setTipe(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex justify-content-end'>
                    {
                        (data !== null) ?
                            <button className='btn btn-sm btn-success'
                                onClick={() => onManagaPayment('save')}
                            >Edit</button>
                            :
                            <button className='btn btn-sm btn-primary'
                                onClick={() => onManagaPayment('save')}
                            >Tambah</button>

                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SettingPaymentAcceptModal