const initialState = {
    ListDriver: []
}

const driverReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LIST_DRIVER_CHANGE":
            return {
                ...state,
                ListDriver: action.payload
            }
        default:
            return state
    }

}

export default driverReducer