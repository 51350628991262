import { Modal, Tabs } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { Input } from "../../atoms";
import moment from "moment";
import {
  formatNormali,
  formatRupiah,
  nyonyaaAdminEndpoint,
  Order as JenisOrderStatus,
} from "../../../utils";
import { useDispatch } from "react-redux";
import { saveVoucer } from "../../../config/redux/action/voucerAction";

function ModalVoucer({ show, handleClose, data, update, kategoriUserData }) {
  const [Id, setId] = useState(0);
  const [Judul, setJudul] = useState("");
  const [Gambar, setGambar] = useState("");
  const [GambarUrl, setGambarUrl] = useState("");
  const [Nama, setNama] = useState("");
  const [Kode, setKode] = useState("");
  const [Status, setStatus] = useState(1);
  const [Stok, setStok] = useState(100);
  const [TanggalMulai, setTanggalMulai] = useState(moment().toDate());
  const [TanggalSelesai, setTanggalSelesai] = useState(moment().toDate());
  const [Deskripsi, setDeskripsi] = useState("");
  const [Harga, setHarga] = useState("");
  const [MinHarga, setMinHarga] = useState("");
  const [MaxHarga, setMaxHarga] = useState("");
  const [Persen, setPersen] = useState("");
  const [Order, setOrder] = useState(1);
  const [JenisOrder, setJenisOrder] = useState("semua");
  const [KategoriUser, setKategoriUser] = useState(1);
  const [Tab, setTab] = useState(1);
  const dispatch = useDispatch();

  const dataStatus = [
    { id: 0, name: "Tidak Aktif" },
    { id: 1, name: "Aktif" },
    { id: 2, name: "Aktif (Private)" },
  ];

  const dataJenisUser = () => {
    let data = [
      {
        id: "semua",
        name: "Semua",
      },
    ];
    JenisOrderStatus.map((val) => {
      data.push({
        id: val.value,
        name: val.keterangan,
      });
    });
    return data;
  };

  const dataKategoriUser = () => {
    let data = [];
    kategoriUserData.map((val) => {
      data.push({
        id: val.id,
        name: val.nama,
      });
    });
    return data;
  };

  useEffect(() => {
    if (data !== null) {
      setId(data.id);
      setJudul("Edit Voucer");
      setGambarUrl(
        data.gambar === null || data.gambar === ""
          ? ""
          : process.env.REACT_APP_STORAGE + data.gambar
      );
      setNama(data.nama);
      setKode(data.kode);
      setStatus(data.aktif);
      setStok(data.stok);
      setTanggalMulai(moment(data.tanggal_mulai).toDate());
      setTanggalSelesai(moment(data.tanggal_selesai).toDate());
      setDeskripsi(data.deskripsi);
      setHarga(formatRupiah(String(data.harga), "Rp. "));
      setMinHarga(formatRupiah(String(data.min_harga), "Rp. "));
      setMaxHarga(formatRupiah(String(data.max_harga), "Rp. "));
      setPersen(data.persen);
      setTab(data.harga === 0 ? 1 : 2);
      setOrder(data.order);
      setJenisOrder(data.jenis_order);
      setKategoriUser(data.kategori_user_id);
    } else {
      setId(0);
      setJudul("Tambah Voucer");
      setGambarUrl("");
      setNama("");
      setKode("");
      setStatus(1);
      setStok(0);
      setTanggalMulai(moment().toDate());
      setTanggalSelesai(moment().toDate());
      setDeskripsi("");
      setHarga("");
      setMinHarga("");
      setMaxHarga("");
      setPersen("");
      setTab(1);
      setOrder(1);
      setJenisOrder("semua");
      setKategoriUser(1);
    }
  }, [data]);

  const imageChange = (val) => {
    setGambar(val[0]);
    setGambarUrl(URL.createObjectURL(val[0]));
  };

  const onSave = () => {
    dispatch(
      saveVoucer({
        url: "/voucer",
        id: Id,
        nama: Nama,
        gambar: Gambar,
        deskripsi: Deskripsi,
        kode: Kode,
        harga: Tab === 2 ? formatNormali(Harga) : 0,
        persen: Tab === 1 ? Persen : 0,
        min_harga: formatNormali(MinHarga),
        max_harga: Tab === 1 ? formatNormali(MaxHarga) : 0,
        tanggal_mulai: TanggalMulai,
        tanggal_selesai: TanggalSelesai,
        stok: Stok,
        aktif: Status,
        jenis_order: JenisOrder,
        order: Order,
        kategori_user_id: KategoriUser,
      }),
      dispatch
    ).then(() => {
      update();
      handleClose();
    });
  };

  const onUpdate = () => {
    dispatch(
      saveVoucer({
        url: "/voucer",
        id: Id,
        nama: Nama,
        gambar: Gambar,
        deskripsi: Deskripsi,
        kode: Kode,
        harga: Tab === 2 ? formatNormali(Harga) : 0,
        persen: Tab === 1 ? Persen : 0,
        min_harga: formatNormali(MinHarga),
        max_harga: Tab === 1 ? formatNormali(MaxHarga) : 0,
        tanggal_mulai: TanggalMulai,
        tanggal_selesai: TanggalSelesai,
        stok: Stok,
        aktif: Status,
        jenis_order: JenisOrder,
        order: Order,
        kategori_user_id: KategoriUser,
      }),
      dispatch
    ).then(() => {
      update();
      handleClose();
    });
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{Judul}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 col-lg-4">
            <div className="m-3 text-center border rounded">
              {GambarUrl === "" ? (
                <FontAwesomeIcon
                  icon={faImage}
                  size="10x"
                  className="opacity-50"
                />
              ) : (
                <img src={GambarUrl} className="img-fluid" />
              )}
            </div>
            <div className="fw-bold d-flex justify-content-center text-danger mb-2">
              Ukuran Gambar 720 X 340
            </div>
            <div className="px-3">
              <input
                type="file"
                className="form-control form-control-sm"
                onChange={(e) => imageChange(e.target.files)}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-8 mt-md-3 mt-lg-0">
            <div className="row">
              <div className="col-sm-12 col-lg-4">
                <Input
                  judul={"Nama"}
                  placeholder="Nama Voucer"
                  type={"text"}
                  value={Nama}
                  onChange={(e) =>
                    e.target.value.length < 20 ? setNama(e.target.value) : null
                  }
                />
              </div>
              <div className="col-sm-12 col-lg-4">
                <Input
                  judul={"Kode"}
                  placeholder="Kode Voucer"
                  type={"text"}
                  value={Kode}
                  onChange={(e) =>
                    e.target.value.length < 25 ? setKode(e.target.value) : null
                  }
                  style={{ textTransform: "uppercase" }}
                />
              </div>
              <div className="col-sm-12 col-lg-4">
                <Input
                  judul={"Status"}
                  type={"select"}
                  data={dataStatus}
                  value={Status}
                  onChange={(e) => setStatus(e.target.value)}
                />
              </div>
              <div className="col-6 col-lg-4">
                <Input
                  judul={"Tanggal Mulai"}
                  placeholder={"Tanggal Mulai"}
                  type={"reactdatetime"}
                  selected={TanggalMulai}
                  onChange={(date) => setTanggalMulai(date)}
                />
              </div>
              <div className="col-6 col-lg-4">
                <Input
                  judul={"Tanggal Selesai"}
                  placeholder={"Tanggal Selesai"}
                  type={"reactdatetime"}
                  selected={TanggalSelesai}
                  onChange={(date) => setTanggalSelesai(date)}
                />
              </div>
              <div className="col-8 col-sm-4 col-lg-4">
                <Input
                  judul={"Jenis Order"}
                  type={"select"}
                  data={dataJenisUser()}
                  value={JenisOrder}
                  onChange={(e) => setJenisOrder(e.target.value)}
                />
              </div>
              <div className="col-4 col-sm-2 col-lg-3">
                <Input
                  judul={"Stok"}
                  placeholder={"Stok"}
                  type={"number"}
                  value={Stok}
                  onChange={(e) => setStok(e.target.value)}
                />
              </div>
              <div className="col-8 col-sm-4 col-lg-3">
                <Input
                  judul={"Kategori User"}
                  type={"select"}
                  data={dataKategoriUser()}
                  value={KategoriUser}
                  onChange={(e) => setKategoriUser(e.target.value)}
                />
              </div>
              <div className="col-4 col-sm-2 col-lg-1">
                <Input
                  judul={"Order"}
                  placeholder={"Order"}
                  type={"number"}
                  value={Order}
                  onChange={(e) => setOrder(e.target.value)}
                />
              </div>
              <div className="col-12 col-lg-5">
                <div
                  className="my-1 px-2 py-1 rounded bg-danger text-white ws-preline w-100"
                  style={{ fontSize: "12px" }}
                >
                  {`Order = 0 (Untuk User Pertama Order)
                                        Order = 1 (Untuk semua user tanpa batas Order)
                                        Order = N (Untuk semua user diatas Order N)
                                    `}
                </div>
              </div>
              <div className="col-12">
                <Input
                  judul={"Deskripsi"}
                  placeholder={"Deksripsi"}
                  type={"textarea"}
                  value={Deskripsi}
                  onChange={(e) => setDeskripsi(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-12 col-lg-4 d-flex flex-row flex-lg-column">
            <button
              className={`btn mx-4 shadow-none ${
                Tab === 1 ? `btn-primary` : ``
              }`}
              onClick={() => setTab(1)}
            >
              Voucer Hitung Persen
            </button>
            <button
              className={`btn mx-4 shadow-none ${
                Tab === 2 ? `btn-primary` : ``
              }`}
              onClick={() => setTab(2)}
            >
              Voucer Hitung Harga
            </button>
          </div>
          <div className="col-12 col-lg-8 border rounded px-2 py-1 mt-lg-0 mt-3">
            <div className="fw-bold w-100 text-center mb-2">
              {Tab === 1
                ? "Hitung Vuocer dengan Persentase"
                : "Hitung Voucer dengan Harga"}
            </div>
            {Tab === 1 ? (
              <div className="row">
                <div className="col-4">
                  <Input
                    judul={"Persen"}
                    type="number"
                    placeholder={"Persentase"}
                    value={Persen}
                    onChange={(e) =>
                      e.target.value.length < 3
                        ? setPersen(e.target.value)
                        : null
                    }
                  />
                </div>
                <div className="col-4">
                  <Input
                    judul={"Min Harga"}
                    type="text"
                    placeholder={"Rp 10.000"}
                    value={MinHarga}
                    onChange={(e) =>
                      setMinHarga(formatRupiah(String(e.target.value), "Rp ."))
                    }
                  />
                </div>
                <div className="col-4">
                  <Input
                    judul={"Max Harga"}
                    type="text"
                    placeholder={"Rp 10.000"}
                    value={MaxHarga}
                    onChange={(e) =>
                      setMaxHarga(formatRupiah(String(e.target.value), "Rp ."))
                    }
                  />
                </div>
              </div>
            ) : (
              <div className="row d-flex justify-content-center">
                <div className="col-4">
                  <Input
                    judul={"Harga"}
                    type="text"
                    placeholder={"Rp 10.000"}
                    value={Harga}
                    onChange={(e) =>
                      setHarga(formatRupiah(String(e.target.value), "Rp ."))
                    }
                  />
                </div>
                <div className="col-4">
                  <Input
                    judul={"Min Harga"}
                    type="text"
                    placeholder={"Rp 10.000"}
                    value={MinHarga}
                    onChange={(e) =>
                      setMinHarga(formatRupiah(String(e.target.value), "Rp ."))
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-end mt-3">
          <div className="w-75 d-flex flex-column">
            {data === null ? (
              <button
                className="btn btn-primary align-self-end"
                onClick={() => onSave()}
              >
                Simpan
              </button>
            ) : (
              <button
                className="btn btn-primary
                             align-self-end"
                onClick={() => onUpdate()}
              >
                Update
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalVoucer;
