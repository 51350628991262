import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { manageJamList } from '../../../config/redux/action/settingReducer'
import { Input } from '../../atoms'

function ModalJam({ show, handleClose, data, update }) {
    const [Id, setId] = useState(0)
    const [Judul, setJudul] = useState('')
    const [JamMulai, setJamMulai] = useState(null)
    const [JamSelesai, setJamSelesai] = useState(null)
    const dispatch = useDispatch()


    useEffect(() => {
        if (data !== null) {
            setId(data.id)
            setJudul('Edit Jam')
            let jam = data.nama.replace(/ /g, "").split('-')
            setJamMulai(jam[0])
            setJamSelesai(jam[1])
        } else {
            setId(0)
            setJudul('Tambah Jam')
            setJamMulai('10:00')
            setJamSelesai('10:00')
        }
    }, [data])

    const manageJam = (val) => {
        dispatch(manageJamList({
            url: '/setting/jam',
            id: Id,
            title: val,
            nama: `${JamMulai} - ${JamSelesai}`
        }))
        .then(()=>{
            update()
            handleClose()
        })
    }

    return (
        <Modal show={show} onHide={handleClose} size={'sm'}>
            <Modal.Header closeButton>
                <Modal.Title>{Judul}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-6'>
                        <Input judul={'Jam Mulai'}
                            type='time'
                            value={JamMulai}
                            onChange={e => setJamMulai(e.target.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <Input judul={'Jam Mulai'}
                            type='time'
                            value={JamSelesai}
                            onChange={e => setJamSelesai(e.target.value)}
                        />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-6'>
                        {
                            (data !== null) ?
                                <button className='btn btn-sm btn-danger w-100'
                                    onClick={() => manageJam('delete')}
                                >
                                    Hapus
                                </button>
                                :
                                <></>
                        }
                    </div>
                    <div className='col-6'>
                        <button className='btn btn-sm btn-primary w-100'
                            onClick={() => manageJam('save')}
                        >
                            Simpan
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalJam