import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentAcceptList } from "../../../config/redux/action/settingReducer";
import { formatRupiah, nyonyaaAdminEndpoint } from "../../../utils";
import SettingPaymentAcceptModal from "./SettingPaymentAcceptModal";

function SettingPaymentAccept() {
  const { PaymentAcceptList } = useSelector((state) => state.settingReducer);
  const [show, setShow] = useState(false);
  const [Data, setData] = useState(null);
  const handleClose = () => setShow(false);
  const [Update, setUpdate] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getPaymentAcceptList({
        url: "/setting/payment",
        type: "PAYMENT_ACCEPT_LIST",
      }),
      dispatch
    );
  }, [Update]);

  return (
    <div className="bg-white rounded px-2 py-1 shadow d-flex justify-content-center flex-column position-relative">
      <div className="fw-bold mb-3 text-center">Payment Accept</div>
      <div className="position-absolute top-0 end-0 me-2 mt-2">
        <button
          className="btn btn-primary btn-sm"
          onClick={() => {
            setData(null);
            setShow(true);
          }}
        >
          Tambah
        </button>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered" style={{ fontSize: "12px" }}>
          <thead>
            <tr>
              <th>Gambar</th>
              <th>Nama</th>
              <th>Kode</th>
              <th>Deskripsi</th>
              <th>Min Order</th>
              <th>Fee</th>
            </tr>
          </thead>
          <tbody>
            {PaymentAcceptList.length > 0 ? (
              PaymentAcceptList.map((val) => {
                return (
                  <tr
                    key={val.id}
                    className="hover"
                    onClick={() => {
                      setData(val);
                      setShow(true);
                    }}
                  >
                    <td align="center">
                      {val.gambar === null || val.gambar === "" ? (
                        <FontAwesomeIcon
                          icon={faImage}
                          className=" opacity-50"
                          style={{ height: 50, width: 120 }}
                        />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_STORAGE}${val.gambar}`}
                          className=""
                          style={{ height: 50, width: 120 }}
                        />
                      )}
                    </td>
                    <td>{val.nama}</td>
                    <td>{val.kode}</td>
                    <td>{val.deskripsi}</td>
                    <td>{formatRupiah(String(val.min_order), "Rp. ")}</td>
                    <td>{formatRupiah(String(val.fee), "Rp. ")}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} align={"center"}>
                  Tidak Ada data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SettingPaymentAcceptModal
        show={show}
        handleClose={handleClose}
        data={Data}
        update={() => setUpdate(Update + 1)}
      />
    </div>
  );
}

export default SettingPaymentAccept;
