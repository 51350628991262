import React, { useState } from "react";
import logo from "../../../assets/img/logo.png";
import Menu from "./Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Offcanvas } from "react-bootstrap";

function SideBar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="sidebar">
      <div className="d-flex justify-content-center px-2 py-3 border-bottom border-white position-relative align-items-center">
        <div
          className="d-block d-md-none position-absolute start-0 ms-3 mt-1 set-pointer"
          onClick={handleShow}
        >
          <FontAwesomeIcon icon={faBars} size={"lg"} />
        </div>
        <img
          src={logo}
          style={{ height: "30px", width: "30px" }}
          alt="logo"
          className="shadow-sm"
        />
        <div className=" fw-bold ms-1">Admin Nyonyaa</div>
      </div>
      <div className="d-none d-md-block">
        <Menu />
      </div>

      <Offcanvas show={show} onHide={handleClose} className="w-75">
        <Offcanvas.Body className="canvas-blue text-white text-decoration-none">
          <Menu />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default SideBar;
