import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

function ButtonLoading({
  disabled = false,
  size = "sm",
  loading = false,
  children,
  ...rest
}) {
  return (
    <Button
      disabled={loading ? true : disabled}
      size={size}
      className={`${loading && "px-5"}`}
      {...rest}
    >
      {loading ? (
        <FontAwesomeIcon
          size="sm"
          color="white"
          icon={faCircleNotch}
          className={"fa-spin"}
        />
      ) : (
        children
      )}
    </Button>
  );
}

export default ButtonLoading;
