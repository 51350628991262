import { combineReducers } from "redux";
import globalReducer from "./globalReducer";
import sessionReducer from "./sessionReducer";
import produkReducer from "./produkReducer";
import orderReducer from "./orderReducer";
import driverReducer from "./driverReducer";
import workshopReducer from "./workshopReducer";
import userReducer from "./userReducer";
import voucerReducer from "./voucerReducer";
import promoReducer from "./promoReducer";
import settingReducer from "./settingReducer";
import mitraReducer from "./mitraReducer";
import chartReducer from "./chartReducer";
import ratingReducer from "./ratingReducer";
import alamatReducer from "./alamatReducer";
import reportReducer from "./reportReducer";

const reducer = combineReducers({
  globalReducer,
  sessionReducer,
  produkReducer,
  orderReducer,
  driverReducer,
  workshopReducer,
  userReducer,
  voucerReducer,
  promoReducer,
  settingReducer,
  mitraReducer,
  chartReducer,
  ratingReducer,
  alamatReducer,
  reportReducer,
});

export default reducer;
