import { faArrowLeftLong, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AddDriver, AddWorkshop } from "..";
import ReactDatePicker from "react-datepicker";
import {
  addPembayaranTanggalOrder,
  ChangeStatus,
  selesaiOrderBerlanggananTanggal,
  selesaiOrderTanggal,
  updateAccountJurnal,
  updateBerlanggananKg,
} from "../../../config/redux/action/orderAction";
import { Account, Driver, Produksi } from "../../../utils";
import moment from "moment";

function OrderDetailBerlangganan({ data, onChange, data_faktur }) {
  const [PembayaranDate, setPembayaranDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [showDriver, setShowDriver] = useState(false);
  const [AccountJurnal, setAccountJurnal] = useState(0);
  const handleCloseDriver = () => setShowDriver(false);
  const [showWorkshop, setShowWorkshop] = useState(false);
  const handleCloseWorkshop = () => setShowWorkshop(false);
  const [IdBerlangganan, setIdBerlangganan] = useState(0);
  const [StatusWorkshop, setStatusWorkshop] = useState(0);
  const [StatusDriver, setStatusDriver] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data_faktur.tanggal_bayar !== null) {
      setPembayaranDate(new Date(data_faktur.tanggal_bayar));
    }
  }, [data_faktur]);

  const changeDriver = (id, driver_id, status_driver) => {
    dispatch(
      ChangeStatus({
        url: "/order/berlangganan/change/driver",
        id,
        driver_id,
        status_driver: status_driver || 0,
        title: "driver",
      }),
      dispatch
    ).then(() => {
      onChange();
    });
  };

  const changeWorkshop = (id, workshop_id, status_workshop) => {
    dispatch(
      ChangeStatus({
        url: "/order/berlangganan/change/workshop",
        id,
        workshop_id,
        status_workshop: status_workshop || 0,
        title: "workshop",
      }),
      dispatch
    ).then(() => {
      onChange();
    });
  };

  const orderSelesai = (val, id) => {
    dispatch(
      selesaiOrderTanggal({
        url: "/order/selesai",
        tanggal_selesai: val,
        title: "berlangganan",
        no_order: data.no_order,
        id,
      }),
      dispatch
    ).then(() => {
      onChange();
    });
  };

  const orderBerlanggananSelesai = (val) => {
    dispatch(
      selesaiOrderBerlanggananTanggal({
        url: "/order/berlangganan/selesai",
        no_order: data.no_order,
        status: val,
      }),
      dispatch
    ).then(() => {
      onChange();
    });
  };

  const accountChange = (value) => {
    setAccountJurnal(value);

    dispatch(
      updateAccountJurnal({
        url: "/order/account_jurnal",
        account_jurnal: value,
        no_order: data.no_order,
      }),
      dispatch
    ).then(() => {
      onChange();
    });
  };

  const kgChange = (value) => {
    dispatch(
      updateBerlanggananKg({
        url: "/order/berlangganandetail/kg",
        id: value.id,
        kg: value.kg,
      }),
      dispatch
    ).then(() => {
      onChange();
    });
  };

  const tanggalPembayaran = (val) => {
    setPembayaranDate(val);

    dispatch(
      addPembayaranTanggalOrder({
        url: "/order/tanggal_bayar",
        tanggal_bayar: val,
        no_order: data.no_order,
      }),
      dispatch
    ).then(() => {
      onChange();
    });
  };

  return (
    <div className="d-flex flex-column">
      <div
        className="d-flex flex-column bg-white mb-2 shadow rounded"
        style={{ fontSize: "14px" }}
      >
        <div className="ps-2 text-primary">
          <div onClick={() => window.history.back()} className="set-pointer">
            <FontAwesomeIcon icon={faArrowLeftLong} /> Kembali
          </div>
        </div>
        <div className="row py-2 px-2 mb-2 mx-2">
          <ul className="list-group col-12 col-md-4 mb-2">
            <li className="list-group-item d-flex justify-content-start">
              <div className="fw-bold w-25">No Order</div>
              <div className="w-75">{data.no_order}</div>
            </li>
            <li className="list-group-item d-flex justify-content-start">
              <div className="fw-bold w-25">Produk</div>
              <div className="w-75">{data.produk.nama}</div>
            </li>
            <li className="list-group-item d-flex justify-content-start">
              <div className="fw-bold w-25">Account</div>
              <div className="w-75">
                <select
                  className="form-select form-select-sm w-100"
                  value={data_faktur.account_jurnal}
                  onChange={(e) => accountChange(e.target.value)}
                >
                  {Account.map((val) => {
                    return (
                      <option
                        key={val.value}
                        value={val.value}
                      >{`(${val.keterangan}) ${val.properti}`}</option>
                    );
                  })}
                </select>
              </div>
            </li>
          </ul>
          <ul className="list-group col-12 col-md-4 mb-2">
            <li className="list-group-item d-flex justify-content-start">
              <div className="fw-bold w-25">Nama</div>
              <div className="w-75">{data.user.nama}</div>
            </li>
            <li className="list-group-item d-flex justify-content-start">
              <div className="fw-bold w-25">NoTelp</div>
              <div className="w-75">{data.user.notelp}</div>
            </li>
            <li className="list-group-item d-flex justify-content-start">
              <div className="fw-bold w-25">Bayar</div>
              <div className="w-75">
                <ReactDatePicker
                  selected={PembayaranDate}
                  onChange={(date) => tanggalPembayaran(date)}
                  minDate={new Date().setDate(new Date().getDate() - 3)}
                  showDisabledMonthNavigation
                  showTimeInput
                  isClearable={true}
                  dateFormat="dd/MM/yyyy h:mm aa"
                  className="form-control form-control-sm"
                />
              </div>
            </li>
          </ul>
          <ul className="list-group col-12 col-md-4 mb-2">
            <li className="list-group-item d-flex justify-content-start">
              <div className="fw-bold w-25">Pesan</div>
              <div className="w-75">
                {moment(data_faktur.tanggal_pesan).format("LLL")}
              </div>
            </li>
            <li className="list-group-item d-flex justify-content-start">
              <div className="fw-bold w-25">Order</div>
              <div className="w-75">{data.stok}</div>
            </li>
            <li className="list-group-item d-flex justify-content-start">
              <div className="fw-bold w-25">Status</div>
              <div className="w-75">
                <select
                  className="form-select form-select-sm"
                  value={data.status}
                  onChange={(e) => orderBerlanggananSelesai(e.target.value)}
                >
                  <option value={2}>Berlangsung</option>
                  <option value={3}>Selesai</option>
                </select>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {data.berlangganan_detail.length > 0 ? (
        data.berlangganan_detail.map((val) => {
          let tanggal_kirim = new Date(val.tanggal_kirim).toLocaleString();
          let kg = val.kg || 0;
          return (
            <div
              key={val.id}
              className="bg-white py-2 px-2 shadow rounded row mx-1 mb-2"
              style={{ fontSize: "13px" }}
            >
              <div className="col-12 col-md-4">
                <div className="px-2 d-flex flex-column">
                  <b>Tanggal</b>
                  <div className="ps-1 mb-3">{tanggal_kirim}</div>
                  <b>Alamat</b>
                  <div className="ws-preline ps-1 mb-3">{val.alamat}</div>
                  <b>Jam Ambil</b>
                  <div className="ps-1 mb-3">{val.jam_kirim}</div>
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="px-2">
                  <b>Jumlah Potong</b>
                  <div className="ps-1 mb-3">{val.jmlh_potong}</div>
                  <b className="text-center">Wash Option</b>
                  {val.berlangganan_detail_opt.map((opt) => {
                    return (
                      <div key={opt.id} className="ps-1">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="me-1"
                          color="#06c706"
                        />
                        {opt.produk.nama}
                      </div>
                    );
                  })}

                  <div className="fw-bold mb-2 mt-3">Status Workshop</div>
                  <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-start">
                      <div className="fw-bold w-25">WorkShop</div>
                      <div className="w-75 position-relative">
                        {val.workshop_id === null ? (
                          <>Belum Ada</>
                        ) : (
                          <>{val.workshop.nama}</>
                        )}
                        <div
                          className="position-absolute top-0 end-0 set-pointer text-decoration-underline text-primary px-2 rounded"
                          onClick={() => {
                            setIdBerlangganan(val.id);
                            setStatusWorkshop(val.status_workshop);
                            setShowWorkshop(true);
                          }}
                        >
                          Ganti Workshop
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-start">
                      <div className="fw-bold w-25">Status</div>
                      <div className="w-75">
                        <select
                          className="form-control form-control-sm"
                          onChange={(e) => {
                            let value =
                              e.target.value.length > 2 ? 0 : e.target.value;
                            changeWorkshop(val.id, val.workshop_id, value);
                          }}
                          value={
                            val.status_produksi !== null
                              ? val.status_produksi
                              : "null"
                          }
                          disabled={val.workshop_id === null ? true : false}
                        >
                          {Produksi.map((val) => {
                            return (
                              <option key={val.value} value={val.value}>
                                {val.keterangan}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="px-2">
                  <div className="fw-bold mb-2">Status Driver</div>
                  <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-start">
                      <div className="fw-bold w-25">Driver</div>
                      <div className="w-75 position-relative">
                        {val.driver_id === null ? (
                          <>Belum Ada</>
                        ) : (
                          <>{val.driver.nama}</>
                        )}
                        <div
                          className="position-absolute top-0 end-0 set-pointer text-decoration-underline text-primary px-2 rounded"
                          onClick={() => {
                            setIdBerlangganan(val.id);
                            setStatusDriver(val.status_driver);
                            setShowDriver(true);
                          }}
                        >
                          Ganti Driver
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-start">
                      <div className="fw-bold w-25">Status</div>
                      <div className="w-75">
                        <select
                          className="form-control form-control-sm"
                          onChange={(e) => {
                            let value =
                              e.target.value.length > 2 ? 0 : e.target.value;
                            changeDriver(val.id, val.driver_id, value);
                          }}
                          value={
                            val.status_driver !== null
                              ? val.status_driver
                              : "null"
                          }
                          disabled={val.driver_id === null ? true : false}
                        >
                          {Driver.map((driver) => {
                            return (
                              <option key={driver.value} value={driver.value}>
                                {driver.keterangan}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-start">
                      <div className="fw-bold w-25">Tgl Selesai</div>
                      <div className="w-50">
                        <ReactDatePicker
                          selected={
                            val.tanggal_terima === null
                              ? null
                              : new Date(val.tanggal_terima)
                          }
                          onChange={(date) => orderSelesai(date, val.id)}
                          minDate={new Date()}
                          showDisabledMonthNavigation
                          showTimeInput
                          isClearable={true}
                          dateFormat="MM/dd/yyyy h:mm aa"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="px-2">
                  <div className="fw-bold mb-2">Kilogram</div>
                  <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-start">
                      <div className="fw-bold w-25">Kg</div>
                      <div className="w-75 position-relative">{val.kg}</div>
                    </li>
                    <li className="list-group-item d-flex justify-content-start">
                      <div className="fw-bold w-25">Kg</div>
                      <div className="w-75 position-relative d-flex">
                        <input
                          type={"number"}
                          placeholder={val.kg}
                          className="form-control form-control-sm me-2"
                          onChange={(e) => (kg = e.target.value)}
                        />
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => kgChange({ id: val.id, kg })}
                        >
                          Update
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <></>
      )}
      <AddDriver
        show={showDriver}
        handleClose={handleCloseDriver}
        onchange={(val) => {
          changeDriver(IdBerlangganan, val, StatusDriver);
        }}
      />

      <AddWorkshop
        show={showWorkshop}
        handleClose={handleCloseWorkshop}
        onchange={(val) => {
          changeWorkshop(IdBerlangganan, val, StatusWorkshop);
        }}
      />
    </div>
  );
}

export default OrderDetailBerlangganan;
