import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import moment from "moment";
import { Gap } from "../../components";

function UserMigrateRow({ val, onClick }) {
  const userDelete = JSON.parse(val.user_value);
  let image = null;
  if (userDelete.gambar === null || userDelete.gambar === "") {
    image = (
      <FontAwesomeIcon
        icon={faUserCircle}
        className=" opacity-50"
        style={{ height: 50, width: 50 }}
      />
    );
  } else if (userDelete.gambar.includes("https://")) {
    image = (
      <img
        src={userDelete.gambar}
        className="rounded-circle"
        style={{ height: 50, width: 50 }}
      />
    );
  } else {
    image = (
      <img
        src={`${process.env.REACT_APP_STORAGE}${userDelete.gambar}`}
        className="rounded-circle"
        style={{ height: 50, width: 50 }}
      />
    );
  }

  let image_move = null;

  if (val.user_move_id !== null) {
    if (val.user_move.gambar === null || val.user_move.gambar === "") {
      image = (
        <FontAwesomeIcon
          icon={faUserCircle}
          className=" opacity-50"
          style={{ height: 50, width: 50 }}
        />
      );
    } else if (val.user_move.gambar.includes("https://")) {
      image = (
        <img
          src={val.user_move.gambar}
          className="rounded-circle"
          style={{ height: 50, width: 50 }}
        />
      );
    } else {
      image = (
        <img
          src={`${process.env.REACT_APP_STORAGE}${val.user_move.gambar}`}
          className="rounded-circle"
          style={{ height: 50, width: 50 }}
        />
      );
    }
  }

  return (
    <div
      className="px-3 py-2 mb-3 border border-1 rounded hover"
      onClick={() => onClick(val)}
    >
      <div className="row">
        <div className="col-12 col-md-6 col-lg-4 mb-3 mb-lg-0">
          <div className="text-start text-md-center fw-bold mb-2">
            User dihapus
          </div>
          <div className="d-flex align-items-center" style={{ fontSize: 12 }}>
            {image}
            <div className="ms-2 px-2 py-1 border border-1 w-100 d-flex flex-column">
              <div className="fw-bold">{userDelete.nama}</div>
              <div className="fst-italic">{userDelete.email}</div>
              <div className="fst-italic">{userDelete.notelp}</div>
            </div>
          </div>
          <div className="fst-italic mt-2" style={{ fontSize: 10 }}>
            Pengajuan di tanggal{" "}
            {moment(new Date(val.date_delete)).format("D/M/yyyy hh:mm:ss")}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mb-3 mb-lg-0">
          <div className="text-start text-md-center fw-bold mb-2">
            Pindah ke user
          </div>

          {val.user_move_id === null ? (
            <div
              className="d-flex justify-content-center fst-italic"
              style={{ fontSize: 12 }}
            >
              Belum ditentukan
            </div>
          ) : (
            <div className="d-flex align-items-center" style={{ fontSize: 12 }}>
              {image}
              <div className="ms-2 px-2 py-1 border border-1 w-100 d-flex flex-column">
                <div className="fw-bold">{val.user_move.nama}</div>
                <div className="fst-italic">{val.user_move.email}</div>
                <div className="fst-italic">{val.user_move.notelp}</div>
              </div>
            </div>
          )}
        </div>
        <div className="col-12 col-md-6 col-lg-2 mb-3 mb-lg-0">
          <div className="text-start text-md-center fw-bold mb-2">Status</div>
          <div
            className="d-flex flex-column align-items-center"
            style={{ fontSize: 12 }}
          >
            <div
              className={`${
                val.approve ? "bg-success" : "bg-danger"
              } text-white px-2 py-1 rounded fw-bold`}
            >
              {val.approve ? "Di setujui" : "Tertunda"}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-2">
          <div className="text-start text-md-center fw-bold mb-2">
            Di Setujui
          </div>
          <div
            className="d-flex flex-column align-items-center"
            style={{ fontSize: 12 }}
          >
            {val.user_approve_id === null ? (
              <div>Belum disetujui</div>
            ) : (
              <div className="d-flex justify-content-center flex-column text-center">
                <div>Di setujui oleh {val.user_approve.nama}</div>
                <div style={{fontSize:10, fontStyle:'italic'}}>{val.user_approve.email}</div>
                <div className="mt-2">Tanggal :</div>
                <div style={{fontSize:10, fontStyle:'italic'}}>{moment(new Date(val.updatedAt)).format("D/M/yyyy hh:mm:ss")}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserMigrateRow;
