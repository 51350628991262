import React, { useState } from "react";
import { Form } from "react-bootstrap";

function SettingDayActiveList({ value, onChangeValue }) {
  const [active, setActive] = useState(value.active);

  return (
    <div className="d-flex justify-content-between">
      <div className="">{value.name}</div>
      <Form>
        <Form.Check
          type="switch"
          checked={active}
          onChange={() => {
            setActive(!active);
            onChangeValue({
              value: value.value,
              active: !active,
            });
          }}
        />
      </Form>
    </div>
  );
}

export default SettingDayActiveList;
