import { faImage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ModalMitra, SideBar } from '../../components'
import { getMitraList } from '../../config/redux/action/mitraAction'
import { nyonyaaAdminEndpoint } from '../../utils'

function MitraPage() {
    const { MitraList } = useSelector(state => state.mitraReducer)
    const [Update, setUpdate] = useState(1)
    const [Data, setData] = useState(null)
    const [Show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getMitraList({
            url: '/mitra',
            type: 'MITRA_LIST'
        }), dispatch)

    }, [Update])


    return (
        <div>
            <SideBar />
            <div className='content'>
                <div className='bg-white mx-2 rounded shadow px-2 py-1'>
                    <div className='d-flex flex-column'>
                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-sm btn-primary me-2 mb-2 mt-3'
                                onClick={() => {
                                    setData(null)
                                    setShow(true)
                                    dispatch({ type: 'CHANGE_ALAMAT_GLOBAL', payload: [] })
                                }}
                            >
                                Tambah Mitra
                            </button>
                        </div>
                        <div className='table-responsive'>
                            <table className='table table-bordered' style={{ fontSize: '12px' }}>
                                <thead>
                                    <tr>
                                        <th>Gambar</th>
                                        <th>Nama</th>
                                        <th>Alamat</th>
                                        <th>No Telp</th>
                                        <th>Oprasional</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (MitraList.length > 0) ?
                                            MitraList.map(val => {
                                                return (
                                                    <tr key={val.id} className='hover'
                                                        onClick={() => {
                                                            setData(val)
                                                            setShow(true)
                                                            dispatch({ type: 'CHANGE_ALAMAT_GLOBAL', payload: val })
                                                        }}
                                                    >
                                                        <td align='center'>
                                                            {
                                                                (val.gambar === null || val.gambar === '') ?
                                                                    <FontAwesomeIcon icon={faImage}
                                                                        className=' opacity-50'
                                                                        style={{ height: 50, width: 120 }} />
                                                                    :
                                                                    <img src={`${process.env.REACT_APP_STORAGE}${val.gambar}`}
                                                                        className=''
                                                                        style={{ height: 50, width: 120 }} />

                                                            }
                                                        </td>
                                                        <td>{val.nama}</td>
                                                        <td>{val.alamat}</td>
                                                        <td>{val.notelp}</td>
                                                        <td>{val.jam_oprasional}</td>
                                                        <td>{(val.aktif) ? 'Aktif' : 'Tidak Aktif'}</td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td align='center' colSpan={6}>Data tidak ditemukan</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <ModalMitra
                show={Show}
                handleClose={handleClose}
                data={Data}
                update={() => setUpdate(Update + 1)}
            />

        </div>
    )
}

export default MitraPage