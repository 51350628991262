import React from 'react'
import { SettingDayActive, SettingJam, SettingKategoriUser, SettingPaymentAccept, SideBar } from '../../components'

function SettingPage() {


    return (
        <div>
            <SideBar />
            <div className='content'>
                <div className='row'>
                    <div className='col-12 col-md-10 mb-3'>
                        <SettingPaymentAccept />
                    </div>
                    <div className='col-12 col-md-2 mb-3'>
                        <SettingJam />
                        <SettingDayActive />
                    </div>
                    <div className='col-12 col-md-2 mb-3'>
                        <SettingKategoriUser />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingPage