import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardRating, SideBar } from "../../components";
import { getDataAPI } from "../../config/redux/action/newRequestAction";
import { getRating } from "../../config/redux/action/ratingAction";

function RatingPage() {
  const { RatingDriver, RatingWorkshop } = useSelector(
    (state) => state.ratingReducer
  );
  const [ratingWash, setRatingWash] = useState([]);
  const [ratingIron, setRatingIron] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDataAPI({
        url: "/rating/workshop",
        type: "RATING_WORKSHOP_LIST",
      })
    );

    dispatch(
      getDataAPI({
        url: "/rating/driver",
        type: "RATING_DRIVER_LIST",
      })
    );

    const getRatingWash = dispatch(
      getDataAPI({
        url: "/rating/wash",
        data: {
          role_id: 6,
        },
      })
    );

    const getRatingIron = dispatch(
      getDataAPI({
        url: "/rating/wash",
        data: {
          role_id: 7,
        },
      })
    );

    Promise.all([getRatingWash, getRatingIron])
      .then((res) => {
        setRatingWash(res[0].data);
        setRatingIron(res[1].data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <SideBar />
      <div className="content">
        <div className="row">
          <div className="col-12 col-lg-6 mb-3">
            <div className=" p-2 d-flex flex-column bg-white pb-4 shadow rounded">
              <div className="w-100 fw-bold text-center mb-3">Rate Driver</div>
              {RatingDriver.length > 0 ? (
                RatingDriver.map((val) => {
                  if (val.user !== null) {
                    return (
                      <CardRating
                        key={val.driver_id}
                        data={val}
                        onClick={() =>
                          navigate(
                            `/rating/detail?type=driver&user_id=${val.driver_id}`
                          )
                        }
                      />
                    );
                  }
                })
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <div className=" p-2 d-flex flex-column bg-white pb-4 shadow rounded">
              <div className="w-100 fw-bold text-center mb-3">
                Rate Workshop
              </div>
              {RatingWorkshop.length > 0 ? (
                RatingWorkshop.map((val) => {
                  if (val.user !== null) {
                    return (
                      <CardRating
                        key={val.workshop_id}
                        data={val}
                        onClick={() =>
                          navigate(
                            `/rating/detail?type=workshop&user_id=${val.workshop_id}`
                          )
                        }
                      />
                    );
                  }
                })
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <div className=" p-2 d-flex flex-column bg-white pb-4 shadow rounded">
              <div className="w-100 fw-bold text-center mb-3">
                Rate Cuci
              </div>
              {ratingWash.length > 0 ? (
                ratingWash.map((val, key) => {
                  if (val.user !== null) {
                    return (
                      <CardRating
                        key={key}
                        data={val}
                        onClick={() =>
                          navigate(
                            `/rating/detail?type=wash&user_id=${val.user.id}`
                          )
                        }
                      />
                    );
                  }
                })
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <div className=" p-2 d-flex flex-column bg-white pb-4 shadow rounded">
              <div className="w-100 fw-bold text-center mb-3">
                Rate Setrika
              </div>
              {ratingIron.length > 0 ? (
                ratingIron.map((val, key) => {
                  if (val.user !== null) {
                    return (
                      <CardRating
                        key={key}
                        data={val}
                        onClick={() =>
                          navigate(
                            `/rating/detail?type=wash&user_id=${val.user.id}`
                          )
                        }
                      />
                    );
                  }
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RatingPage;
