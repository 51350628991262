const initialState = {
  OrderList: [],
  OrderListBerlangganan: [],
  OrderListBerlanggananRekap: [],
  OrderDetail: null,
  OrderDetailBerlangganan: null,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ORDER_LIST_CHANGE":
      return {
        ...state,
        OrderList: action.payload,
      };
    case "ORDER_LIST_BERLANGGANAN_CHANGE":
      return {
        ...state,
        OrderListBerlangganan: action.payload,
      };
    case "ORDER_LIST_BERLANGGANAN_REKAP_CHANGE":
      return {
        ...state,
        OrderListBerlanggananRekap: action.payload,
      };
    case "ORDER_DETAIL_LIST_CHANGE":
      return {
        ...state,
        OrderDetail: action.payload,
      };
    case "ORDER_DETAIL_BERLANGGANAN_LIST_CHANGE":
      return {
        ...state,
        OrderDetailBerlangganan: action.payload,
      };

    default:
      return state;
  }
};

export default orderReducer;
