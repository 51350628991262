import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalVoucer, SideBar } from "../../components";
import { getUserKategoriList } from "../../config/redux/action/userAction";
import { getVoucerList } from "../../config/redux/action/voucerAction";
import { formatRupiah, nyonyaaAdminEndpoint } from "../../utils";

function VoucerPage() {
  const { VoucerList } = useSelector((state) => state.voucerReducer);
  const { ListKategoriUser } = useSelector((state) => state.userReducer);
  const [Update, setUpdate] = useState(0);
  const [Data, setData] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getUserKategoriList({
        url: "/user/kategori",
        type: "LIST_KATEGORI_USER_CHANGE",
      }),
      dispatch
    );
  }, []);

  useEffect(() => {
    dispatch(
      getVoucerList({
        url: "/voucer",
        type: "VOUCER_LIST",
      })
    );
  }, [Update]);

  const statusVoucer = (status) => {
    if (status === 2) {
      return "Aktif (Private)";
    } else if (status === 1) {
      return "Aktif";
    } else {
      return "Tidak Aktif";
    }
  };

  return (
    <div>
      <SideBar />
      <div className="content">
        <div className="mx-2 bg-white rounded shadow px-2 py-1 d-flex flex-column">
          <button
            className="btn btn-sm btn-primary align-self-end me-2 mb-2 mt-3"
            onClick={() => {
              setData(null);
              setShow(true);
            }}
          >
            Tambah Voucer
          </button>
          <div className="table-responsive">
            <table
              className="table table-bordered"
              style={{ fontSize: "12px" }}
            >
              <thead>
                <tr>
                  <th>Gambar</th>
                  <th>Nama</th>
                  <th>Kode</th>
                  <th>Stok</th>
                  <th>Harga</th>
                  <th>Tanggal</th>
                  <th>Aktif</th>
                </tr>
              </thead>
              <tbody>
                {VoucerList.length > 0 ? (
                  VoucerList.map((val) => {
                    return (
                      <tr
                        key={val.id}
                        className="hover"
                        onClick={() => {
                          setData(val);
                          setShow(true);
                        }}
                      >
                        <td align="center">
                          {val.gambar === null || val.gambar === "" ? (
                            <FontAwesomeIcon
                              icon={faImage}
                              className=" opacity-50"
                              style={{ height: 50, width: 120 }}
                            />
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_STORAGE}${val.gambar}`}
                              className=""
                              style={{ height: 50, width: 120 }}
                            />
                          )}
                        </td>
                        <td>{val.nama}</td>
                        <td>{val.kode}</td>
                        <td>{val.stok}</td>
                        <td align="right" className="ws-preline">
                          {val.harga > 0
                            ? `harga : ${formatRupiah(
                                String(val.harga),
                                "Rp ."
                              )}`
                            : `Persen : ${val.persen} %`}

                          {`\nmin-harga : ${formatRupiah(
                            String(val.min_harga),
                            "Rp ."
                          )}
                                                        max-harga : ${formatRupiah(
                                                          String(val.max_harga),
                                                          "Rp ."
                                                        )}
                                                        `}
                        </td>
                        {/* <td align='right'>{formatRupiah(String(val.max_harga), 'Rp .')}</td>
                                                    <td align='right'>{formatRupiah(String(val.min_harga), 'Rp .')}</td> */}
                        <td className="ws-preline" align="center">
                          {new Date(val.tanggal_mulai).toLocaleString() +
                            " \n s/d \n" +
                            new Date(val.tanggal_selesai).toLocaleString()}
                        </td>
                        <td>{statusVoucer(val.aktif)}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10} align="center">
                      Data tidak ditemukan
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ModalVoucer
        show={show}
        handleClose={handleClose}
        data={Data}
        kategoriUserData={ListKategoriUser}
        update={() => setUpdate(Update + 1)}
      />
    </div>
  );
}

export default VoucerPage;
