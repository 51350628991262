import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRoleList } from '../../../config/redux/action/userAction'

function RoleList({onUtama, status, ...rest }) {
    const { ListRole } = useSelector(state => state.userReducer)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getRoleList({
            url: '/role',
            type: 'LIST_ROLE_CHANGE'
        }), dispatch)
    }, [])

    useEffect(() => {
        if (ListRole.length > 0) {
            if (status === undefined) {
                onUtama(ListRole[0].id)
            }else{
                onUtama(ListRole[2].id)
            }

        }
    }, [ListRole])

    return (
        <div className=''>
            <select className='form-select form-select-sm' {...rest}>
                {
                    (ListRole.length > 0) ?
                        ListRole.map(val => {
                            if (status === 'workshop') {
                                if (val.id === 3 || val.id === 6 || val.id === 7) {
                                    return (
                                        <option key={val.id} value={val.id}>{val.name}</option>
                                    )
                                }
                            }else {
                                return (
                                    <option key={val.id} value={val.id}>{val.name}</option>
                                )
                            }

                        })
                        :
                        <></>
                }
            </select>
        </div>
    )
}

export default RoleList