import axios from "axios"
import { nyonyaaAdminEndpoint } from "../../../utils"

export const getAlamatList = (payload) => (dispatch) => {
    const session = JSON.parse(localStorage.getItem("session_user"))
    axios.get(nyonyaaAdminEndpoint + payload.url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${session.data.jwt}`,
        },
        params: {
            user_id: session.data.id,
            search: payload.Search
        }
    })
        .then(res => {
            if (res.status == 200) {
                dispatch({ type: payload.type, payload: res.data.data })
            }
        })
        .catch(err => console.log(err))
}

export const saveAlamat = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        const session = JSON.parse(localStorage.getItem("session_user"))
        let data = new FormData()
        data.append('id', payload.Id)
        data.append('jalan', payload.Jalan)
        data.append('kode_pos', payload.KodePos)
        data.append('nama', payload.Nama)
        data.append('telp', payload.NoTelp)
        data.append('utama', false)
        data.append('longlat', payload.LongLat)
        data.append('label', payload.label)
        data.append('description', payload.description)


        axios({
            method: 'post',
            url: nyonyaaAdminEndpoint + payload.url,
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${session.data.jwt}`,
            },
            data
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.log(err);
                return reject(err)
            })
    })
}

export const deleteAlamat = (payload) => (dispatch) =>{
    return new Promise((resolve, reject) => {
        const session = JSON.parse(localStorage.getItem("session_user"))
        let data = new FormData()
        data.append('id', payload.id)

        axios({
            method: 'DELETE',
            url: nyonyaaAdminEndpoint + payload.url,
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${session.data.jwt}`,
            },
            data
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.log(err);
                return reject(err)
            })
    })
}