import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RoleList } from "../..";
import { getUserList } from "../../../config/redux/action/userAction";
import { addUserWorkshop } from "../../../config/redux/action/workshopAction";
import { nyonyaaAdminEndpoint } from "../../../utils";

function AddUserWorkshop({ show, handleClose, workshop_id, update }) {
  const [Role, setRole] = useState(0);
  const { ListUserWorkshop } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Role > 0) {
      dispatch(
        getUserList({
          url: "/user",
          type: "LIST_USER_WORKSHOP_CHANGE",
          role: Role,
          limit: 10,
        }),
        dispatch
      );
    }
  }, [Role]);

  const tambahUser = (val) => {
    dispatch(
      addUserWorkshop({
        url: "/workshop",
        user_id: val.user.id,
        workshop_id,
      }),
      dispatch
    )
      .then((result) => {
        if (result !== 200) {
          update("Data user telah dimasukan");
        } else {
          update();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Tambah User Workshop</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        <div className="form-group mb-2">
          <label>Role : </label>
          <RoleList
            value={Role}
            onChange={(e) => setRole(e.target.value)}
            status={"workshop"}
            onUtama={(val) => setRole(val)}
          />
        </div>

        <ul className="list-group">
          {ListUserWorkshop.length > 0 ? (
            ListUserWorkshop.map((val) => {
              return (
                <li key={val.id} className="list-group-item">
                  <div className="d-flex">
                    <div>
                      {val.user.gambar === null ? (
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          className=" opacity-50"
                          style={{ height: 50, width: 50 }}
                        />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_STORAGE}${val.user.gambar}`}
                          className="rounded-circle"
                          style={{ height: 50, width: 50 }}
                        />
                      )}
                    </div>
                    <div className="d-flex flex-column mx-3">
                      <div>{val.user.nama}</div>
                      <div>{val.user.notelp}</div>
                    </div>
                    <div className=" d-flex w-100 justify-content-end">
                      <button
                        onClick={() => tambahUser(val)}
                        className="btn btn-primary align-self-center"
                      >
                        Tambah
                      </button>
                    </div>
                  </div>
                </li>
              );
            })
          ) : (
            <li className="list-group-item">Data tidak ditemukan</li>
          )}
        </ul>
      </Modal.Body>
    </Modal>
  );
}

export default AddUserWorkshop;
