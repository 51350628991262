import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { manageMitraList } from "../../../config/redux/action/mitraAction";
import { nyonyaaAdminEndpoint } from "../../../utils";
import { AlamatMaps, Input } from "../../atoms";

function ModalMitra({ show, handleClose, data, update }) {
  const { ManageAlamat } = useSelector((state) => state.globalReducer);
  const [Id, setId] = useState(0);
  const [Nama, setNama] = useState(null);
  const [NoTelp, setNoTelp] = useState(null);
  const [Judul, setJudul] = useState("");
  const [JamMulai, setJamMulai] = useState(null);
  const [JamSelesai, setJamSelesai] = useState(null);
  const [LongLat, setLongLat] = useState("");
  const [Gambar, setGambar] = useState("");
  const [GambarUrl, setGambarUrl] = useState("");
  const [Jalan, setJalan] = useState("");
  const [Aktif, setAktif] = useState(1);
  const dispatch = useDispatch();
  const searchRef = useRef();
  const script = document.createElement("script");

  const statusAktif = [
    { id: 1, name: "Aktif" },
    { id: 0, name: "Tidak Aktif" },
  ];

  useEffect(() => {
    const Url =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDRtCedV7wgfNEk1lg4xtoDD5ckeHyOMKQ&libraries=places";
    script.src = Url;
    script.async = true;

    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (data !== null) {
      setId(data.id);
      setJudul("Edit Mitra");
      let jam = data.jam_oprasional.replace(/ /g, "").split("-");
      setJamMulai(jam[0]);
      setJamSelesai(jam[1]);
      setGambarUrl(
        data.gambar === null || data.gambar === ""
          ? ""
          : process.env.REACT_APP_STORAGE + data.gambar
      );
      setNama(data.nama);
      setLongLat(data.longlat);
      setNoTelp(data.notelp);
      setJalan(data.alamat);
      setAktif(data.aktif ? 1 : 0);
    } else {
      setId(0);
      setJudul("Tambah Mitra");
      setJamMulai("10:00");
      setJamSelesai("10:00");
      setGambarUrl("");
      setNama("");
      setLongLat([]);
      setNoTelp("");
      setJalan("");
      setAktif(1);
    }
  }, [data]);

  useEffect(() => {
    if (ManageAlamat.Alamat !== undefined) {
      setJalan(ManageAlamat.Alamat);
      setLongLat(`${ManageAlamat.LongLat.lat},${ManageAlamat.LongLat.lng}`);
    }
  }, [ManageAlamat]);

  const imageChange = (val) => {
    setGambar(val[0]);
    setGambarUrl(URL.createObjectURL(val[0]));
  };

  const onSave = (title) => {
    dispatch(
      manageMitraList({
        url: "/mitra",
        title,
        id: Id,
        nama: Nama,
        image: Gambar,
        alamat: Jalan,
        notelp: NoTelp,
        longlat: LongLat,
        aktif: Aktif,
        jam_oprasional: `${JamMulai} - ${JamSelesai}`,
      }),
      dispatch
    ).then(() => {
      update();
      handleClose();
    });
  };

  return (
    <Modal show={show} onHide={handleClose} size={"xl"}>
      <Modal.Header closeButton>
        <Modal.Title>{Judul}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex">
          <div className="w-50">
            <div style={{ height: "300px", marginInline: "10px" }}>
              <AlamatMaps searchRef={searchRef} />
            </div>
          </div>
          <div className="w-50 d-flex justify-content-around flex-column">
            <div className="m-3 text-center border rounded">
              {GambarUrl === "" ? (
                <FontAwesomeIcon
                  icon={faImage}
                  size="10x"
                  className="opacity-50"
                />
              ) : (
                <img src={GambarUrl} className="img-fluid" />
              )}
            </div>
            <div className="mx-3">
              <input
                type="file"
                className="form-control"
                onChange={(e) => imageChange(e.target.files)}
              />
            </div>
          </div>
        </div>
        <div className="d-flex mt-3">
          <div className="w-100">
            <div className="row">
              <div className="col-6">
                <Input
                  judul={"Nama"}
                  placeholder="Masukan Nama"
                  type={"text"}
                  value={Nama}
                  onChange={(e) => setNama(e.target.value)}
                />
              </div>
              <div className="col-6">
                <Input
                  judul={"No Telp"}
                  placeholder="Masukan Np Telp"
                  type={"text"}
                  value={NoTelp}
                  onChange={(e) => setNoTelp(e.target.value)}
                />
              </div>
              <div className="col-12">
                <Input
                  judul={"Alamat"}
                  placeholder="Alamat Mitra"
                  type={"textarea"}
                  value={Jalan}
                  onChange={(e) => setJalan(e.target.value)}
                />
              </div>
              <div className="col-4">
                <Input
                  judul={"Status"}
                  type={"select"}
                  data={statusAktif}
                  value={Aktif}
                  onChange={(e) => setAktif(e.target.value)}
                />
              </div>
              <div className="col-4">
                <Input
                  judul={"Jam Masuk"}
                  type="time"
                  value={JamMulai}
                  onChange={(e) => setJamMulai(e.target.value)}
                />
              </div>
              <div className="col-4">
                <Input
                  judul={"Jam Pulang"}
                  type="time"
                  value={JamSelesai}
                  onChange={(e) => setJamSelesai(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex mt-3 justify-content-end">
          {data === null ? (
            <button className="btn btn-primary " onClick={() => onSave("save")}>
              Simpan
            </button>
          ) : (
            <button
              className="btn btn-success "
              onClick={() => onSave("update")}
            >
              Update
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalMitra;
