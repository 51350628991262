import React from "react";
import { formatRupiah, nyonyaaAdminEndpoint } from "../../../utils";

function CardMaster({ val, click }) {
  return (
    <div
      className="d-flex justify-content-around border rounded py-2 my-1 hover"
      onClick={() => click(val)}
      style={{ fontSize: 12 }}
    >
      <div className="row w-100">
        <div className="text-center col-6 col-lg-3">
          {/* <img src={`${nyonyaaAdminEndpoint}${val.gambar}`} */}
          <img
            src={`${process.env.REACT_APP_STORAGE}${val.gambar}`}
            alt={val.nama}
            style={{ maxHeight: 100 }}
            className="align-self-center"
          />
        </div>
        <div className="d-flex flex-column col-6 col-lg-6">
          <div className="fw-bold">
            {val.nama} ({val.satuan})
          </div>
          <div className="d-none d-sm-block">{val.keterangan}</div>
        </div>
        <div className="d-flex flex-column text-center col-12 col-lg-3 pt-2 pt-lg-0">
          {val.aktif ? (
            <div className="bg-success w-50 rounded text-white align-self-center">
              Aktif
            </div>
          ) : (
            <div className="bg-danger w-50 rounded text-white align-self-center">
              Tidak Aktif
            </div>
          )}

          <div className="text-primary fw-bold fs-5">
            {formatRupiah(String(val.harga - val.diskon), "Rp. ")}
          </div>

          <div style={{ fontSize: 10 }} className="fst-italic">
            {formatRupiah(String(val.harga), "Rp .")}
            <b className="fw-bold text-danger">
              {val.diskon > 0
                ? ` (${formatRupiah(String(val.diskon), "Rp .")})`
                : null}
            </b>
          </div>
          <div className="d-none d-lg-block">

          <div> Pesan : {val.qty_order}</div>
          <div> Kapasitas : {val.kapasitas} Kg</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardMaster;
